import React, { Component } from 'react'
import Category from "./Category"
import ArrowLeft from "./../../assets/images/al.png"
import { GetLocalizationString, HidePreloader, SetDefaultLanguage } from '../../Helpers/CustomHelper'

class SelectSubCategories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            service_categories: [],
            category_info:{},
        };
      }
    
      componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        let routeState
        if(this.props.location.service_categories){
            localStorage.setItem('routeState', JSON.stringify(this.props.location.service_categories))
            localStorage.setItem('category_type', this.props.location.category_type)
            
            routeState = this.props.location.service_categories
            let ServiceCategoriesList = routeState;
            if(ServiceCategoriesList.length > 0){
                let category_type = this.props.location.category_type;
                ServiceCategoriesList = ServiceCategoriesList.map(function(category,i){
                    return <Category key={i} actionFor="specialization-list" category_info={category} nextPage="/select-specialization" category_type={(category_type == 'Grievance') ? 'Grievance' : 'Service'}/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                    })
                }, 100);
                
            }
            
            console.log(this.props.location.category_info)
            
            if(this.props.location.category_info){
                localStorage.setItem('category_info', JSON.stringify(this.props.location.category_info))
            }
            this.setState({
                category_info:this.props.location.category_info,
            })
            localStorage.setItem('SelectedCategory',(localStorage.getItem('RequestFor') == 'Update') ? this.props.location.category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSubCategory',0)
            localStorage.setItem('SelectedSpecialization',0)
            
        } else {
            routeState = localStorage.getItem('routeState')
            if(routeState) routeState = JSON.parse(routeState)
            let ServiceCategoriesList = routeState;
            if(ServiceCategoriesList.length > 0){
                let category_type = localStorage.getItem('category_type');
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                    return <Category key={i} actionFor="specialization-list" category_info={category} nextPage="/select-specialization" category_type={(category_type == 'Grievance') ? 'Grievance' : 'Service'}/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                    })
                }, 100);
            }

            let category_info = localStorage.getItem('category_info')
            if(category_info) category_info = JSON.parse(category_info)

            console.log(category_info)
            this.setState({
                category_info:category_info,
            })
            localStorage.setItem('SelectedCategory',(localStorage.getItem('RequestFor') == 'Update') ? category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSubCategory',0)
            localStorage.setItem('SelectedSpecialization',0)
            
        }
      }
    
    render() {
        return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">
                            {
                                (localStorage.getItem('RequestFor') == 'Update')?
                                GetLocalizationString('select_category')
                                :
                                this.state.category_info.service_category_title ?? GetLocalizationString('select_category')
                            }
                            </h1>
                        </div>
                        <div className="col-2 text-right">
        
                        </div>
                    </div>
                </div>
            </header>
            {/* {(localStorage.getItem('category_type') != 'Grievance' && this.state.category_info.disclaimer_text)
            ?
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="diss_box">
                                <div className="alert alert-danger text-left m-0">
                                     
                                    {
                                    this.state.category_info.disclaimer_text
                                    ?
                                    <>
                                    <strong>{GetLocalizationString('disclaimer')}</strong>
                                    {this.state.category_info.disclaimer_text}
                                    </>
                                    :
                                    ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            :
            ''
            } */}
            
            <section>
                <div className="container">
                    <div className="row">
                        {
                            (localStorage.getItem('RequestFor') == 'Update')?
                            (<div className="col-12">
                                <h2 className="special_text ">{GetLocalizationString('select_category_in_which_you_provide_services')}</h2>
                            </div>)
                            :
                            (<div className="col-12">
                                <h2 className="special_text ">{ (localStorage.getItem('category_type') == 'Grievance') ? GetLocalizationString('select_grievance_services') : GetLocalizationString('select_services')}</h2>
                            </div>)
                        }
                        {this.state.service_categories}
                    </div>
                </div>
            </section>
            
        </>
        )
    }
}

export default SelectSubCategories
