////////////////////////////////////////////////////////////
//     							                          //
//  Program: Timer.jsx                                    //
//  Application: commpon                                  //
//  Option: For countdown timer into project              //
//  Developer: NP  						                   //
//  Date: 2021-03-09                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from 'react';
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowNotifications } from '../../Helpers/CustomHelper';
import AuthenticationService from '../../services/AuthenticationService'

class Timer extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            start:Date.now(),
            diff:0,
            minutes:0,
            seconds:0,
            duration : 60 * this.props.minutes,
            resend: false,
        }
        this.timerState = '';
        this.timerCount = '';
        this.count = 0;
        this.resendOTP = this.resendOTP.bind(this);
    }

    componentDidMount (){
        HidePreloader()
        SetDefaultLanguage()
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(()=>{
            this.timer();
        },1000)
    }

    starttimer(){
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(()=>{
            this.timer();
        },1000)
    }

    componentWillUnmount() {
        window.clearInterval(this.timerCount);
    }

    timer() {
        let diff = this.state.diff;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;

        diff = this.state.duration - (((Date.now() -  this.state.start) / 1000) | 0);
        minutes = (diff / 60) | 0;
        seconds = (diff % 60) | 0;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({minutes:minutes,seconds:seconds});

        if (diff <= 0) {
            this.setState({resend:true});
            window.clearInterval(this.timerCount);
        }
    }

    resendOTP(evt) {
        evt.preventDefault();
        let input = {
                mobile: this.props.mobile,
                type: this.props.request_for
        }
        AuthenticationService.sendOtp(input)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                console.log(res);
                this.setState({start:Date.now(),minutes:0,seconds:0,duration: 60 * this.props.minutes});
                this.setState({resend:false});
                this.starttimer();
                ShowNotifications('success', GetLocalizationString('otp_sent_successfully'),1500)
            }else if(res.status === 401){
                console.log(res);
                ShowNotifications('error', GetLocalizationString('user_not_found'),1500)
            }else if(res.status === 400){
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            console.log(errors);
        });
          
    }

    render () {
        return(
            <>
            <div className="row">
            {
            (this.state.resend) 
            ? <div className="col-6"><a className="timerText">00:00</a></div>
            : (<div className="col-6"><a className="timerText">{(this.state.minutes > 0) ? this.state.minutes : '00'}:{(this.state.seconds > 0) ? this.state.seconds : '00'}</a></div>) }
            {
            (this.state.seconds <= 0 && this.state.minutes <= 0) 
            ?
            <div className="col-6 text-right pr-5" onClick={this.resendOTP}>
                <a className="btn btn-link">
                {GetLocalizationString('resend_otp')}
                </a>
            </div>
            :
            <div className="col-6 text-right pr-5" style={opacity_style}>
                <a className="btn btn-link">
                {GetLocalizationString('resend_otp')}
                </a>
            </div>
            }
            </div>
            </>
        );
    }
}
const opacity_style ={
    opacity:"0.5"
}
export default Timer;
