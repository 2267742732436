////////////////////////////////////////////////////////////
//     							                          //
//  Program: AuthenticationService.js                     //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer:   						                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class AuthenticationService {

    signin(data) {
        let endPoint = 'login';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    register(data) {
        let endPoint = 'register';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    sendOtp(data) {
        let endPoint = 'SendOTP';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    check_mobile_exists(data) {
        let endPoint = 'CheckMobileExist';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    verifyOtp(data) {
        let endPoint = 'VerifyOTP';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getProfile() {
        let endPoint = 'get-profile';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateProfile(data) {
        delete data.errors;
        delete data.input;

        let endPoint = 'update-profile';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    uploadProfileImage(data) {
        delete data.errors;
        delete data.input;

        let endPoint = 'update-profile-with-image';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getSetting() {
        let endPoint = 'app-settings';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    logout() {
        let endPoint = 'user-logout';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    delete_account() {
        let endPoint = 'delete-account';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

}

export default new AuthenticationService()