/*global google*/
import React, { Component } from 'react'
import HomeService from "./../services/HomeService";
import { ShowNotifications, ShowPreloader, HidePreloader,GetLocalizationString } from './../Helpers/CustomHelper'
import ArrowLeft from "./../assets/images/al.png"
import ArrowRight from "./../assets/images/ar.png"
import { getLoggedInUser } from '../Helpers/CustomHelper';
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import default_user_img from "./../assets/images/default_user_img.png"
import { Rating } from 'react-simple-star-rating'
import { Link } from 'react-router-dom';
import AutoPng from "./../images/gps_on_auto.png"
import RedAutoPng from "./../images/gps_off_auto.png"
import DoctorPng from "./../images/doctor_map_icon.png"
import CurrentLocation from "./../assets/images/current_location.png"
import whatsaap from "./../assets/images/social/whatsapp_new.png"
import call from "./../assets/images/social/call_new.png"
import { isEmptyObject } from 'jquery';

class ProvidersListMap extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            ProvidersList: [],
            category_detail: this.props.history.location.category_info ?? {},
            parent_category_id: this.props.history.location.category_info ? this.props.history.location.category_info.level1_service_category_id : 0,
            showingInfoWindow: false,
            selectedProvider: {},
            current_address:'',
            longitude: 0,
            latitude: 0,
            currentLocation: {}
        };
        this.handleClose = this.handleClose.bind(this);
        this.UpdateCurrentLocation = this.UpdateCurrentLocation.bind(this)
        this.getReverseGeocodingData = this.getReverseGeocodingData.bind(this)
    }

    getReverseGeocodingData(lat, lng) {
        var latlng = new google.maps.LatLng(lat, lng)
        var geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
            if (status !== google.maps.GeocoderStatus.OK) {
                console.log(status)
            }
            if (status == google.maps.GeocoderStatus.OK) {
                this.setState({
                    current_address: results[0].formatted_address
                })
            }
        });
    }

    UpdateCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position)=> {
            this.setState({
                currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude },
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
            setTimeout(() => {
                this.getReverseGeocodingData(this.state.latitude, this.state.longitude)
            }, 100);
            
            });
        }
    }

    handleMarkerClick = (marker, e) => {
        console.log(marker)
        this.setState({
            selectedProvider: marker.provider,
            showingInfoWindow: true
        });
    };

    handleClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                selectedProvider: {}
            });
        }
    };

    UNSAFE_componentWillMount(){
        if(window.google &&
        window.google.maps) {
            window.location.reload()
        }
    }

    componentDidMount() {
        ShowPreloader()
        this.UpdateCurrentLocation()
        var params = { 
            "slug": this.props.match.params.slug,
            "type": (this.state.parent_category_id == process.env.REACT_APP_TRANSPOTATION_ID) ? 'Transpotation' : (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'Doctor' : (this.state.parent_category_id == process.env.REACT_APP_HOUSEHOLD_ID) ? 'Household' : '',
            "search": "",
            "latitude":process.env.REACT_APP_DEFAULT_LAT,
            "longitude": process.env.REACT_APP_DEFAULT_LONG,
            "sort_order": "ASC",
            "sort_column": "last_name",
            'is_map_view': 1,
            "user_id": getLoggedInUser().user_id ?? 0
        }
        HomeService.GetProvidersList(params)
        .then((res) =>{
        if((global.successStatus).includes(res.status)) {
            HidePreloader()
            let ProvidersList = res.data.providers;
            if(ProvidersList.length > 0){
                ProvidersList = ProvidersList.map(function(provider, i){
                    return {
                        provider,
                        name: provider.address,
                        location: { 
                          lat: (isEmptyObject(provider.gps_provider_location)) ? provider.latitude : (!isEmptyObject(provider.gps_provider_location) && provider.gps_provider_location.status_id == 1) ? provider.gps_provider_location.latitude : provider.latitude,
                          lng: (isEmptyObject(provider.gps_provider_location)) ? provider.longitude : (!isEmptyObject(provider.gps_provider_location) && provider.gps_provider_location.status_id == 1) ? provider.gps_provider_location.longitude : provider.longitude,
                        }
                    }
                })
                this.setState({
                    ProvidersList:ProvidersList,
                    isLoaded:true
                })
            }
            
        }else if(res.status === 401){
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }else if(res.status === 400){
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        else{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });

        setTimeout(() => {
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(this.state.ProvidersList)
        }, 2000);


        if(this.props.history.location.category_info){
            localStorage.setItem('category_detail', JSON.stringify(this.state.category_detail))
        }else{
            var category_data = JSON.parse(localStorage.getItem('category_detail'))
            setTimeout(() => {
                this.setState({
                    category_detail:category_data
                })  
            }, 100);
            
        }
    }
    
    render() {
        return (
        (this.state.ProvidersList.length <= 0 && this.state.isLoaded)
        ?
        <>
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                    <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                    </div>
                    <div className="col-8">
                        <h1 className="head_heading">{GetLocalizationString('Location')}</h1>
                    </div>
                    <div className="col-2 text-right"></div>
                </div>
            </div>
        </header>
        <div className="success_box">
            <h5 className="app_done">{GetLocalizationString('no_provider')}</h5>
        </div>
        </>
        
        :
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('Location')}</h1>
                        </div>
                        <div className="col-2 text-right"><Link to={
                            () => {
                                return {
                                  pathname: '/get-providers-list/' + this.state.category_detail.slug,
                                  category_info: this.state.category_detail
                                }
                            }
                        }>{GetLocalizationString('listing')}</Link></div>
                    </div>
                </div>
            </header>

            <section className="location_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 p-0" id='GoogleMapContainer'>
                        {
                        (this.state.ProvidersList.length <= 0 && this.state.isLoaded)
                        ?
                        ''
                        :
                        <>
                        {
                        (this.state.category_detail.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID)
                        ?
                        <div className="row m-0">
                            <div className="col-6 text-right">
                                <div className="gps_info">
                                    <p> <span className="gps_green">.</span> {GetLocalizationString('gps_on')}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                 <div className="gps_info">
                                    <p> <span className="gps_green gps_red">.</span> {GetLocalizationString('gps_off')}</p>
                                </div>
                            </div>

                        </div>
                        :
                        ''
                        }
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
                            <GoogleMap
                            defaultOptions={{ fullscreenControl: false }}
                            mapContainerStyle={mapStyles}
                            zoom={12}
                            center={{
                                lat: parseFloat(this.state.latitude),
                                lng: parseFloat(this.state.longitude)
                            }}
                            >
                                {
                                
                                    this.state.ProvidersList.map((item,i) => {
                                        return (
                                            <Marker 
                                            options={
                                                { 
                                                icon: { 
                                                    url: (item.provider.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID) ? (isEmptyObject(item.provider.gps_provider_location)) ? RedAutoPng :(!isEmptyObject(item.provider.gps_provider_location) && item.provider.gps_provider_location.status_id == 1) ? AutoPng : RedAutoPng : DoctorPng
                                                    } 
                                                }
                                            }
                                            key={item.name+i} 
                                            position={item.location}
                                            placeIndex={i}
                                            onClick={this.handleMarkerClick.bind(this, item)}
                                            style={{height:"45px", width:"45px"}}
                                            />
                                        )
                                    })
                                    
                                }

                                <Marker 
                                options={
                                    { 
                                    icon: { url: CurrentLocation } 
                                    }
                                }
                                key={'my-location'} 
                                position={{lat: this.state.latitude, lng: this.state.longitude}}
                                placeIndex={0}
                                style={{height:"45px", width:"45px"}}
                                />
                                
                            </GoogleMap>
                        </LoadScript>
                        </>
                        }
                        </div>
                    </div>
                </div>
            </section>
            {
            (this.state.showingInfoWindow == true)
            ?
            (
            <section className="Foot_btn_first foot_btn_second">
                <div className="container">
                    <b className="close_btn" style={CrossBtn} aria-hidden="true" onClick={this.handleClose}><i className='fa fa-close'></i></b>
                    <div className="row">
                        <div className="col-12">
                            <div className=" heath_detail box-multiple box_sd_none p-0 m-0">
                                <div className="media">
                                <div className="media-body p-0">
                                    <h5>{this.state.selectedProvider.full_name}<span> {this.state.selectedProvider.service_category_title}</span></h5>
                                    {(this.state.selectedProvider.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID && this.state.selectedProvider.unique_id != '')
                                    ?
                                    
                                    (this.state.selectedProvider.unique_id != '' && this.state.selectedProvider.unique_id != null)
                                    ?
                                    <p>({this.state.selectedProvider.unique_id})</p>
                                    :
                                    ''
                                    :
                                    ''
                                    }
                                    {
                                    (this.state.selectedProvider.level1_service_category_id != process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                                    ?
                                    <div className="star_view">
                                        
                                    < Rating size="15" initialValue={this.state.selectedProvider.average_rating} readonly="true" />
                                    {
                                    this.state.selectedProvider.average_rating > 0
                                    ?
                                    <span className="star_number">{this.state.selectedProvider.average_rating}</span> 
                                    :
                                    <span className="star_number">0.0</span>
                                    }                               
                                    </div>
                                    :
                                    ''
                                    }
                                    
                                    {(this.state.selectedProvider.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                                    ?
                                    <>
                                    <p>{GetLocalizationString('general_fees')} ₹ {this.state.selectedProvider.advance_fees ?? 0} <span>| {GetLocalizationString('discounted_fees')} ₹  {this.state.selectedProvider.discount_fees ?? 0}</span> </p>

                                    <div className="proive_info">
                                        <ul>
                                            {(this.state.selectedProvider.night_consultation == 1) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                                            
                                            {(this.state.selectedProvider.video_consultation == 1) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                                        </ul>
                                    </div> 
                                    </>
                                    :
                                    ''
                                    }
                                    <div className="contact_details">
                                    <ul>
                                    <li><a href={'tel:'+this.state.selectedProvider.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.state.selectedProvider.mobile}</a></li>
                                    {
                                    this.state.selectedProvider.whatsapp_number != ''
                                    ?
                                    <li><a href={process.env.REACT_APP_WHATSAPP_LINK+this.state.selectedProvider.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.state.selectedProvider.whatsapp_number}</a></li>
                                    :
                                    ''
                                    }
                                    </ul>
                                </div>
                                   
                                    
                                </div>
                                <img className="" src={this.state.selectedProvider.profile_image_url ?? default_user_img} alt=""/>

                                </div>
                                
                               
                                <div className="col-12 text-center">
                                <Link className="btn btn-theme btn_v_pro"
                                    to={() => {
                                        return {
                                          pathname: '/provider-detail/' + this.state.selectedProvider.slug,
                                          provider_info: this.state.selectedProvider
                                        }
                                      }}> {GetLocalizationString('view_profile')} <img src={ArrowRight} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            )
            :''
            }

        </>
    
        )
    }
}
const mapStyles = {        
    height: "calc(100vh - 64px)",
    width: "100%"
}
const CrossBtn = {
    float: 'right'
}

export default ProvidersListMap