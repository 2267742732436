////////////////////////////////////////////////////////////
//     							                          //
//  Program: SendOTP.jsx                                  //
//  Application: authentication                           //
//  Option: For sign in into project                      //
//  Developer: 						                      //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { Link, Redirect } from "react-router-dom";
import ArrowRignt from "./../../assets/images/ar.png"
import ArrowLeft from "./../../assets/images/al.png"
import { HidePreloader, ShowNotifications, ShowPreloader, SetDefaultLanguage, GetLocalizationString, GetValidationString } from '../../Helpers/CustomHelper';

class SendOTP extends Component {
  constructor(props) {
      super(props)
      this.state = {
          isSubmit: false,
          showAlertModal: false,
          alertModalType: '',
          alertModalTitle: '',
          alertModalMessage: '',
          mobile: '',
          headerToken: localStorage.getItem('headerToken'),
          default_lang: localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE,
      }

    this.validator = new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            valid_mobile: {  
              message: GetValidationString('please_select_phone_no',this.state.default_lang),
              rule: (val) => {
                const regexExp = /^[6-9]\d{9}$/gi;
                if(regexExp.test(val)){
                    return true 
                }
                return false
              }
            },
            mobile: {  
                required:true,
                message: GetValidationString('mobile_required',this.state.default_lang),
                rule: (val) => {
                    if(val.trim() != ''){
                        return true 
                    }
                    return false
                  }
            },
        },
        messages: {
            mobile: GetValidationString('mobile_required',this.state.default_lang),
            numeric:GetValidationString('only_digit',this.state.default_lang),
            size:GetValidationString('phone_no_size',this.state.default_lang)
        }
    });
      
      this.closeModal = this.closeModal.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.SendOTP = this.SendOTP.bind(this);
  }

  closeModal() {
      this.setState({ showAlertModal: false });
  }

  componentDidMount() {
      SetDefaultLanguage(localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE)
      HidePreloader()
      /*if(this.state.headerToken !== null || this.state.headerToken !== ''){
          localStorage.clear();
          sessionStorage.clear();
      }*/
  }

  SendOTP = (e) => {
      this.validator.messages = {
        mobile: GetValidationString('mobile_required',this.state.default_lang),
        numeric:GetValidationString('only_digit',this.state.default_lang),
        size:GetValidationString('phone_no_size',this.state.default_lang)
      };
      ShowPreloader()
      e.preventDefault();
      if(this.validator.allValid()){ 
          this.setState({isSubmit: true});
          let input = {
                mobile: this.state.mobile,
          }
          AuthenticationService.sendOtp(input)
          .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                localStorage.setItem('register_request_type','login')
                localStorage.setItem('register_mobile','')
                localStorage.setItem('register_first_name','')
                localStorage.setItem('register_last_name','')
                localStorage.setItem('register_role_id','')
                localStorage.setItem('register_default_language','')
                ShowNotifications('success', GetLocalizationString('otp_sent_successfully'),1500).then(()=>{
                    HidePreloader()
                    this.props.history.push({
                        pathname: '/verify-otp',
                        state: { 
                            mobile: this.state.mobile,
                            redirectTo: '/home'
                        }
                    });
                });
            }else if(res.status === 401){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('user_not_found'),1500)
            }else if(res.status === 400){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('user_not_found'),1500)
            }
            else{
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
          }).catch((errors)=>{
              HidePreloader()
              console.log(errors);
          });
         
    }else{
        HidePreloader()
        this.validator.showMessages();
    }
  }

  changeHandler = (event) => {
      this.setState({[event.target.name]:event.target.value});
      this.validator.showMessageFor(event.target.name);
  }

  getTitle() {
      return process.env.REACT_APP_PROJECT_TITLE ? process.env.REACT_APP_PROJECT_TITLE : '';
  }

  render() {
    if(this.state.headerToken !== null) {
        return <Redirect to='/home' />;
    }
    return (
        <>
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <a onClick={()=>{
                        this.props.history.push({
                            pathname: '/home'
                        });
                    }}><img src={ArrowLeft} alt="" /></a>
                    </div>
                    <div className="col-8">
                        
                    </div>
                    
                </div>
            </div>
        </header>
        <section className="login_page">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <h3>{GetLocalizationString('sign_in')}</h3>
                    <form action="#">
                        <div className="form-group">
                        <label className="float-left">{GetLocalizationString('phone_no')}</label>
                            <input 
                            type="text"
                            className="form-control" 
                            name="mobile" 
                            placeholder=""
                            value={this.state.mobile}
                            onChange={this.changeHandler}
                            maxLength="10"
                            minLength="10"
                            />
                            {this.validator.message(
                                "mobile",
                                this.state.mobile,
                                "mobile|numeric|valid_mobile|size:10",
                                { className: "valid_info" }
                            )}
                        </div>
                        <button className="btn-theme" type="submit"  onClick={this.SendOTP}> {GetLocalizationString('sign_in')} <img src={ArrowRignt} alt=""/> </button>
                    </form>
                    <p>{GetLocalizationString('dont_have_an_account')} <Link to="/register" className="btn btn-link">{GetLocalizationString('sign_up_small')}</Link></p>
                    
                </div>
            </div>
            </div>
        </section>
        </>
      
    )
  }
}

export default SendOTP
