////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import ArrowRight from "./../assets/images/ar.png"
import { Rating } from 'react-simple-star-rating'
import HomeService from "./../services/HomeService";
import { getLoggedInUserId, ShowNotifications, ShowPreloader, HidePreloader, GetLocalizationString, GetValidationString, SetDefaultLanguage } from './../Helpers/CustomHelper';
import SimpleReactValidator from 'simple-react-validator'

class SendProviderReview extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            error: null,
            isLoaded: false,
            provider_info:{},
            message:'',
            isSubmit: false,
            rating: 0,
            actual_rating: 0,
            default_lang: localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE,
          
        };
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: GetValidationString('required_fields',this.state.default_lang),
            }
        });

        this.handleRating = this.handleRating.bind(this);
        this.SubmitReview = this.SubmitReview.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    handleRating(rate){
        if(rate > 0){
            let NewRate = rate/20
            this.setState({
                actual_rating:NewRate,
                rating:rate
            })
        
        }
    }

    changeHandler = (event) => {
        this.setState({[event.target.name]:event.target.value});
        this.validator.showMessageFor(event.target.name);
    }

    SubmitReview = (e) => {
        ShowPreloader()
        e.preventDefault();
        if(this.validator.allValid() && this.state.rating > 0){ 
            this.setState({isSubmit: true});
            let user_id = getLoggedInUserId();
            var params = {
                "review_description": this.state.message,
                "review_rating":this.state.actual_rating,
                "user_id": user_id,
                "slug": this.props.match.params.slug,
            }
            HomeService.SendProviderReview(params)
            .then((res) =>{
                HidePreloader()
            if((global.successStatus).includes(res.status)) {
                this.props.history.push({
                    pathname: '/review-sent',
                    slug: this.props.match.params.slug
                });

            }else if(res.status === 401){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            }).catch((errors)=>{
                HidePreloader()
                console.log(errors);
            });
        }else{
            this.validator.showMessages();
            HidePreloader()
            if(this.state.rating == 0){
                ShowNotifications('error', GetLocalizationString('give_rate'),1500)
            }
        }
    }
    componentDidMount(){
        HidePreloader()
        SetDefaultLanguage(localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE)
    }
    render() {
        return (
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt=""/></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('review')}</h1>
                        </div>
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                        <div className="rating_sys">
                            <h5>{GetLocalizationString('Ratings')}</h5>
                            <Rating size="35" onClick={this.handleRating} ratingValue={this.state.rating} transition={true} allowHalfIcon={true}/>
                            <h5 className="pt-4">{GetLocalizationString('Message')}</h5>
                            <form>
                                <div className="form-group"> 
                                    <textarea name="message" id="" className="form-control" rows="7" onChange={this.changeHandler} placeholder={GetLocalizationString('write_here')}>{this.state.message}</textarea>
                                    {this.validator.message(
                                        "message",
                                        this.state.message,
                                        "required",
                                        { className: "valid_info" }
                                    )}
                                </div>
                                <a onClick={this.SubmitReview} className="btn btn-theme">{GetLocalizationString('submit')}<img src={ArrowRight} alt=""/></a>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </section>            
            </>
          )
    }
}

export default SendProviderReview