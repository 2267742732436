////////////////////////////////////////////////////////////
//     							                          //
//  Program: SplashScreen.jsx                             //
//  Application: Splash Screen                            //
//  Option: For Splash Screen                             //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-08-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import { HidePreloader, ShowPreloader, ShowNotifications, GetLocalizationString } from '../Helpers/CustomHelper'
import ArrowLeft from "./../assets/images/al.png"
import HomeService from '../services/HomeService'

export class GrievanceCategoryDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded:false,
            loading: true,
            contacts: [],
            category_info: {},
            page: 1,
            per_page: 100
        }
    }

    componentDidMount() {
        HidePreloader()
        let routeState
        if(this.props.location.category_info){
            routeState = this.props.location.category_info
            localStorage.setItem('category_info', JSON.stringify(this.props.location.category_info))
            this.setState({
                category_info: routeState
            })
        }else{
            let category_info = localStorage.getItem('category_info')
            if(category_info) category_info = JSON.parse(category_info)
            this.setState({
                category_info:category_info,
            }) 
        }

        setTimeout(() => {
            ShowPreloader()
            var params = {
                "per_page": this.state.per_page,
                "page": this.state.page,
                "slug" : this.state.category_info.slug
            }
            
            HomeService.GetGrievanceContacts(params)
            .then((res) =>{
                if((global.successStatus).includes(res.status)) {
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false,
                        contacts: res.data.contacts,
                    })          
                }else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
            }).catch((errors)=>{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(errors);
            });
        }, 100);

    }

    render() {
        return (
            (this.state.isLoaded)
            ?
            <>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-2">
                                <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                            </div>
                            <div className="col-8">
                                <h1 className="head_heading">{this.state.category_info.grievance_category_title}</h1>
                            </div>

                        </div>
                    </div>
                </header>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {
                                    (this.state.contacts.length <= 0)
                                    ?
                                    <>
                                    <div className="success_box no_cnt_data">
                                        <h5 className="app_done">{GetLocalizationString('no_contact')}</h5>
                                    </div>
                                    </>
                                    :
                                    this.state.contacts.map((contact, index)=>{
                                        return <div className="virtual_box" key={index}>
                                        <h4>{contact.contact_title.replace(/['"]+/g, '')}</h4>
                                        <p>{contact.grievance_number}</p>
                                        <p>{contact.grievance_email}</p>
                                    </div>
                                    })
                                    
                                }
                            </div>
                        </div>
                    </div>
                </section>


            </>
            :
            ''
        )
    }
}

export default GrievanceCategoryDetail