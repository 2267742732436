////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Logout.jsx                                   //
//  Application: authentication                           //
//  Option: For logout                                    //
//  Developer: NP  						                            //
//  Date: 2021-12-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'

export class Logout extends Component {
  constructor(props) {
      super(props)

      this.logout = this.logout.bind(this);
  }

  componentDidMount() {
      this.logout();
  }

  logout = (e) => {
    var UserDefaultLang = localStorage.getItem('default-user-lang');   
    if(global.headerToken){
        AuthenticationService.logout();
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('default-user-lang',UserDefaultLang);
        setTimeout(() => {
          this.props.history.push('/login');
        }, 1000);
        
    }else{
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('default-user-lang',UserDefaultLang);
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1000);
    }
  }

  render() {
    return (
      <div></div>
    )
  }

}

export default Logout
