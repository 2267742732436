////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import default_user_img from "./../assets/images/default_user_img.png"
import booking_img from "./../assets/images/icon/booking.png"
import patient_booking_img from "./../assets/images/icon/patient_booking.png"

import account_img from "./../assets/images/icon/user.png"
import review_img from "./../assets/images/icon/satisfaction.png"
import logout_img from "./../assets/images/icon/shutdown.png"
import AuthenticationService from '../services/AuthenticationService'
import { Link } from 'react-router-dom'
import { GetLocalizationString, HidePreloader, ShowPreloader } from '../Helpers/CustomHelper'

export class ProviderProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            isLoaded:false
        }
    }

    componentDidMount() {
        ShowPreloader()
        AuthenticationService.getProfile()
        .then((res)=> {
            console.log(res)
            if((global.successStatus).includes(res.status)) {
               HidePreloader()
               this.setState({
                   isLoaded: true,
                   user_detail:res.data
               })
            }else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res) 
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.error(errors)
        })
                
    }
    render() {
        return (
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('about')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="profile_list">
                                <div className="media" >
                                    <div className="media-body pt-2">
                                    <h5>{this.state.user_detail.full_name}</h5>
                                    <p>{this.state.user_detail.mobile}</p>
                                    </div>
                                    <img src={this.state.user_detail.profile_image_url ?? default_user_img} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box_cat">
                            <Link to={`/provider-bookings/`+this.state.user_detail.slug}>
                            <img src={booking_img} alt=""/>
                            <h3>{GetLocalizationString('my_booking')}</h3>
                            </Link>
                        </div>
                        <div className="box_cat">
                            <Link to={`/patient-bookings/`+this.state.user_detail.slug}>
                                <img src={patient_booking_img} alt=""/>
                                <h3>{GetLocalizationString('patient_booking')}</h3>
                            </Link>
                        </div>
                        <div className="box_cat">
                            <Link to={`/update-profile`}>
                                <img src={account_img} alt=""/>
                                <h3>{GetLocalizationString('my_account')}</h3>
                            </Link>
                        </div>
                        <div className="box_cat">
                            <Link to={`/my-reviews/`+this.state.user_detail.slug}>
                                <img src={review_img} alt=""/>
                                <h3>{GetLocalizationString('review')}</h3>
                            </Link>
                        </div>
                        
                        <div className="box_cat">
                            <Link to={`/logout`}>
                                <img src={logout_img} alt=""/>
                                <h3>{GetLocalizationString('logout')}</h3>
                            </Link>
                        </div>
                        
                        
                    </div>
                </div>
            </section>
    
            </>
          )
    }
}

export default ProviderProfile