////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import AuthenticationService from '../services/AuthenticationService'
import HomeService from '../services/HomeService'
import { HidePreloader, ShowNotifications, ShowPreloader, GetLocalizationString } from './../Helpers/CustomHelper'
import { Rating } from 'react-simple-star-rating'

export class ProviderReviews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            reviews: [],
            total_reviews: 0,
            per_page: process.env.REACT_APP_PER_PAGE,
            page:1,
            loading:false,
            isLoaded:false
        }
        this.GetAllReviews = this.GetAllReviews.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        ShowPreloader()
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
               this.setState({
                   user_detail:res.data
               })
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })

        /** get patient booking */
        this.GetAllReviews();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */   

            
    }

    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.total_reviews / this.state.per_page)
        let totalPages =  (this.state.total_reviews % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true
                })
                this.GetAllReviews()
            }
        }
    };

    GetAllReviews(){
        var params = {
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
        }
        HomeService.GetProviderReviews(params)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                console.log(res)
                this.setState({
                    reviews:[...this.state.reviews,...res.data.reviews],
                    isLoaded:true,
                    loading: false,
                    total_reviews: res.data.total_reviews,
                })
                console.log('reviews ',res.data.reviews);            
            }else if(res.status === 401){
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
    }
    render() {
        return (
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('review')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                        {
                        (this.state.reviews.length <= 0 && this.state.isLoaded)
                        ?
                        
                        (
                            <div className="success_box no_cnt_data">
                                <h5 className="app_done">{GetLocalizationString('no_reviews_found')}</h5>
                            </div>
                        )
                        :
                        this.state.reviews.map((review, i) => {
                            return (
                                <div key={i} className={"mt-2"}>
                                    <div className="slider_review">
                                        <h5>{(review.full_name != ' ') ? review.full_name : 'Anonymous User'}</h5>
                                        <p className='mt-2'>{review.review_description ?? ''}</p>
                                        <div className="star_view">
                                            < Rating size="15" initialValue={review.review_rating} readonly="true" />
                                            <span className="star_number">{review.review_rating}</span> 
                                            
                                        </div>
                                    </div>
                                </div>
                            ) 
                        })
                        
                        }
                        </div>   
                    </div>
                </div>
            </section>

            {
            (this.state.loading == true)
            ?
            (
                <div style={loadingCSS}>
                    <center><b>Loading...</b></center>
                </div>
            )
            :
            ''
            }

            </>
          )
    }
}
const loadingCSS = { 
    height: "50px", 
    margin: "30px" 
};
export default ProviderReviews