////////////////////////////////////////////////////////////
//     							                          //
//  Program: RegisterSuccess.jsx                          //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import Done from "./../assets/images/icon/done.png"
import { HidePreloader, GetLocalizationString } from './../Helpers/CustomHelper';

export class ReviewSent extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        HidePreloader()
        console.log(this.props)
        setTimeout(()=>{
            this.props.history.push({
                pathname: '/provider-detail/'+this.props.location.slug
            });
        },2000)
    }


    render() {
        return (
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="success_box_review">
                                <img src={Done} alt="" className="ïmg-fluid" />
                                <p className='mt-2'>{GetLocalizationString('review_sent_success')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ReviewSent