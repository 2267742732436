////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import default_user_img from "./../assets/images/default_user_img.png"
import AuthenticationService from '../services/AuthenticationService'
import HomeService from '../services/HomeService'
import { Link } from 'react-router-dom'
import { ShowNotifications, ShowPreloader, HidePreloader, GetLocalizationString, SetDefaultLanguage } from './../Helpers/CustomHelper'
import call from "./../assets/images/social/call_new.png"
import Moment from "moment";

export class PatientBookings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            patient_bookings: [],
            appointment_dates: [],
            selected_date: '',
            patient_bookings_count: 0,
            loading: false,
            page: 1,
            per_page: process.env.REACT_APP_PER_PAGE,
            isLoaded: false,
            isDateSlected: false
        }
        this.ChooseDate = this.ChooseDate.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage()
        AuthenticationService.getProfile()
        .then((res)=> {
            console.log(res)
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    user_detail:res.data,
                })
            }else{
                HidePreloader()
                console.log(res) 
            }
        }).catch((errors)=>{
            HidePreloader()
            console.error(errors)
        })

        /** get patient booking */
        this.getPatientBookings();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */   

                
    }
    
    getPatientBookings = () => {
        ShowPreloader()
        var params = {
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
            "type": 1,
            "appointment_date": this.state.selected_date
        }
        HomeService.GetAllBookings(params)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    patient_bookings:[...this.state.patient_bookings,...res.data.patient_bookings],
                    loading: false,
                    isLoaded:true,
                    patient_bookings_count: res.data.patient_bookings_count,
                    appointment_dates:res.data.appointment_dates
                })
                console.log('patient_bookings ',this.state.appointment_dates);            
            }else if(res.status === 401){
                HidePreloader()
                this.setState({
                   isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                HidePreloader()
                this.setState({
                   isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                this.setState({
                   isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
    }

    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.personal_bookings_count / this.state.per_page)
        let totalPages =  (this.state.personal_bookings_count % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true
                })
                this.getPatientBookings()
            }
        }
    };

    ChooseDate(e, key){
        ShowPreloader()
        var input = this.state.appointment_dates[key].appointment_date
        this.setState({
            selected_date:input,
            page:1,
            isLoaded:false,
            patient_bookings: [],
            patient_bookings_count: 0,
            isDateSlected:true

        })
        setTimeout(() => {
           this.getPatientBookings() 
        }, 500);        
    }
    SeeAll(){
        ShowPreloader()
        this.setState({
            selected_date:'',
            page:1,
            isLoaded:false,
            patient_bookings: [],
            patient_bookings_count: 0,
            isDateSlected:false

        })
        setTimeout(() => {
           this.getPatientBookings() 
        }, 200);        
    }
    render() {
        return (
            (this.state.isLoaded === true)
            ?
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('patient_booking')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section className="patient_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {
                            (this.state.appointment_dates.length > 0 && this.state.isLoaded) 
                            ?
                            <div className="slot_scroll ">
                                <h4 className='mt-1'>{GetLocalizationString('date')}</h4>
                                <ul id='patient_date_slot'>
                                    <li id="seeAllBooking" className={this.state.isDateSlected ? "slot-time-box" : "slot-time-box active"} onClick={event => {this.SeeAll()}}><a>{GetLocalizationString('see_all')}</a></li>
                                    {
                                        
                                        this.state.appointment_dates.map((appointment_date,index)=> {
                                            return  <li key={index} className={(appointment_date.is_active) ? "slot-time-box active": "slot-time-box"}><a onClick={event => this.ChooseDate(event, index)}>{Moment(appointment_date.appointment_date).format("DD MMM")}</a></li>
                                        })
                                        
                                    }
                                </ul>
                            </div>
                            :
                            ''
                            }
                            {
                            (this.state.patient_bookings.length <= 0 && this.state.isLoaded)
                            ?
                            
                            (
                                <div className="success_box no_cnt_data">
                                    <h5 className="app_done">{GetLocalizationString('no_appointment')}</h5>
                                </div>
                            )
                            :
                            this.state.patient_bookings.map((booking, i) => {
                                return (
                                  

                                  <div className="heath_detail box-multiple mt-4" key={i}>
                                        <Link to={() =>{
                                            return {
                                                pathname: '/booking-detail/provider', 
                                                booking_info: booking
                                            }
                                        }}>
                                        <div className="media" >
                                            <div className="media-body ml-2">
                                            <h5>{booking.full_name} </h5>
                                            <p className=''><span className={`badge ${booking.badge_class}`}> <span className="dot"></span> {booking.appointment_status}</span>  </p>
                                            
                                            <div className="contact_details ">
                                                <ul className="">
                                                    <li><span className='booking_mobile'><img src={call} alt="" className="icon_syml_call" /> {booking.mobile}</span></li>
                                                </ul>
                                            </div>
                                            </div>
                                            <img src={(booking.profile_image) ?? default_user_img} alt=""/>

                                        </div>
                                        </Link>
                                    </div>
                                ) 
                            })
                            }
                        </div>   
                    </div>
                </div>
            </section>

            </>
            :
            ''
          )
    }
}

export default PatientBookings