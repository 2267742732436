////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Category.jsx                                 //
//  Application: Dashboard                                //
//  Option: to view Category                              //
//  Developer: Anil kumar Gupta  						              //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ArrowRignt from "./../../assets/images/ar.png"
import call from "./../../assets/images/social/call_new.png"
import whatsaap from "./../../assets/images/social/whatsapp_new.png"
import { Rating } from "react-simple-star-rating";
import { GetLocalizationString } from "../../Helpers/CustomHelper";


class Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false
    };

  }

  componentDidMount(){
    console.log(this.props)
  }
  render() {
    return (
      <>
        <div className="" >
            <div className="row" >
              <div className="col-12">
            
                {/* For provide */}

                {
                (this.props.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                ?
                (
                  <div className="doctor_details heath_detail box-multiple">
                    <div className="media">
                      <div className="media-body p-0">
                        <h5>{this.props.provider_info.full_name}  <span>{this.props.provider_info.service_category_title}</span></h5>
                        <p>{GetLocalizationString('general_fees')} ₹ {this.props.provider_info.advance_fees ?? 0} <span>| {GetLocalizationString('discounted_fees')} ₹  {this.props.provider_info.discount_fees ?? 0}</span> </p>
                        <div className="proive_info">
                          <ul>
                            {(this.props.provider_info.night_consultation == 1) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                            
                            {(this.props.provider_info.video_consultation == 1) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                          </ul>
                        </div>
                        <div className="contact_details">
                      <ul>
                        <li><a href={'tel:'+this.props.provider_info.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.props.provider_info.mobile}</a></li>
                        {
                        this.props.provider_info.whatsapp_number != ''
                        ?
                        <li><a href={process.env.REACT_APP_WHATSAPP_LINK+this.props.provider_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.props.provider_info.whatsapp_number}</a></li>
                        :
                        ''
                        }
                      </ul>
                    </div>
                      </div>
                      <img className="" src={this.props.provider_info.profile_image_url} alt="" />
                    </div>
                    
                    <div className="col-12 text-center">
                    <Link className="btn btn-theme btn-book_suss"
                      to={() => {
                        return {
                          pathname: '/provider-detail/' + this.props.provider_info.slug,
                          provider_info: this.props.provider_info
                        }
                      }}>
                          {GetLocalizationString('book_appointment')} <img src={ArrowRignt} alt="" />
                    </Link>

                    </div>
                  </div>

                )
                :
                (
                  <div className="doctor_details heath_detail box-multiple">
                    <div className="media">
                      <div className="media-body p-0">
                        <h5>{this.props.provider_info.full_name}  <span>{this.props.provider_info.service_category_title}</span></h5>
                        
                        {(this.props.provider_info.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID && this.props.provider_info.unique_id !='') ? (<p>({this.props.provider_info.unique_id})</p>) : ''}
                    
                        <div className="star_view">
                            < Rating size="15" initialValue={this.props.provider_info.average_rating} readonly="true" />
                            {
                              this.props.provider_info.average_rating > 0
                              ?
                              (
                              <span className="star_number">{this.props.provider_info.average_rating}</span>
                              )
                              :
                              <span className="star_number">0.0</span>
                            }                               
                        </div>
                        
                        <div className="contact_details">
                      <ul>
                      <li><a href={'tel:'+this.props.provider_info.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.props.provider_info.mobile}</a></li>
                      {
                      this.props.provider_info.whatsapp_number != ''
                      ?
                      <li><a href={process.env.REACT_APP_WHATSAPP_LINK+this.props.provider_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.props.provider_info.whatsapp_number}</a></li>
                      :
                      ''
                      }
                      </ul>
                    </div>
                      </div>
                      <img className="" src={this.props.provider_info.profile_image_url} alt="" />

                      
                    </div>
                  
                    <div className="col-12 text-center">
                    <Link className="btn btn-theme btn_v_pro"
                        to={() => {
                          return {
                            pathname: '/provider-detail/' + this.props.provider_info.slug,
                            provider_info: this.props.provider_info
                          }
                        }}>
                          {GetLocalizationString('view_profile')}  <img src={ArrowRignt} alt="" />
                          </Link>
                    </div>
                  </div>
                )
                }
                
              </div>
            </div>
        </div>
      </>
    );
  }
}

export default Provider;
