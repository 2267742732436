import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import ArrowRight from "./../assets/images/ar.png"
import PhonePe from "./../assets/images/payment/phonepe.png"
import GooglePay from "./../assets/images/payment/Gpay.png"
import Bhimupi from "./../assets/images/payment/bhim.png"
import Paytm from "./../assets/images/payment/paytm.png"
import Upload from "./../assets/images/upload.png"
import default_user_img from "./../assets/images/default_user_img.png"
import { Rating } from 'react-simple-star-rating'
import HomeService from "./../services/HomeService";
import whatsaap from "./../assets/images/social/whatsapp_new.png"
import call from "./../assets/images/social/call_new.png"
import SimpleReactValidator from 'simple-react-validator'
import { ShowNotifications, getLoggedInUserId, ShowPreloader, HidePreloader, GetLocalizationString, SetDefaultLanguage } from './../Helpers/CustomHelper'
import "react-responsive-carousel/lib/styles/carousel.min.css" 
import { Link } from "react-router-dom"
import $ from 'jquery' 
import DoctorPng from "./../assets/images/provider_location.png"
import Infoicon from "./../assets/images/info.png"
/*import MapContainer from './MapContainer'*/
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

class ProviderDetail extends Component {
    
    
    ClickFileUpload(){
        $('#payment_screenshot').click()
    }

    SingleFileUpload = (event) => {
        if(event.target.files[0] !== undefined){
            var file = event.target.files[0]
            if(!this.isValidFileUploaded(file)){
                ShowNotifications('warning',GetLocalizationString('only_image_allowed'),1500)
                this.setState({
                    file: Upload,
                    file_input: null
                })
            }else{
                this.setState({
                    file: URL.createObjectURL(event.target.files[0]),
                    file_input: event.target.files[0],
                    is_uploaded: true
                })
            }    
        }else{
            this.setState({
                file: Upload,
                file_input: null
            })  
        }
        
    };

    RemoveProfileImage = () => {
        this.setState({
            file: Upload,
            file_input: null,
            is_uploaded: false
        })
    }
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });

        this.state = {
            error: null,
            isLoaded: false,
            provider_info:{},
            settings:{},
            provider_reviews:[],
            payment_instructions:[],
            slot_times:[],
            input_fields:{},
            file:Upload,
            file_input: null,
            is_uploaded: false,
            latitude:0,
            longitude: 0
        };
        this.UpdateSlotTime = this.UpdateSlotTime.bind(this)
        this.ChooseSlotTime = this.ChooseSlotTime.bind(this)
        this.BookAppointment = this.BookAppointment.bind(this)

        this.ClickFileUpload = this.ClickFileUpload.bind(this)
        this.SingleFileUpload = this.SingleFileUpload.bind(this)
        this.RemoveProfileImage = this.RemoveProfileImage.bind()
        this.RedirectToProviderReviews =this.RedirectToProviderReviews.bind(this)
        this.isValidFileUploaded = this.isValidFileUploaded.bind(this)
        this.OpenInMap = this.OpenInMap.bind(this)
    }

    RedirectToProviderReviews = () => {
        this.props.history.push({
            pathname: "/provider-reviews/"+this.props.match.params.slug
        });
    }

    OpenInMap(){
        var redirectTo = "google.navigation:q="+this.state.provider_info.latitude+","+this.state.provider_info.longitude
        window.open(redirectTo , '_system');
        /*window.open("geo:"+this.state.provider_info.latitude+","+this.state.provider_info.longitude+";u=35");*/
    }

    UpdateSlotTime(e, key){
        $('.slot-date-box').removeClass('active')
        e.currentTarget.className += " active";
        var slots = this.state.provider_info.SlotDates[key].timeSlots
        this.setState({
            slot_times:slots
        })
    }

    ChooseSlotTime(e, key){
        /*let tag = e.currentTarget.dataset.day*/
        $('.slot-time-box').removeClass('active')
        e.currentTarget.className += " active";
        var input = this.state.slot_times[key]
        this.setState({
            input_fields:input
        })
    }

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage()
        var params = {
            "slug": this.props.match.params.slug,
        }
        HomeService.GetProviderDetail(params)
        .then((res) =>{
        if((global.successStatus).includes(res.status)) {
            let Provider = res.data.provider
            let ProviderReviews = res.data.provider_reviews
            HidePreloader()
            if(Provider){
                console.log(res)
                /** Checking user is logged in or not */
                if(Provider.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID && getLoggedInUserId() == 0){
                    this.props.history.push({
                        pathname: '/logout'
                    });
                }
                this.setState({
                    provider_info:Provider,
                    settings: res.data.settings,
                    payment_instructions:Provider.payment_instructions,
                    slot_times:Provider.defaultSlots,
                    isLoaded: true
                })
            }
            if(ProviderReviews.length > 0){  
                this.setState({
                    provider_reviews:ProviderReviews,
                    isLoaded:true
                })
            }

            
        }else if(res.status === 401){
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }else if(res.status === 400){
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        else{
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        }).catch((errors)=>{
            console.log(errors);
        });

        /** setting up user current location */
        setTimeout(() => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=> {
                    console.log(position)
                  this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                  })              
                });
            }  
        }, 100);
        
    }

    BookAppointment(e){
        ShowPreloader()
        e.preventDefault();
        if(this.validator.allValid()){ 
            this.setState({isSubmit: true});
            if(!this.state.input_fields.slot_id){
                HidePreloader()
                ShowNotifications('error', GetLocalizationString('select_time'),5000)
                return false
            }
            var formData = new FormData();
            formData.append("appointment_date", this.state.input_fields.appointment_date)
            formData.append("day", this.state.input_fields.day)
            formData.append("ends_at", this.state.input_fields.ends_at)
            formData.append("starts_at", this.state.input_fields.starts_at)
            formData.append("provider_id", this.state.input_fields.provider_id)
            formData.append("slot_id", this.state.input_fields.slot_id)
            formData.append("payment_screenshot",this.state.file_input)

            HomeService.BookAppointment(formData)
            .then((res) =>{
              if((global.successStatus).includes(res.status)) {
                  HidePreloader()
                  ShowNotifications('success', GetLocalizationString('appointment_booked'),1500).then(()=>{
                    console.log(res)
                      this.props.history.push({
                          pathname: '/appointment-booked',
                          booking_info:{'provider_info' : this.state.provider_info,'slot_info':this.state.input_fields}
                      });
                  });
              }else if(res.status === 401){
                  HidePreloader()
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }else if(res.status === 400){
                  HidePreloader()
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
              else{
                  HidePreloader()
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
            }).catch((errors)=>{
                HidePreloader()
                console.log(errors);
            });
           
        }else{
            HidePreloader()
            this.validator.showMessages();
        } 
    }
    isValidFileUploaded=(file)=>{
        const validExtensions = ['png','jpeg','jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }
    render() {
        return (
            (this.state.isLoaded)
            ?
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt=""/></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('Details')}</h1>
                        </div>
                    </div>
                </div>
            </header>

            {/* for doctor user detail */}
            {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
            ? 
            (
            <section className="heath_detail">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="media" >
                                <div className="media-body provider_detail_sec">
                                    <h5>{this.state.provider_info.full_name} <span>{this.state.provider_info.service_category_title}</span> </h5>
                                    <p>{GetLocalizationString('general_fees')} ₹ {this.state.provider_info.advance_fees ?? 0} </p>
                                    <p>{GetLocalizationString('discounted_fees')} ₹  {this.state.provider_info.discount_fees ?? 0} <div className="tooltip_new"><img src={Infoicon} alt="" className='info_icon'/><span className="tooltiptext">{this.state.settings.spacial_fees_instruction ?? ''}</span></div></p> 
                                    
                                   
                                    <div className="proive_info">
                                        <ul>
                                        {(this.state.provider_info.night_consultation) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                            
                                        {(this.state.provider_info.video_consultation) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                                        </ul>
                                    </div>
                                    <div className="contact_details">
                                        <ul>
                                            <li><a href={'tel:'+this.state.provider_info.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.state.provider_info.mobile}</a></li>

                                            {
                                            this.state.provider_info.whatsapp_number != ''
                                            ?
                                            <li><a href={process.env.REACT_APP_WHATSAPP_LINK+this.state.provider_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.state.provider_info.whatsapp_number}</a></li>
                                            :
                                            ''
                                            }
                                        </ul>
                                    </div>
                                    
                                </div>
                                <img className="d-flex" src={this.state.provider_info.profile_image_url != '' ? this.state.provider_info.profile_image_url : default_user_img} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            )
            :
            (
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heath_detail box-multiple box_sd_none mt-0 p-0">
                                <div className="media" >
                                    <div className="media-body">
                                        <h5>{this.state.provider_info.full_name} <span>{this.state.provider_info.service_category_title}</span></h5>

                                        {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID && this.state.provider_info.unique_id != '' && this.state.provider_info.unique_id != null) ? (<p>({this.state.provider_info.unique_id})</p>) : ''}
                                        
                                        {(this.state.provider_info.level1_service_category_id != process.env.REACT_APP_DOCTOR_CATEGORY_ID )                                    
                                        ?
                                        (
                                            <div className="star_view">
                                                < Rating size="15" initialValue={this.state.provider_info.average_rating} readonly="true" />
                                                {
                                                    this.state.provider_info.average_rating > 0
                                                    ?
                                                    <span className="star_number"> {this.state.provider_info.average_rating}</span>
                                                    : 
                                                    <span className="star_number"> 0.0</span>
                                                }   
                                            </div>
                                        )
                                        :
                                        ''
                                        }
                                        <div className="contact_details">
                                            <ul>
                                                
                                                <li><a href={'tel:'+this.state.provider_info.mobile}><img src={call} alt=""  className="icon_syml_call "/> {this.state.provider_info.mobile}</a></li>
                                                {
                                                this.state.provider_info.whatsapp_number != ''
                                                ?
                                                <li><a href={process.env.REACT_APP_WHATSAPP_LINK+this.state.provider_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.state.provider_info.whatsapp_number}</a></li>
                                                :
                                                ''
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <img className="d-flex" src={this.state.provider_info.profile_image_url != '' ? this.state.provider_info.profile_image_url : default_user_img} alt=""/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            )
            }
            
            <section className="heath_detail_abt">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {
                                this.state.provider_info.profile_description != '' && this.state.provider_info.profile_description != null
                                ?
                                <>
                                <h4>{GetLocalizationString('description')}</h4>
                                <p>{this.state.provider_info.profile_description}</p>
                                </>
                                :
                                ''
                            }
                            
                            {
                            this.state.provider_info.address != ''
                            ?
                            <>
                            <h4 className="m-0">{GetLocalizationString('Address')}
                            <span><Link className="view_all_btn" onClick={this.OpenInMap}>{GetLocalizationString('get_direction')}</Link></span>
                            </h4>
                            <span className="pb-4">{this.state.provider_info.address}</span>
                            <div className="map_details" style={mapStyles}>
                            
                            {
                            (this.state.latitude > 0 && this.state.longitude > 0)
                            ?
                            /*<MapContainer 
                            MapStyle={mapStyles} 
                            center={{ lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT), lng: parseFloat(process.env.REACT_APP_DEFAULT_LONG) }} 
                            zoom={8} 
                            centerAroundCurrentLocation={true}
                            data={[
                                {
                                    lat: parseFloat(this.state.latitude),
                                    lng: parseFloat(this.state.longitude)
                                },
                                {
                                    lat: parseFloat(this.state.provider_info.latitude),
                                    lng: parseFloat(this.state.provider_info.longitude)
                                },
                                
                            ]} 
                            />*/
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
                                <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={10}
                                center={{
                                    lat: parseFloat(this.state.provider_info.latitude),
                                    lng: parseFloat(this.state.provider_info.longitude)
                                }}>
                                    <Marker
                                    options={
                                        { 
                                        icon: { 
                                            url: DoctorPng
                                            } 
                                        }
                                    }
                                    position={{
                                        lat: parseFloat(this.state.provider_info.latitude),
                                        lng: parseFloat(this.state.provider_info.longitude)
                                    }}/>  
                                </GoogleMap>     
                            </LoadScript>
                            :
                            ''
                            }

                            </div>
                            
                            </>
                            :
                            ''
                            }

                            {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                            ? 
                            (
                            <div>
                                <h4>{GetLocalizationString('date')}</h4>
                                <div className="slot_scroll">
                                    <ul>
                                    {
                                        (this.state.provider_info.SlotDates.length > 0)
                                        ?
                                        this.state.provider_info.SlotDates.map((slot, i) => {
                                            return (
                                                
                                                (slot.is_empty) 
                                                ?
                                                (<li key={slot.day} className={(slot.is_active)? 'slot-date-box active' :'slot-date-box not_fill'}><a>{slot.day}</a></li>)
                                                :
                                                (<li key={slot.day} className={(slot.is_active)? 'slot-date-box active' :'slot-date-box'} onClick={event => this.UpdateSlotTime(event, i)}><a>{slot.day}</a></li>)  
                                            )
                                        })
                                        :
                                        <center>{GetLocalizationString('no_appointment')}</center>
                                    }   
                                    

                                    </ul>
                                </div>
                                <h4>{GetLocalizationString('time')}</h4>
                                <div className="slot_scroll">
                                    <ul>
                                        {
                                            (this.state.slot_times.length > 0)
                                            ?
                                            this.state.slot_times.map((slotTime, i) => {
                                                    
                                                if(slotTime.is_available) {
                                                    return <li key={slotTime.time} className={(slotTime.is_available)? 'slot-time-box' :'slot-time-box not_fill'} onClick={event => this.ChooseSlotTime(event, i)}><a>{slotTime.time}</a></li>
                                                }else{
                                                   return <li key={slotTime.time} className={(slotTime.is_available)? 'slot-time-box' :'slot-time-box not_fill'} ><a>{slotTime.time}</a></li>
                                                }
                                                   
                                            })
                                            :
                                            <center>{GetLocalizationString('no_slot')}</center>
                                        }   

                                    </ul>
                                </div>
                                <div className="pay_description">
                                    
                                    {/* if provider take advance payment */}
                                    {
                                    (this.state.provider_info.payment_method == 1)
                                    ?
                                    (
                                        <>
                                            <h4 className='m-0'>{GetLocalizationString('payment_option_txt')}</h4>
                                            <p className='pay_info_text'>{this.state.settings.advance_pay_instruction ?? ''}</p>
                                            <div className="row">
                                                <div className="col-12 ">
                                                    
                                                    {
                                                        this.state.payment_instructions.map((payment, i) => {
                                                        var default_img = (i == 0)? Paytm :(i == 1)? PhonePe :(i == 2)? GooglePay : Bhimupi
                                                        var default_txt = (i == 0)? GetLocalizationString('paytm') :(i == 1)? GetLocalizationString('phonepe') :(i == 2)? GetLocalizationString('googlepay') : GetLocalizationString('bhimupi')
                                                            if(payment.account_number != ''){
                                                                return (<div className="pay_type" key={i}>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="media">
                                                                                <img className="d-flex" src={default_img} alt=""/>
                                                                                <div className="media-body">
                                                                                <h5 className="m-0 pr-0 Pay_mode">{default_txt}<span className="float-right pay_mobile">{payment.account_number}</span></h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            }else{    
                                                                return ''
                                                            } 
                                                        })
                                                    }       
                                                     
                                                </div>
                                            </div>
                                        </>
                                    )
                                    :
                                    ''
                                    }
                                    {
                                    (this.state.provider_info.number_of_within_days > 0)
                                    ?
                                    <div className="fee_charge mt-3">                
                                        <div className="form-group">
                                            <label htmlFor="">{GetLocalizationString('second_visit_text')}</label>
                                            <input type="text" className="form-control" id="" placeholder={this.state.provider_info.number_of_within_days} readOnly={true}/>
                                        </div>
                                    </div>
                                    :
                                    ''
                                    }
                                    
                                    {
                                        (this.state.provider_info.payment_method == 1) 
                                        ?
                                        <>
                                        <div className="fee_charge mt-3">
                                            <div className="form-group ">
                                                <label htmlFor="" className="d-block mb-3">{GetLocalizationString('attachment_proof')}</label>
                                                <img src={this.state.file} alt="" onClick={this.ClickFileUpload} style={UploadScreenShot}  />
                                                
                                                {(this.state.is_uploaded) ? (<b className='close_btn_cust' onClick={this.RemoveProfileImage}><i className='fa fa-close'></i></b>) : ''}
        
                                                <input type='file' id='payment_screenshot' style={FileUploadStyle}  onChange={this.SingleFileUpload}/>
                                            </div>
                                            
                                        </div>
                                        </>
                                        :
                                        ''
                                    }
                                    
                                    <button className="btn-theme mt-5 mb-3" type="submit" onClick={this.BookAppointment}> {GetLocalizationString('submit')} <img src={ArrowRight} alt=""/> </button>

                                </div>
                            </div>
                            )
                            :
                            ('')
                            }
                            {
                                (this.state.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                                ? 
                                (
                                <div>

                                </div>
                                )
                                :
                                (
                                <div>
                                    <h4>{GetLocalizationString('review')} 
                                        <span>
                                        <Link
                                        to={() =>{
                                            return {
                                                pathname: '/send-provider-review/'+this.state.provider_info.slug,
                                                provider_info: this.state.provider_info
                                            }
                                        }}
                                        className="write_btn"
                                        >{GetLocalizationString('write_a_review')}</Link>
                                        </span>
                                    </h4>
                                    
                                    <div className="col-12 p-0">
                                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="2000">
                                            <div className="carousel-inner" role="listbox" onClick={this.RedirectToProviderReviews}>
                                            
                                            {
                                                (this.state.provider_reviews.length <= 0 && this.state.isLoaded)
                                                ?
                                                (
                                                    ''
                                                )
                                                :
                                                this.state.provider_reviews.map(function(review, i){
                                                    return <div key={i} className={(i == 0) ? "carousel-item active" : "carousel-item"}>
                                                    <div className="slider_review">
                                                        <h5>{(review.get_user_detail) ? review.get_user_detail.first_name : 'Anonymous User'}</h5>
                                                        <p>
                                                        {
                                                        review.review_description ?
                                                        (review.review_description.length > 200) ? 
                                                        review.review_description.substring(0, 200) + '...read more': review.review_description : 
                                                        ''}
                                                        </p>
                                                        <div className="star_view">
                                                            < Rating size="15" initialValue={review.review_rating} readonly="true" />
                                                            <span className="star_number">{review.review_rating}</span> 
                                                        </div>
                                                    </div>
                                                    </div>
                                                })
                                            }
                                            

                                            </div>

                                            <ul className="carousel-indicators">
                                                {
                                                    (this.state.provider_reviews.length > 0)
                                                    ?
                                                    this.state.provider_reviews.map(function(review, i){
                                                        return <li key={i} data-target={"#carouselExampleIndicators"} data-slide-to={i} className={(i == 0) ? 'active' :''}></li>
                                                    })
                                                    :
                                                    ''
                                                }
                                                
                                                
                                            </ul>

                                        </div>   
                                    </div>

                                </div>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </section>
            </>
            :
            ''
          )
    }
}

const mapStyles = {        
    height: "150px",
    width: "100%",
    margin:0
}
const FileUploadStyle ={
    display:'none'
}
const UploadScreenShot = {
    height: '60px',
    width: '60px'
}
export default ProviderDetail