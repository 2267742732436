////////////////////////////////////////////////////////////
//     							                          //
//  Program: Home.jsx                                     //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Anil kumar Gupta  						  //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import PersonIcon from "./../assets/images/person.png"
import notificationicon from "./../assets/images/notification.png"
import HomeService from "../services/HomeService";
import { ShowNotifications,isObjEmpty, ShowPreloader, HidePreloader, getLoggedInUserId, getLoggedInUser, GetLocalizationString, SetDefaultLanguage } from './../Helpers/CustomHelper';
import Category from "./common/Category";
import { Link } from "react-router-dom";
import default_user_img from "./../assets/images/default_user_img.png"
import AuthenticationService from "../services/AuthenticationService";
import { isEmptyObject } from "jquery";
export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
        error: null,
        isLoaded: false,
        is_gps_on: 0,
        input:{},
        service_categories: [],
        information_categories: [],
        upcomming_booking: {},
        grievance_categories: [],
        current_address: '',
        latitude: 0,
        longitude: 0,
        settings:{},
        currentLocation: { lat: 0, lng: 0 },
        user_data: {},
        grievance_category: {},
        badge_count: 0,
        default_language: !isEmptyObject(getLoggedInUser()) ? getLoggedInUser().default_language : localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
        selected_language: localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE,

    };
    this.RedirectToProfile = this.RedirectToProfile.bind(this)
    this.RedirectToNotification = this.RedirectToNotification.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.getUserProfile = this.getUserProfile.bind(this)
  }

  RedirectToProfile = () => {
    this.props.history.push({
        pathname: '/my-profile',
    });
  }

  RedirectToNotification = () => {
    this.props.history.push({
        pathname: '/my-notifications',
    });
  }
  changeHandler = (event) => {
    ShowPreloader()
    let input = this.state.input;
    let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position)=> {
            this.setState({
                is_gps_on: (value == true) ? 1 : 0,
                currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude },
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
            
        });
    }
    setTimeout(() => {
        var formData = {
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            status: (value == true) ? 1 : 0
        }
        HomeService.UpdateGPSLocation(formData)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                var message =(value == true) ? GetLocalizationString('gps_turned_on'): GetLocalizationString('gps_turned_off');
                ShowNotifications('success', message, 1500).then(()=>{
                    window.location.reload()
                })
            }else{
                HidePreloader()
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            console.log(errors);
        });
    }, 500);
    
}
  componentDidMount() {
    SetDefaultLanguage(this.state.selected_language)
    ShowPreloader()
    this.getUserProfile()
    localStorage.setItem('RequestFor','View')

    AuthenticationService.getSetting({})
    .then((res) =>{
      if((global.successStatus).includes(res.status)) {
            this.setState({
                settings: res.data
            })
      }else{
          HidePreloader()
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
    }).catch((errors)=>{
        HidePreloader()
        this.setState({
            isLoaded:true
        })
        console.log(errors);
    });

    HomeService.GetAllCategoriesList({user_id : getLoggedInUserId()})
    .then((res) =>{
      console.log(res)
        
      if((global.successStatus).includes(res.status)) {
        HidePreloader()
        let ServiceCategoriesList = res.data.service_categories.data;
        let InformationCategoriesList = res.data.information_categories.data;
        let GrievanceCategoriesList = res.data.grievance_categories_data.grievance_categories;
        let upcomming_booking = res.data.upcomming_appointment;
        if(ServiceCategoriesList.length > 0){
            ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                return <Category key={i} actionFor="sub-categories-list" category_info={category} nextPage="/select-sub-category" category_type='Service' />
            })
            InformationCategoriesList = InformationCategoriesList.map(function(category, i){
                return <Category key={i} actionFor="sub-categories-list" category_info={category} nextPage="/select-sub-category" category_type='Service' />
            })
            this.setState({
                service_categories:ServiceCategoriesList,
                information_categories: InformationCategoriesList,
                grievance_categories: GrievanceCategoriesList,
                grievance_category: res.data.grievance_categories_data,
                upcomming_booking,
                isLoaded:true
            })
        }
        
      }else if(res.status === 401){
            HidePreloader()
            console.log(res);
            this.setState({
                isLoaded:true
            })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }else if(res.status === 400){
          HidePreloader()
          console.log(res);
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
      else{
          HidePreloader()
          console.log(res);
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
    }).catch((errors)=>{
        HidePreloader()
        this.setState({
            isLoaded:true
        })
        console.log(errors);
    });
  }

  getUserProfile(){
    AuthenticationService.getProfile()
    .then((res)=> {
        if((global.successStatus).includes(res.status)) {
            SetDefaultLanguage(res.data.selected_language)
            if(res.data.is_profile_completed != 1 && res.data.role_id == process.env.REACT_APP_PROVIDER_ROLE_ID){
                this.props.history.push({
                    pathname: '/select-category'
                });
            }
            this.setState({
                user_data: res.data,
                default_language: res.data.default_language,
                badge_count:res.data.badge_count,
                is_gps_on: !isObjEmpty(res.data.gps_location) ? res.data.gps_location.status_id : 0
            })
            localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
        }else{
            console.log(res) 
        }
    }).catch((errors)=>{
        console.error(errors)
    })
  }

  SetMyLanguage = (e) => {
    let default_lang = process.env.REACT_APP_DEFAULT_LANGUAGE
    if(e ==  'hi'){
        default_lang = 'hi'
        this.setState({
            selected_language: 'hi'
        });
    }else{
        default_lang = 'en'
        this.setState({
            selected_language: 'en'
        });
    }
    SetDefaultLanguage(default_lang)
    AuthenticationService.getSetting({})
    .then((res) =>{
      if((global.successStatus).includes(res.status)) {
            this.setState({
                settings: res.data
            })
      }else{
          HidePreloader()
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
    }).catch((errors)=>{
        HidePreloader()
        this.setState({
            isLoaded:true
        })
        console.log(errors);
    });
    HomeService.GetAllCategoriesList({user_id : getLoggedInUserId()})
    .then((res) =>{  
      if((global.successStatus).includes(res.status)) {
        HidePreloader()
        let ServiceCategoriesList = res.data.service_categories.data;
        let InformationCategoriesList = res.data.information_categories.data;
        let GrievanceCategoriesList = res.data.grievance_categories_data.grievance_categories;
        let upcomming_booking = res.data.upcomming_appointment;
        if(ServiceCategoriesList.length > 0){
            ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                return <Category key={i} actionFor="sub-categories-list" category_info={category} nextPage="/select-sub-category" category_type='Service' />
            })

            InformationCategoriesList = InformationCategoriesList.map(function(category, i){
                return <Category key={i} actionFor="sub-categories-list" category_info={category} nextPage="/select-sub-category" category_type='Service' />
            })
            this.setState({
                service_categories:ServiceCategoriesList,
                grievance_categories: GrievanceCategoriesList,
                information_categories: InformationCategoriesList,
                grievance_category: res.data.grievance_categories_data,
                upcomming_booking,
                isLoaded:true
            })
        }
        
      }else if(res.status === 401){
            HidePreloader()
            console.log(res);
            this.setState({
                isLoaded:true
            })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }else if(res.status === 400){
          HidePreloader()
          console.log(res);
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
      else{
          HidePreloader()
          console.log(res);
          this.setState({
            isLoaded:true
          })
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
      }
    }).catch((errors)=>{
        HidePreloader()
        this.setState({
            isLoaded:true
        })
        console.log(errors);
    });
  }

  render() {
    
    return (
    (this.state.isLoaded)
    ?
      <>
        <header>
            <div className="container">
                <div className="row">
                        <div className="col-4">
                            <div className="language_btn">
                                <ul>
                                    <li onClick={() => {this.SetMyLanguage('hi')}} className={this.state.selected_language == 'hi' ? "active" : ""}><a href="#" className="hind">हिंदी</a></li>
                                    <li onClick={() => {this.SetMyLanguage('en')}} className={this.state.selected_language == 'en' ? "active" : ""}><a href="#">ENG</a></li>
                                </ul>
                            </div>
                        </div>
                    <div className="col-4">
                        <h1 className="head_heading">{GetLocalizationString('home')}</h1>   
                    </div>
                   
                    <div className="col-2 text-right  pr-0">
                        <a  onClick={this.RedirectToProfile}><img src={PersonIcon} alt=""/></a>
                    </div>
                    <div className="col-2 text-right">
                        <a  onClick={this.RedirectToNotification}><img src={notificationicon} alt="" className="bell_icon"/>
                        {(this.state.badge_count > 0)
                        ?
                        <span className="bell_count">{this.state.badge_count}</span>
                        :
                        ''
                        }
                        
                        </a>
                    </div>
                </div>
            </div>
        </header>
        {(!isObjEmpty(this.state.upcomming_booking) && this.state.upcomming_booking) ? (
             <section className="">
                <div className="container ">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="home_heading">{GetLocalizationString('upcoming_booking')}

                                <span>
                                <Link 
                                className="view_all_btn" 
                                to={() => {
                                    return {
                                        pathname: '/my-bookings/' + this.state.user_data.slug,
                                    }
                                }}>{GetLocalizationString('view_all')}</Link>
                                </span>

                            </h2>
                        
                          
                        </div>
                        <div className="col-12 ">
                            <div className="up_booking">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to={() => {
                                            return {
                                                pathname: '/my-bookings/' + this.state.user_data.slug,
                                            }
                                        }}>
                                            <div className="media">
                                                <div className="media-body">
                                                    <h5>{this.state.upcomming_booking.get_provider_detail.full_name} <span>{this.state.upcomming_booking.get_provider_detail.child_service_category.service_category_title}</span> </h5>
                                                    <ul>
                                                        <li><span className="list_slot">{this.state.upcomming_booking.appointment_date}</span></li>
                                                        <li><span className="list_slot">{this.state.upcomming_booking.starts_at}</span></li>
                                                    </ul>
                                                </div>
                                                <img className="" src={this.state.upcomming_booking.get_provider_detail.profile_image_url ?? default_user_img} alt="" />
                                            </div>
                                        </Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        ) : '' } 
        {
        (this.state.isLoaded && getLoggedInUserId() > 0 && getLoggedInUser().role_id == process.env.REACT_APP_PROVIDER_ROLE_ID && getLoggedInUser().level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID)
        ?       
        <section className="gps_switch">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex-cust">
                    <label className="custom-control-label gps_text" >{GetLocalizationString('gps')}</label>
                   
                        <div className="custom-control custom-switch">
                            <input type="checkbox" name="is_gps_on" className="custom-control-input" id="customSwitch1" value={1} checked ={(this.state.is_gps_on == 1) ? 'checked': ''} onChange={this.changeHandler}/>
                            <label className="custom-control-label" htmlFor="customSwitch1"></label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        :
        ''
        }

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="home_heading">{GetLocalizationString('available_services')}</h2>
                    </div>
                    {this.state.service_categories}

                    <div className="col-12">
                        <h2 className="home_heading mt-4">{GetLocalizationString('information_services')}</h2>
                    </div>

                    {this.state.information_categories}
                    {
                    (this.state.isLoaded)
                    ?
                    <div className="box_cat">
                        <Link 
                        to={() =>{
                            return {
                              pathname: '/select-sub-category', 
                              service_categories: this.state.grievance_categories,
                              category_info: {},
                              category_type: 'Grievance'
                            }
                        }}>
                            <img src={ this.state.grievance_category.image } alt=""/>
                            <h3>{this.state.grievance_category.title}</h3>
                        </Link>
                    </div>
                    :
                    ''
                    }
                    
                </div>
            </div>
        </section>

        {/* <footer className="mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="diss_box">
                        <div className="alert alert-danger text-left">
                                <strong>{GetLocalizationString('disclaimer')}</strong>
                                {this.state.settings.home_disclaimer_text}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}
        
      </>
      :
      ''
    );
  }
}

export default Home;
