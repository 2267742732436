import React, { Component } from 'react'
import HomeService from "./../services/HomeService";
import { ShowNotifications, ShowPreloader, HidePreloader, GetLocalizationString, SetDefaultLanguage } from './../Helpers/CustomHelper'
import ArrowLeft from "./../assets/images/al.png"
import SearchImg from "./../assets/images/se.png"
import MapIcon from "./../assets/images/map_icon.png"
import Provider from './common/Provider'
import EmergencyContact from './common/EmergencyContact'
import VirtualTour from './common/VirtualTour';
import { getLoggedInUser } from '../Helpers/CustomHelper';
import { Link } from "react-router-dom";

class ProvidersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            ProvidersList: [],
            ProvidersListTemp: [],
            search:'',
            per_page: process.env.REACT_APP_PER_PAGE,
            page:1,
            providers_count: 0,
            category_detail: this.props.history.location.category_info ?? {},
            parent_category_id: this.props.history.location.category_info ? this.props.history.location.category_info.level1_service_category_id : localStorage.getItem('parent_category_id') ?? 0
        };
        this.SearchProviders = this.SearchProviders.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.GetProvidersList = this.GetProvidersList.bind(this)
    }

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage()
        if(this.props.history.location.category_info){
            localStorage.setItem('category_detail', JSON.stringify(this.state.category_detail)) 
            localStorage.setItem('parent_category_id', this.state.parent_category_id)
                    
            if(this.state.parent_category_id == process.env.REACT_APP_EMERGENCY_CATEGORY_ID){
                var params = {
                    "per_page": process.env.REACT_APP_CONTACT_PER_PAGE,
                    "page": this.state.page,
                    "slug": this.props.match.params.slug,
                    "user_id": getLoggedInUser().provider_id,
                    "is_map_view": 0
                }
                HomeService.GetEmergencyContacts(params)
                .then((res) =>{

                console.log(res);
                if((global.successStatus).includes(res.status)) {
                    HidePreloader()
                    let ProvidersList = res.data.contacts;
                    localStorage.setItem('providers_list', JSON.stringify(ProvidersList)) 
                        
                    if(ProvidersList.length > 0){
                        ProvidersList = ProvidersList.map(function(provider, i){
                            return <EmergencyContact key={i} contact_info={provider}/>
                        })
                        this.setState({
                            ProvidersList:ProvidersList,
                            isLoaded:true
                        })  
                    }else{
                        HidePreloader()
                        this.setState({
                            isLoaded:true,
                            loading: false
                        })
                    }
                    
                }else if(res.status === 401){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }else if(res.status === 400){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
                else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
                }).catch((errors)=>{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(errors);
                });
            }else if(this.state.category_detail.level2_service_category_id == process.env.REACT_APP_VIRTUALTOUR_CATEGORY_ID){
                params = {
                    "per_page": process.env.REACT_APP_CONTACT_PER_PAGE,
                    "page": this.state.page,
                    "slug": this.props.match.params.slug,
                }
                HomeService.Get3DModals(params)
                .then((res) =>{

                console.log(res);
                if((global.successStatus).includes(res.status)) {
                    HidePreloader()
                    let ProvidersList = res.data.models;
                    localStorage.setItem('providers_list', JSON.stringify(ProvidersList)) 
                        
                    if(ProvidersList.length > 0){
                        ProvidersList = ProvidersList.map(function(provider, i){
                            return <VirtualTour key={i} model_info={provider}/>
                        })
                        this.setState({
                            ProvidersList:ProvidersList,
                            isLoaded:true
                        })  
                    }else{
                        HidePreloader()
                        this.setState({
                            isLoaded:true,
                            loading: false
                        })
                    }
                    
                }else if(res.status === 401){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }else if(res.status === 400){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
                else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
                }).catch((errors)=>{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                    console.log(errors);
                });
            }else{
                /** get patient booking */
                this.GetProvidersList();
                window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */  
            }
        }else{
            var providers_list = JSON.parse(localStorage.getItem('providers_list'))
            var parent_category_id = localStorage.getItem('parent_category_id')
            var category_data = JSON.parse(localStorage.getItem('category_detail'))
            if(parent_category_id == process.env.REACT_APP_EMERGENCY_CATEGORY_ID){
                if(providers_list.length > 0){
                    providers_list = providers_list.map(function(provider, i){
                        return <EmergencyContact key={i} contact_info={provider}/>
                    })
                    this.setState({
                        ProvidersList:providers_list,
                        category_detail:category_data,
                        isLoaded:true
                    })
                    HidePreloader()
                }else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        category_detail:category_data,
                        loading: false
                    })
                }
            }else if(category_data.level2_service_category_id == process.env.REACT_APP_VIRTUALTOUR_CATEGORY_ID){
                if(providers_list.length > 0){
                    providers_list = providers_list.map(function(provider, i){
                        return <VirtualTour key={i} model_info={provider}/>
                    })
                    this.setState({
                        ProvidersList:providers_list,
                        category_detail:category_data,
                        isLoaded:true
                    })
                    HidePreloader()
                }else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        category_detail:category_data,
                        loading: false
                    })
                }
            }else{
                if(providers_list.length > 0){
                    providers_list = providers_list.map(function(provider, i){
                        return <Provider key={provider.provider_id} provider_info={provider}/>
                    })
                    this.setState({
                        ProvidersList:[...this.state.ProvidersList,...providers_list],
                        loading: false,
                        isLoaded:true,
                        category_detail:category_data,
                        providers_count:providers_list.length
                    })
                    HidePreloader()
                }else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        category_detail:category_data,
                        loading: false
                    })
                }
            }
        }

        
    }

    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.providers_count / this.state.per_page)
        let totalPages =  (this.state.providers_count % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true
                })
                this.GetProvidersList()
            }
        }
    };

    SearchProviders = (event) =>{
        //if (event.key === 'Enter') {
            ShowPreloader()
            this.setState({
                ProvidersList:[],
                isLoaded:false,
                loading: true,
                page:1,
                providers_count:0
            })
            setTimeout(() => {
                if(this.state.parent_category_id != process.env.REACT_APP_EMERGENCY_CATEGORY_ID){
                    this.GetSearchProvidersList()
                }
            }, 0);
        //}
        
    }

    changeHandler = (event) =>{
        this.setState({[event.target.name]:event.target.value});
    }

    GetProvidersList(){
        var params = { 
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
            "type": (this.state.parent_category_id == process.env.REACT_APP_TRANSPOTATION_ID) ? 'Transpotation' : (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'Doctor' : (this.state.parent_category_id == process.env.REACT_APP_HOUSEHOLD_ID) ? 'Household' : '',
            "search": this.state.search,
            "latitude": "28.609831",
            "longitude": "77.367500",
            "sort_order": (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'ASC' : 'ASC',
            "sort_column": (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'last_name' : "first_name",
            "user_id": getLoggedInUser().user_id ?? 0,
            "is_map_view": 0
        }
        
        HomeService.GetProvidersList(params)
        .then((res) =>{
            console.log(res);
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                let ProvidersList = res.data.providers;
                if(ProvidersList.length > 0){
                    localStorage.setItem('providers_list', JSON.stringify([...this.state.ProvidersListTemp,...ProvidersList]))
                    this.setState({
                        ProvidersListTemp: [...this.state.ProvidersListTemp,...ProvidersList]
                    }) 
                    ProvidersList = ProvidersList.map(function(provider, i){
                        return <Provider key={provider.provider_id} provider_info={provider}/>
                    })
                    this.setState({
                        ProvidersList:[...this.state.ProvidersList,...ProvidersList],
                        loading: false,
                        isLoaded:true,
                        providers_count:res.data.providers_count
                    })
                    
                }else{
                    localStorage.setItem('providers_list', JSON.stringify(ProvidersList))
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                }
                
            }else if(res.status === 401){
                this.setState({
                    isLoaded:true
                })
                console.log(res);
                HidePreloader()
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                console.log(res);
                HidePreloader()
                this.setState({
                    isLoaded:true,
                    loading: false
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                console.log(res);
                HidePreloader()
                this.setState({
                    isLoaded:true,
                    loading: false
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
                loading: false
            })
            console.log(errors);
        });
    }

    GetSearchProvidersList(){
        var params = { 
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
            "type": (this.state.parent_category_id == process.env.REACT_APP_TRANSPOTATION_ID) ? 'Transpotation' : (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'Doctor' : (this.state.parent_category_id == process.env.REACT_APP_HOUSEHOLD_ID) ? 'Household' : '',
            "search": this.state.search,
            "latitude": "28.609831",
            "longitude": "77.367500",
            "sort_order": (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'ASC' : 'ASC',
            "sort_column": (this.state.parent_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.parent_category_id == process.env.REACT_APP_HEALTHCARE_CATEGORY_ID) ? 'last_name' : "first_name",
            "user_id": getLoggedInUser().user_id ?? 0,
            "is_map_view": 0
        }
        
        HomeService.GetProvidersList(params)
        .then((res) =>{
            console.log(res);
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                let ProvidersList = res.data.providers;
                if(ProvidersList.length > 0){ 
                    ProvidersList = ProvidersList.map(function(provider, i){
                        return <Provider key={provider.provider_id} provider_info={provider}/>
                    })
                    this.setState({
                        ProvidersList:ProvidersList,
                        loading: false,
                        isLoaded:true,
                        providers_count:res.data.providers_count
                    })
                    
                }else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false
                    })
                }
                
            }else if(res.status === 401){
                this.setState({
                    isLoaded:true
                })
                console.log(res);
                HidePreloader()
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                console.log(res);
                HidePreloader()
                this.setState({
                    isLoaded:true,
                    loading: false
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                console.log(res);
                HidePreloader()
                this.setState({
                    isLoaded:true,
                    loading: false
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
                loading: false
            })
            console.log(errors);
        });
    }
    
    render() {
        return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{this.state.category_detail.service_category_title}</h1>
                        </div>
                        <div className="col-2 text-right">
                            {
                            (this.state.parent_category_id != process.env.REACT_APP_EMERGENCY_CATEGORY_ID && this.state.category_detail.level2_service_category_id != process.env.REACT_APP_VIRTUALTOUR_CATEGORY_ID && this.state.ProvidersList.length > 0)
                            ?(
                                <Link
                                    to={() =>{
                                        return {
                                            pathname: '/get-providers-map-view/'+this.props.match.params.slug,
                                            category_info: this.props.history.location.category_info ?? {},
                                            parent_category_id: this.props.history.location.category_info ? this.props.history.location.category_info.level1_service_category_id : 0
                                        }
                                    }}>
                                    <img src={MapIcon} alt=""/>
                                </Link>
                            )
                            :
                            ''
                            
                            }
                            
                        </div>
                    </div>
                </div>
            </header>
            {
            (this.state.parent_category_id == process.env.REACT_APP_EMERGENCY_CATEGORY_ID) ? (
                <>
                {/* {
                   (this.state.category_detail.disclaimer_text != `""` && this.state.category_detail.disclaimer_text != undefined)
                   ?
                   <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="diss_box">
                                        <div className="alert alert-danger text-left m-0">
                                            <strong>{GetLocalizationString('disclaimer')}</strong> {this.state.category_detail.disclaimer_text.replaceAll('"',"")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                   :
                   '' 
                } */}
                
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Provider List start from here */}
                                {
                                (this.state.ProvidersList.length <= 0 && this.state.isLoaded == true)
                                ?
                                (
                                    <div className="success_box no_cnt_data">
                                        <h5 className="app_done">{GetLocalizationString('no_contact')}</h5>
                                    </div>
                                )
                                :
                                this.state.ProvidersList
                                }                                
                            </div>
                        </div>
                    </div>
                </section>
                </>
            ) :
            (
                <section className="payment_mode">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12 mb-5">
                                {
                                (this.state.parent_category_id != process.env.REACT_APP_EMERGENCY_CATEGORY_ID && this.state.category_detail.level2_service_category_id != process.env.REACT_APP_VIRTUALTOUR_CATEGORY_ID)
                                ?
                                (<div className="input-group">
                                    <div className="input-group-append">
                                    <button className="btn btn-under_input" type="button">
                                        <img src={SearchImg} alt="" className="img-fluid" />
                                    </button>
                                    </div>
                                    <input type="text" className="form-control new_bdr" placeholder={GetLocalizationString('search')} aria-label="" aria-describedby="basic-addon1" name='search' value={this.state.search} onChange={this.changeHandler} onKeyDown={this.SearchProviders}/>
                                </div>)
                                :
                                ''
                                }
                                {/* Provider List start from here */}
                                
                                {
                                (this.state.ProvidersList.length <= 0 && this.state.isLoaded == true)
                                ?
                                
                                    
                                (this.state.category_detail.level2_service_category_id == process.env.REACT_APP_VIRTUALTOUR_CATEGORY_ID)
                                ?
                                <div className="success_box no_cnt_data">
                                    <h5 className="app_done">{GetLocalizationString('no_tour')}</h5>
                                </div>
                                :
                                <div className="success_box no_cnt_data">
                                    <h5 className="app_done">{GetLocalizationString('no_provider')}</h5>
                                </div>
                                    
                               
                                :
                                this.state.ProvidersList
                                }                          
                            </div>
                        </div>
                    </div>
                </section>
            )
            }

            {
            (this.state.loading == true)
            ?
            (
                <div style={loadingCSS}>
                    <center><b>Loading...</b></center>
                </div>
            )
            :
            ''
            }
            
        </>
        )
    }
}
const loadingCSS = { 
    height: "50px", 
    margin: "30px" 
};
export default ProvidersList