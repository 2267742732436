////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import default_user_img from "./../assets/images/default_user_img.png"
import whatsaap from "./../assets/images/social/whatsapp_new.png"
import call from "./../assets/images/social/call_new.png"
import HomeService from '../services/HomeService'
import { GetLocalizationString, getLoggedInUser, HidePreloader, SetDefaultLanguage, ShowNotifications } from './../Helpers/CustomHelper'
import { Link } from 'react-router-dom'

export class BookingDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            booking_info: {},
            appointment_time:'',
            appointment_date:'',
            isLoading:true
            
        }
        this.CancelAppointment = this.CancelAppointment.bind(this)
    }

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        let routeState
        if(this.props.location.booking_info){
            localStorage.setItem('routeState', JSON.stringify(this.props.location.booking_info))
            routeState = this.props.location.booking_info
            let booking_info = routeState;
            setTimeout(() => {
                var time_array = booking_info.starts_at.split('|')
                this.setState({
                    appointment_date: time_array[0],
                    appointment_time:time_array[1],
                    booking_info:booking_info,
                    isLoading:false
                })
            }, 100);
                  
        } else {
            routeState = localStorage.getItem('routeState')
            if(routeState) routeState = JSON.parse(routeState)
            let booking_info = routeState;
            setTimeout(() => {
                var time_array = booking_info.starts_at.split('|')
                this.setState({
                    appointment_date: time_array[0],
                    appointment_time:time_array[1],
                    booking_info:booking_info,
                    isLoading:false
                })
            }, 100);
            
        } 
    }

    CancelAppointment(elmt){
        this.setState({isSubmit: true});
        var input = {
            "appointment_id":this.state.booking_info.appointment_id,
            "status" : elmt
        }
        HomeService.CancelAppointment(input)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                var message ='';
                switch(elmt){
                    case 2:
                        message = GetLocalizationString('appointment_accepted');
                    break;
                    case 3:
                        message = GetLocalizationString('appointment_declined');
                    break;
                    case 4:
                        message = GetLocalizationString('appointment_canclled');  

                }
                ShowNotifications('success', message, 1500).then(()=>{
                    this.props.history.goBack()
                });
            }else if(res.status === 401){
                console.log(res);
                ShowNotifications('error', GetLocalizationString('appointment_could_not_cancle'),1500)
            }else if(res.status === 400){
                console.log(res);
                ShowNotifications('error', GetLocalizationString('appointment_could_not_cancle'),1500)
            }
            else{
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            console.log(errors);
        });
        
    }
    render() {
        return (
            (this.state.isLoading == false)
            ?
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('booking_detail')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            
            <section className='heath_detail'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="user_book_info user_detail box_sd_none mt-0 p-0" >
                                <div className="media" >
                                    <div className="media-body">
                                    <h5 className="p-0">{this.state.booking_info.full_name}  <span className='service_title'>{this.state.booking_info.service_category_title}</span></h5>
                                    
                                    {
                                    (this.state.booking_info.level1_service_category_id > 0 && this.state.booking_info.level2_service_category_id > 0 )
                                    ?
                                    <>
                                    <p>{GetLocalizationString('general_fees')} ₹ {this.state.booking_info.advance_fees ?? 0} <span>| {GetLocalizationString('discounted_fees')} ₹  {this.state.booking_info.discount_fees ?? 0}</span> </p>
                                    <div className="proive_info">
                                        <ul>
                                        {(this.state.booking_info.night_consultation) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                            
                                        {(this.state.booking_info.video_consultation) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                                        </ul>
                                    </div>
                                    </>
                                    :
                                    ''
                                    }
                                    
                                    <div className="contact_details">
                                        <ul>
                                            <li><a href={'tel:'+this.state.booking_info.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.state.booking_info.mobile}</a></li>
                                            
                                            {
                                            this.state.booking_info.whatsapp_number != '' && this.state.booking_info.whatsapp_number != null
                                            ?
                                            <li><a href={'tel:'+this.state.booking_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.state.booking_info.whatsapp_number}</a></li>
                                            :
                                            ''
                                            }
                                        </ul>
                                    </div>
                                    </div>
                                    <img className="d-flex" src={(this.state.booking_info.profile_image) ?? default_user_img} alt=""/>

                                </div>
                                {/* {
                                (this.state.booking_info.level1_service_category_id == 0 && this.state.booking_info.level2_service_category_id == 0 )
                                ? */}
                                <p className="pd-t-5"><span className={`badge ${this.state.booking_info.badge_class}`}> <span className="dot"></span> {this.state.booking_info.appointment_status}</span>  </p>
                                {/* :
                                ''
                                } */}
                                    
                            </div>
                            <div className="book_info_date border-0 pl-0 pr-0">
                                <p>{GetLocalizationString('date')} <span>{this.state.appointment_date}</span> </p>
                                <p>{GetLocalizationString('time')}<span>{this.state.appointment_time}</span> </p>
                                {(this.state.booking_info.advance_fees > 0) 
                                ?(
                                    <p>{GetLocalizationString('payment')} <span>{(this.state.booking_info.advance_fees > 0) ? '₹ '+this.state.booking_info.advance_fees : '₹ 0.00'}</span> </p>
                                ) 
                                : 
                                ''}

                            </div>
                        </div>

                        {(this.state.booking_info.payment_screenshot) 
                        ?(
                        <div className="col-12">
                            <div className="payment_proof">
                                <h3>{GetLocalizationString('payment_proof')}</h3>
                                <img src={(this.state.booking_info.payment_screenshot) ?? ''} alt=""/>
                            </div>
                        </div>
                        )
                        :
                        ''
                        }
                    </div>
                    {
                    (this.state.booking_info.appointment_status != process.env.REACT_APP_EXPIRED_STATUS && getLoggedInUser().role_id == 3)
                    ?
                    (<div className="row proof_btn_het">
                       
                        {
                        (this.state.booking_info.appointment_status != process.env.REACT_APP_EXPIRED_STATUS)
                        ?
                        (
                        (this.state.booking_info.appointment_status != process.env.REACT_APP_DECLINE_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_CANCELLED_STATUS)
                        ?
                        <>
                        <div className="col-4 mb-2">
                            <a onClick={() => {this.CancelAppointment(4)}} className="btn btn_sec_theme w-100">{GetLocalizationString('cancel')}</a>
                        </div>
                        <div className="col-4 mb-2"></div>
                        <div className="col-4 text-right mb-2">
                            <Link className="btn btn_sec_theme w-100"
                                to={() => {
                                    return {
                                        pathname: '/reschedule-appointment/' + this.state.booking_info.provider_slug+'/'+this.state.booking_info.appointment_id,
                                        provider_info: this.state.booking_info
                                    }
                                    }}> {GetLocalizationString('reschedule')} 
                            </Link>
                        </div>
                        </> 
                        :
                        <></>
                        )
                        :
                        ''
                        }
                        
                    </div>)
                    :
                    ''
                    }

                    {
                    getLoggedInUser().role_id == 2
                    ?
                    (
                        <>
                        {
                            (getLoggedInUser().level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID && getLoggedInUser().user_id != this.state.booking_info.user_id && this.state.booking_info.appointment_status != process.env.REACT_APP_ACCEPTED_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_EXPIRED_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_DECLINE_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_CANCELLED_STATUS)
                            ?
                            (
                            <>
                            <div className="row col-12 m-0 p-0">
                            <div className="col-4 mb-2 mt-3 p-0">
                                <a onClick={() => {this.CancelAppointment(2)}} className="btn btn_sec_theme w-100">{GetLocalizationString('accept')}</a>
                            </div>   
                            <div className="col-4"></div>
                            <div className="col-4 mb-2 mt-3 p-0">
                                <a onClick={() => {this.CancelAppointment(4)}} className="btn btn_sec_theme w-100">{GetLocalizationString('cancel')}</a>
                            </div>
                            </div>
                            </>
                            )
                            :
                            (getLoggedInUser().user_id == this.state.booking_info.user_id && this.state.booking_info.appointment_status != process.env.REACT_APP_EXPIRED_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_DECLINE_STATUS && this.state.booking_info.appointment_status != process.env.REACT_APP_CANCELLED_STATUS)
                            ?(
                            <>
                            <div className="row proof_btn_het">
                                <div className="col-4 text-right mb-2">
                                    <Link className="btn btn_sec_theme w-100"
                                        to={() => {
                                            return {
                                                pathname: '/reschedule-appointment/' + this.state.booking_info.provider_slug+'/'+this.state.booking_info.appointment_id,
                                                provider_info: this.state.booking_info
                                            }
                                            }}> {GetLocalizationString('reschedule')} 
                                    </Link>
                                </div>
                                <div className="col-4 mb-2"></div>
                                <div className="col-4 mb-2">
                                    <a onClick={() => {this.CancelAppointment(4)}} className="btn btn_sec_theme w-100">{GetLocalizationString('cancel')}</a>
                                </div>
                            </div>
                            </>  
                            )
                            :
                            ''
                            
                        }
                        </>
                    )
                    :
                    ''
                    }


                </div>
            </section>

            </>
            :
            ''
          )
    }
}

export default BookingDetail