////////////////////////////////////////////////////////////
//     							                          //
//  Program: SplashScreen.jsx                             //
//  Application: Splash Screen                            //
//  Option: For Splash Screen                             //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-08-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import { HidePreloader } from '../Helpers/CustomHelper'
import splash_logo from './../assets/images/splash_logo.png'


export class SplashScreen extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        HidePreloader()
        setTimeout(() => {
            this.props.history.push({
                pathname:'/register'
            })
        }, 4000);
    }

    render() {
        return (
            <>
            <section className='splash_sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="splash_logo">
                                <img src={splash_logo} alt="" />
                            </div>
                            <div className="col-12 splash_text">
                                <h3>Roorkee Seva</h3>
                                <p>A tribute to Roorkee city on 175 glorious years of IIT Roorkee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default SplashScreen