////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import ArrowRight from "./../assets/images/ar.png"
import HomeService from "./../services/HomeService";
import { GetLocalizationString, getLoggedInUserId, HidePreloader, SetDefaultLanguage, ShowNotifications, ShowPreloader, GetValidationString } from './../Helpers/CustomHelper';
import SimpleReactValidator from 'simple-react-validator'

class SendSuggestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            provider_info:{},
            message:'',
            isSubmit: false,
            default_lang: localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE,
        };
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: GetValidationString('message_required_fields',this.state.default_lang),
            }
        });
        this.SubmitGrievanceReport = this.SubmitGrievanceReport.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
    }

    componentDidMount(){
        HidePreloader()
        SetDefaultLanguage()
        this.setState({
            isLoaded: true
        })
    }

    changeHandler = (event) => {
        this.setState({[event.target.name]:event.target.value});
        this.validator.showMessageFor(event.target.name);
    }

    SubmitGrievanceReport = (e) => {
        ShowPreloader()
        e.preventDefault()
        if(this.validator.allValid() && this.state.message != ''){ 
            this.setState({isSubmit: true})
            let user_id = getLoggedInUserId()
            const formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("description", this.state.message);
            HomeService.SendSuggestions(formData)
            .then((res) =>{
            HidePreloader()
            if((global.successStatus).includes(res.status)) {
                this.props.history.push({
                    pathname: '/suggestion-sent'
                });
            }else if(res.status === 401){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            }).catch((errors)=>{
                HidePreloader()
                console.log(errors);
            });
        }else{
            HidePreloader()
            this.validator.showMessages();
        }
    }

    

    
    render() {
        return (
            (this.state.isLoaded)
            ?
            <>

            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt=""/></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('suggestion')}</h1>
                        </div>
                    </div>
                </div>
            </header>

            {/* {
                (this.state.category_info.disclaimer_text != `""` && this.state.category_info.disclaimer_text != undefined)
                ?
                <section>
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="diss_box">
                                    <div className="alert alert-danger text-left m-0">
                                        <strong>{GetLocalizationString('disclaimer')}</strong> {this.state.category_info.disclaimer_text.replaceAll('"', "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                '' 
            } */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                        <div className="rating_sys">
                            <h5 className="pt-4">{GetLocalizationString('Message')}</h5>
                            <form>
                                <div className="form-group"> 
                                    <textarea name="message" id="" className="form-control" rows="7" onChange={this.changeHandler} placeholder={GetLocalizationString('write_here')}>{this.state.message}</textarea>
                                    {this.validator.message(
                                        "message",
                                        this.state.message,
                                        "required",
                                        { className: "valid_info" }
                                    )}
                                </div>
                                
                                <div className="Foot_btn_grievance">
                                    <a onClick={this.SubmitGrievanceReport} className="btn btn-theme mt-5 ">{GetLocalizationString('submit')}<img src={ArrowRight} alt=""/></a>    
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </section>            
            </>
            :
            ''
          )
    }
}


export default SendSuggestion