////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import default_user_img from "./../assets/images/default_user_img.png"
import ArrowRight from "./../assets/images/ar.png"
import Moment from "moment";
import { Link } from 'react-router-dom';
import whatsaap from "./../assets/images/social/whatsapp_new.png"
import call from "./../assets/images/social/call_new.png"
import { GetLocalizationString, HidePreloader, ShowPreloader, isObjEmpty, SetDefaultLanguage } from './../Helpers/CustomHelper';

export class AppointmentSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            booking_info: {}            
        }
    }

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage()
        let routeState
        if(this.props.location.booking_info){
            localStorage.setItem('routeState', JSON.stringify(this.props.location.booking_info))
            routeState = this.props.location.booking_info
            let booking_info = routeState;
            setTimeout(() => {
                HidePreloader()
                this.setState({
                    booking_info:booking_info,
                })
            }, 100);
                  
        } else {
            routeState = localStorage.getItem('routeState')
            if(routeState) routeState = JSON.parse(routeState)
            let booking_info = routeState;
            setTimeout(() => {
                HidePreloader()
                this.setState({
                    booking_info:booking_info,
                })
            }, 100);
            
        } 
    }
    render() {
        return (
            <>
            {
            (!isObjEmpty(this.state.booking_info) && this.state.booking_info)
            ?(
                <section>
                <div className="container">
                  <div className="row">
                    <div className="col-12 ">
                      <div className="success_box ">
                        <div className="col-12 text-center">
                          <p className="text-sucess final_text">{GetLocalizationString('your_appointment')}</p>
                        </div>
            
                        <div className="heath_detail box-multiple">
                          <div className="media">
                            <div className="media-body">
                              <h5>{this.state.booking_info.provider_info.full_name} <span> {this.state.booking_info.provider_info.service_category_title}</span></h5>
                              {(this.state.booking_info.provider_info.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID && this.state.booking_info.provider_info.unique_id != '') ? (<p>({this.state.booking_info.provider_info.unique_id})</p>) : ''}
    
                              {(this.state.booking_info.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) 
                              ?
                              <p>{GetLocalizationString('general_fees')} ₹ {this.state.booking_info.provider_info.advance_fees ?? 0} <span>| {GetLocalizationString('discounted_fees')} ₹  {this.state.booking_info.provider_info.discount_fees ?? 0}</span> </p>
                              :
                              ''
                              }
    
                              <div className="proive_info">
                                <ul>
                                {(this.state.booking_info.provider_info.night_consultation) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                    
                                {(this.state.booking_info.provider_info.video_consultation) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                                </ul>
                              </div>
                              <div className="contact_details">
                            <ul>
                            
                                <li><a href={'tel:'+this.state.booking_info.provider_info.mobile}><img src={call} alt="" className="icon_syml_call"/> {this.state.booking_info.provider_info.mobile}</a></li>
    
                                {
                                this.state.booking_info.provider_info.whatsapp_number != ''
                                ?
                                <li><a href={'tel:'+this.state.booking_info.provider_info.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {this.state.booking_info.provider_info.whatsapp_number}</a></li>
                                :
                                ''
                                }
                           
                            </ul>
                            </div>
                                </div>
                                <img className="" src={this.state.booking_info.provider_info.profile_image_url ?? default_user_img} alt=""/>
                            </div>
                       
                           
                            <div className="succes_time">
                                <ul>
                                    <li><span className="booked_slot">{Moment(this.state.booking_info.slot_info.starts_at).format("DD MMM")}</span></li>
                                    <li><span className="booked_slot">{Moment(this.state.booking_info.slot_info.starts_at).format("hh:mm A")}</span></li>
                                </ul>
                            </div>
                        </div>
            
                        <Link onClick={()=>{
                            this.props.history.push({
                                pathname: '/home'
                            });
                        }} className="btn btn-theme">{GetLocalizationString('go_to_home')} <img src={ArrowRight} alt=""/></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
            :
            ''
            }

            </>
          )
    }
}

export default AppointmentSuccess