////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import default_user_img from "./../assets/images/default_user_img.png"
import { Rating } from 'react-simple-star-rating'

export class ReviewDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            review_info: this.props.location.review_info ?? {},
        }
    }

    componentDidMount() {
       
                
    }
    render() {
        return (
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">Review Detail</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        {
                        (this.state.review_info)
                        ?
                        
                            (
                            <div className="user_book_info">
                                <div className="media" >
                                    <img className="d-flex" src={this.state.review_info.profile_image ?? default_user_img} alt=""/>
                                    <div className="media-body ml-2">
                                        <h5>{this.state.review_info.full_name}</h5>
                                        <p>{this.state.review_info.unique_id} </p>
                                        <div className="star_view">
                                            <Rating size="15" initialValue={this.state.review_info.review_rating} readonly="true" />
                                            <span className="star_number">{this.state.review_info.review_rating}</span>
                                            <span clas="float-right">
                                            <p>{this.state.review_info.mobile} </p> <p>{this.state.review_info.whatsapp_number} </p>
                                            <p>{this.state.review_info.review_description} </p> 
                                            
                                            </span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) 
                        
                        :
                        ''
                        }
                        </div>   
                    </div>
                </div>
            </section>
    
            </>
          )
    }
}

export default ReviewDetail