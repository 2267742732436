import LocalizedStrings from 'react-localization'
class Localization {
    
    strings = new LocalizedStrings({
        hi: {
            "sign_in" : "साइन इन करें",
            "phone_no" : "फोन नंबर",
            "dont_have_an_account" : "खाता नहीं है?",
            "sign_up" : "फ्री साइन अप करें",
            "splash_screen_text" : "आईआईटी रुड़की अपने अस्तित्व के 175 वर्ष मना रहा है",
            "enter_otp" : "ओटीपी दर्ज करें",
            "otp_send_to" : "ओटीपी भेजा गया ",
            "continue" : "आगे बढ़े",
            "resend_otp" : "ओटीपी पुनः भेजें",
            "select_language" : "भाषा चुनिये",
            "first_name" : "पहला नाम",
            "last_name" : "उपनाम",
            "are_you_service_provider" : "क्या आप किसी प्रकार की सेवा देते हैं?",
            "yes" : "हाँ",
            "no" : "नहीं",
            "already_have_an_account" : "क्या आपके पास पहले से एक खाता मौजूद है?",
            "sign_in_small" : "साइन इन करें",
            "sign_up_small" : "फ्री साइन अप करें",
            "please_select_phone_no" : "कृपया एक मान्य फोन नंबर दर्ज करें।",
            "please_select_first_name" : "कृपया पहला नाम दर्ज करें।",
            "select_category" : "श्रेणी चुनीए",
            "select_category_in_which_you_provide_services" : "उस श्रेणी का चयन करें जिसमें आप सेवाएं प्रदान करते हैं।",
            "about" : "प्रोफ़ाइल",
            "whatsapp_no" : "व्हाट्सएप नंबर",
            "setup_fees" : "सेटअप शुल्क",
            "identity_no" : "पहचान संख्या",
            "select_location" : "स्थान चुनें",
            "open_camera" : "कैमरे से खोलें",
            "open_gallery" : "गैलरी से खोलें",
            "setup_time" : "समय चुनें",
            "submit" : "सुरक्षित करें",
            "your_location" : "अपना स्थान प्राप्त करें",
            "you_have_registered_service_provider" : "आपने एक सेवा प्रदाता के रूप में पंजीकृत किया है।",
            "go_to_home" : "होम पेज पर जाये",
            "grievance" : "शिकायत",
            "description" : "विवरण",
            "description_txt" : "विवरण",
            "attach_photo" : "फोटो संलग्न करें",
            "grievance_submited" : "आपकी शिकायत संबंधित विभाग को भेज दी गई है।",
            "select_grievance_category" : "शिकायत श्रेणी का चयन करें",
            "home" : "होम",
            "upcoming_booking" : "आगामी बुकिंग",
            "gps" : "जीपीएस",
            "available_services" : "उपलब्ध सेवाएं",
            "message_field_required" : "संदेश फ़ील्ड आवश्यक है।",
            "select_services" : "सेवाओं का चयन करें",
            "Emergency" : "आपातकालीन",
            "Transport" : "यातायात",
            "Household" : "परिवार",
            "Education" : "शिक्षा",
            "Location" : "स्थान",
            "enter_your_address":"अपना स्थान दर्ज करें",
            "gps_on" : "जीपीएस चालू है",
            "gps_off" : "जीपीएस बंद है",
            "view_profile" : "प्रोफ़ाइल देखें",
            "Details" : "विवरण",
            "Address" : "पता",
            "Review" : "समीक्षा",
            "write_a_review" : "एक समीक्षा लिखे",
            "write_here" : "यहां लिखें.......",
            "search" : "खोज ........",
            "Profile" : "प्रोफ़ाइल",
            "profile_text" : "प्रोफ़ाइल",
            "my_booking" : "मेरी बुकिंग",
            "patient_booking" : "रोगी बुकिंग",
            "my_account" : "मेरा खाता",
            "review" : "समीक्षा",
            "logout" : "लॉग आउट",
            "Ratings" : "रेटिंग्स",
            "Healthcare" : "स्वास्थ्य देखभाल",
            "select_specialization" : "विशेषज्ञता का चयन करें",
            "Doctors" : "डॉक्टरों",
            "select_specialization_service" : "उस विशेषज्ञता का चयन करें जिसमें आप सेवाएं प्रदान करते हैं।",
            "Message" : "संदेश",
            "please_add_desc" : "कृपया संदेश दर्ज करें",
            "your_review_submitted" : "आपकी समीक्षा सफलतापूर्वक सबमिट कर दी गई है।",
            "Dismiss" : "नकार देना",
            "otp_sent_successfully" : "ओटीपी सफलतापूर्वक भेजा गया",
            "otp_verify_successfully" : "ओटीपी सफलतापूर्वक सत्यापित हो गया।",
            "oops_something_wrong" : "ओह! कुछ गलत हुआ, बाद में पुन: प्रयास करें।",
            "gps_turned_on" : "जी पी एस सफलतापूर्वक चालू हो गया!",
            "gps_turned_off" : "जी पी एस सफलतापूर्वक बंद हो गया!",
            "profile_update_success" : "प्रोफाइल को सफलतापूर्वक अपडेट किया गया!",
            "slot_already_exist" : "ओह! स्लॉट पहले से मौजूद है कृपया जांचें।",
            "entered_otp_invalid" : "दर्ज ओटीपी अमान्य है!",
            "verifying_mobile_number" : "फोन नंबर सत्यापित किया जा रहा है...",
            "report_sent_success" : "आपकी शिकायत सफलतापूर्वक सबमिट कर दी गई है।",
            "review_sent_success" : "आपकी समीक्षा सफलतापूर्वक सबमिट कर दी गई है।",
            "appointment_booked" : "अपॉइंटमेंट सफलतापूर्वक बुक किया गया!",
            "appointment_could_not_cancle" : "अपॉइंटमेंट रद्द नहीं हो सका!",
            "appointment_canclled" : "अपॉइंटमेंट सफलतापूर्वक रद्द कर दिया गया!",
            "appointment_accepted" : "नियुक्ति सफलतापूर्वक स्वीकार की गई!",
            "appointment_declined" : "अपॉइंटमेंट सफलतापूर्वक अस्वीकृत!",
            "mode_of_payment" : "भुगतान का प्रकार",
            "advance_fees" : "फीस",
            "discount_text" : "क्या आप आईआईटी रुड़की या केंद्र सरकार के कर्मचारी को छूट देते हैं?",
            "enter_discount" : "कृपया रियायती शुल्क दर्ज करें",
            "night_consultation" : "क्या आप रात्रि परामर्श सेवाएं प्रदान करते हैं?",
            "video_consultation" : "क्या आप वीडियो परामर्श प्रदान करते हैं?",
            "advance_fee_text" : "क्या आप चाहते हैं कि रोगी परामर्श शुल्क का अग्रिम भुगतान करे?",
            "second_visit_text" : "कितने दिनो तक दूसरा दौरा नि:शुल्क है",
            "day":"दिन",
            "from_time": "कब से",
            "to_time" : "कब तक",
            "duration_text" : "प्रत्येक नियुक्ति की अवधि",
            "sun" :"रवि",
            "mon" : "सोम",
            "tue" : "मंगल",
            "wed" : "बुध",
            "thu" : "बृहस्पति",
            "fri" : "शुक्र",
            "sat" : "शनि",
            "five_minutes": "5 मिनट",
            "ten_minutes": "10 मिनट",
            "fifteen_minutes": "15 मिनट",
            "twenty_minutes": "20 मिनट",
            'grievance_sent_text' : "आपकी शिकायत संबंधित विभाग को भेज दी गई है",
            "no_reviews_found" : "कोई रिकॉर्ड नहीं मिला।",
            "back_to_home" : "घर",
            "back" : "वापिस",          
            "no_appointment": "कोई रिकॉर्ड नहीं मिला।",
            "no_slot": "कोई स्लॉट उपलब्ध नहीं है।",
            "no_provider" : "कोई रिकॉर्ड नहीं मिला।",
            "no_tour" : "कोई रिकॉर्ड नहीं मिला।",
            "no_contact" : "कोई रिकॉर्ड नहीं मिला।",
            "book_appointment" : "बुक अपॉइंटमेंट",
            "payment_option" : "भुगतान विकल्प",
            "payment_option_txt" : "अग्रिम भुगतान करें",
            "booking_detail" : "बुकिंग विवरण",
            "payment": "भुगतान", 
            "payment_proof": "भुगतान प्रमाण",
            "date":"दिनांक",
            "time": "समय",
            "accept":"स्वीकार करे",
            "cancel": "रद्द करे",
            'cancelled' : 'रद्द',
            "reschedule": "पुनर्निर्धारित करे",
            "your_appointment" :"अपॉइंटमेंट बुक!",
            "attachment_proof" : "पेमेंट प्रूफ अटैच करें",
            "paytm":"पेटीएम",
            "googlepay" : "गूगल पे",
            "phonepe" : "फोन पे",
            "bhimupi" : "भीम यूपीआई",
            "paytm_label" : "अपना पेटीएम वॉलेट लिंक करें।",
            "googlepay_label" : "अपना गूगल पे खाता लिंक करें।",
            "phonepe_label" : "अपना फोनपे खाता लिंक करें।",
            "bhimupi_label" : "अपना भीम यूपीआई खाता लिंक करें।",
            "general_fees":" सामान्य शुल्क",
            "discounted_fees":"रियायती शुल्क",
            "video_consultation_txt" : "वीडियो परामर्श",
            "night_consultation_txt" : "रात्रि परामर्श",
            "view_booking" :"बुकिंग देखें",
            'user_not_found' : "उपयोगकर्ता नहीं मिला।",
            'slot_exist' : "स्लॉट पहले से मौजूद है कृपया जांचें।",
            'slot_empty' : "कृपया स्लॉट को ठीक से भरें।",
            'see_all':"पूरा देखें",
            'view_all':"पूरा देखें",
            'days_placeholder': "दिन...",
            'select_grievance_services': "शिकायत श्रेणी का चयन करें",
            "discounted_fees_text": "कृपया छूट शुल्क दर्ज करें",
            'information_services': 'महत्वपूर्ण सूचना',
            'delete_account': 'खाता हटाएँ',
            'confirmation_text': 'आपका अनुरोध स्वीकार कर लिया गया है। आपका खाता 7 दिनों के भीतर स्वतः ही हटा दिया जाएगा।',
            'confirmation_title': 'पुष्टिकरण',
            "select_terms_condition_and_service_provider" : "कृपया सेवा प्रदाता और नियम और शर्तों का चयन करें।",
            'listing' : "लिस्टिंग",
            'suggestion' : 'सुझाव',
            'suggestion_sent_text' : "आपका सुझाव संबंधित विभाग को भेज दिया गया है।",
            
            
            
            



            
            /** validation message localization */
            "required_fields" : "यह फ़ील्ड आवश्यक है|",
            "mobile_required" : "कृपया एक मान्य फोन नंबर दर्ज करें।",
            'discount_fees_required' : 'कृपया छूट शुल्क दर्ज करें।',
            "unique_required" : "कृपया पहचान संख्या चुनें।",
            "name_required" : "कृपया पहला नाम दर्ज करें।",
            "grievance_required_fields" : "विवरण फ़ील्ड आवश्यक है।",
            "message_required_fields" : "संदेश फ़ील्ड आवश्यक है।",
            "numeric" : "इस फ़ील्ड में केवल अंक होने चाहिए|",
            "alpha_space" : "इस फ़ील्ड में केवल अक्षर और रिक्त स्थान होने चाहिए|",
            'invalid_start_time' : 'आरंभ होने का समय समाप्ति समय से कम होना चाहिए।',
            'invalid_end_time' : 'समाप्ति समय प्रारंभ समय से बड़ा होना चाहिए।',
            'select_time': "कृपया दिनांक और समय चुनें।",            
            'only_image_allowed' : "केवल jpg, jpeg और png फॉर्मेट की अनुमति है।",
            'give_rate' : 'उफ़! कृपया रेटिंग दें।',
            'phone_no_size': 'फोन नंबर केवल 10 अंकों का होना चाहिए।',
            'only_digit' : "फोन नंबर एक नंबर होना चाहिए।",
            'notifications' : 'सूचनाएं',
            'valid_upi_number': "कृपया एक मान्य यूपीआई आईडी दर्ज करें।",
            'select_upi_number': "कृपया एक यूपीआई आईडी दर्ज करें।",
            'read_all_notification' : 'सब पढें',
            'get_direction' : 'दिशा प्राप्त करें',
            'disclaimer':"*",
            'agree_text_pre' : "मैं रुड़की सेवा के ",
            'terms_condition' : "नियम और शर्तो",
            'terms_condition_heading' : "नियम और शर्तें",
            'agree_text_post' : "से सहमत हूँ",
            "required_terms_condition" : "कृपया नियम और शर्तें स्वीकार करें।",
            'select_provider' : "कृपया सेवा प्रदाता का चयन करें।",
            
            
            
            


        },
        en:{
            "sign_in" : "Sign In",
            "phone_no" : "Phone Number",
            "dont_have_an_account" : "Don’t have an account?",
            "sign_up" : "Free Sign Up",
            "splash_screen_text" : "IIT Roorkee celebrating 175 years of its existence",
            "enter_otp" : "Enter OTP",
            "otp_send_to" : "OTP sent to ",
            "continue" : "Continue",
            "resend_otp" : "Resend OTP",
            "select_language" : "Select Language",
            "first_name" : "First Name",
            "last_name" : "Last Name",
            "are_you_service_provider" : "Are you a service provider?",
            "yes" : "Yes",
            "no" : "No",
            "already_have_an_account" : "Already have an account?",
            "sign_in_small" : "Sign in",
            "sign_up_small" : "Free sign up",
            "please_select_phone_no" : "Please enter a valid phone number.",
            "please_select_first_name" : "Please enter first name.",
            "select_category" : "Select Category",
            "select_category_in_which_you_provide_services" : "Select the category in which you provide services.",
            "about" : "About",
            "whatsapp_no" : "Whatsapp No.",
            "setup_fees" : "Setup Fees",
            "identity_no" : "Identity No.",
            "select_location" : "Select Location",
            "open_camera" : "Open From Camera",
            "open_gallery" : "Open From Gallery",
            "setup_time" : "Setup Time",
            "submit" : "Submit",
            "your_location" : "Get your location",
            "you_have_registered_service_provider" : "You have registered as a service provider.",
            "go_to_home" : "Go to Home",
            "grievance" : "Grievance",
            "description" : "About",
            "description_txt" : "Description",
            "attach_photo" : "Attach photo",
            "grievance_submited" : "Your grievance has been sent to the concerned department.",
            "write_here" : "Write here.......",
            "select_grievance_category" : "Select Grievance Category",
            "home" : "Home",
            "upcoming_booking" : "Upcoming Bookings",
            "gps" : "GPS",
            "available_services" : "Available Services",
            "message_field_required" : "The message field is required.",
            "select_services" : "Select Services",
            "Emergency" : "Emergency",
            "Transport" : "Transport",
            "Household" : "Household",
            "Education" : "Education",
            "Location" : "Location",
            "enter_your_address":"Enter your location",
            "gps_on" : "GPS is ON",
            "gps_off" : "GPS is OFF",
            "view_profile" : "View Profile",
            "Details" : "Details",
            "Address" : "Address",
            "Review" : "Review",
            "write_a_review" : "Write a Review",
            "search" : "Search ........",
            "Profile" : "Profile",
            "profile_text" : "Profile",
            "my_booking" : "My Bookings",
            "patient_booking" : "Patient Bookings",
            "my_account" : "My Account",
            "review" : "Reviews",
            "logout" : "Logout",
            "Ratings" : "Ratings",
            "Healthcare" : "Healthcare",
            "select_specialization" : "Select Specialization",
            "Doctors" : "Doctors",
            "select_specialization_service" : "Select the specialization in which you provide services.",
            "Message" : "Message",
            "please_add_desc" : "Please enter message",
            "your_review_submitted" : "Your review has been submitted successfully.",
            "Dismiss" : "Dismiss",
            "otp_sent_successfully" : "OTP sent successfully.",
            "otp_verify_successfully" : "OTP verified successfully.",
            "oops_something_wrong" : "OOps! something went wrong, try again later.",
            "gps_turned_on" : "GPS turned on successfully.",
            "gps_turned_off" : "GPS turned off successfully.",
            "profile_update_success" : "Profile updated successfully.",
            "slot_already_exist" : "OOps! slot already exists please check.",
            "entered_otp_invalid" : "Entered OTP is invalid.",
            "verifying_mobile_number" : "Verifying mobile number...",
            "report_sent_success" : "Your grievance has been submitted successfully.",
            "review_sent_success" : "Your review has been submitted successfully.",
            "appointment_booked" : "Appointment booked successfully.",
            "appointment_could_not_cancle" : "Appointment could not be cancel.",
            "appointment_canclled" : "Appointment cancelled successfully.",
            "appointment_accepted" : "Appointment accepted successfully.",
            "appointment_declined" : "Appointment declined successfully.",
            "mode_of_payment" : "Setup Fees",
            "advance_fees" : "Fees",
            "discount_text" : "Do you give discount to the IIT Roorkee or Central Government Employee?",
            "enter_discount" : "Discounted Fee",
            "night_consultation" : "Do you provide the night consultation Services?",
            "video_consultation" : "Do you provide the video consultation?",
            "advance_fee_text" : "Do you want patient to pay consultation fee in advance?",
            "second_visit_text" : "Second visit is free within",
            "day":"Day",
            "from_time": "From",
            "to_time" : "To",
            "duration_text" : "Duration of each appointment",
            "sun" :"SUN",
            "mon" : "MON",
            "tue" : "TUE",
            "wed" : "WED",
            "thu" : "THU",
            "fri" : "FRI",
            "sat" : "SAT",
            "five_minutes": "5 Minutes",
            "ten_minutes": "10 Minutes",
            "fifteen_minutes": "15 Minutes",
            "twenty_minutes": "20 Minutes",
            'grievance_sent_text' : "Your grievance has been sent to the concerned department.",
            "no_reviews_found" : "No records found.",
            "back_to_home" : "Back to Home",
            "back" : "Back",
            "no_appointment": "No records found.",
            "no_slot": "No slot available.",
            "no_provider" : "No records found.",
            "no_tour" : "No records found.",
            "no_contact" : "No records found.",
            "book_appointment" : "Book Appointment",
            "payment_option" : "Pay fees in advance",
            "payment_option_txt" : "Pay fees in advance",
            "booking_detail" : "Booking Detail",
            "payment" : "Payment",
            "payment_proof" : "Payment Proof",
            "date": "Date",
            "time" : "Time",
            "accept":"Accept",
            "cancel": "Cancel",
            "cancelled": "Cancelled",
            "reschedule": "Reschedule",
            "your_appointment": "Appointment Booked!",
            "attachment_proof" : "Attach Payment Proof",
            "paytm":"Paytm",
            "googlepay" : "Google Pay",
            "phonepe" : "Phonepe",
            "bhimupi" : "Bhim UPI",
            "paytm_label" : "Link your Paytm wallet.",
            "googlepay_label" : "Link your Google Pay account.",
            "phonepe_label" : "Link your PhonePe account.",
            "bhimupi_label" : "Link your Bhim UPI account.",
            "general_fees":"General fee",
            "discounted_fees":"Discounted fee",
            "video_consultation_txt" : "Video Consultation",
            "night_consultation_txt" : "Night Consultation",
            "view_booking" :"View Booking",
            'user_not_found' : "User could not be found.",
            'slot_exist' : "OOps! Slot already exists please check.",
            'slot_empty' : "Please fill the slot properly.",
            'see_all':"See All",
            'view_all':"View All",
            'days_placeholder': "days.....",
            'select_grievance_services':"Select Grievance Category",
            "discounted_fees_text":"Please Enter Discounted Fee",
            'information_services': 'Important Information',
            'delete_account': 'Delete account',
            'confirmation_text' : 'Your request has been accepted. Your account will automatically be deleted within 7 days.',
            'confirmation_title' : 'Confirmation',
            "select_terms_condition_and_service_provider" : "Please select service provider and terms and conditions.",
            'listing' : "Listing",
            'suggestion' : 'Suggestions',
            'suggestion_sent_text' : "Your suggestion has been sent to the concerned department.",
            
            


            /** validation message localization */
            "required_fields" : "This field is required.",
            "mobile_required" : "Please enter a valid phone number.",
            'discount_fees_required' : 'Please enter discount fees.',
            "unique_required" : "Please enter identity number.",
            "name_required" : "Please enter first name.",
            "grievance_required_fields" : "The description field is required.",
            "message_required_fields" : "The message field is required.",
            "numeric" : "This field must be contain only digits.",
            "alpha_space" : "This field must be contain letters and spaces only.",
            'invalid_start_time' : 'Start time must be less than end time.',
            'invalid_end_time' : 'End time must be greater than start time.',
            'select_time' : "Please select date & time.",
            'only_image_allowed' : "Only jpg, jpeg & png format is allowed.",
            'give_rate' : 'OOps! please give rate.',
            'phone_no_size': 'Mobile number must be 10 digits only.',
            'only_digit' : "The mobile number must be a number.",
            'notifications' : 'Notifications',
            'valid_upi_number': "Please enter a valid UPI Id. ",
            'select_upi_number': "Please enter a UPI Id. ",
            'read_all_notification' : 'Read All',
            'get_direction' : 'Get direction',
            'disclaimer':"*",
            'agree_text_pre' : "I agree to the Roorkee Seva",
            'terms_condition' : "Terms & Conditions",
            'terms_condition_heading' : "Terms & Conditions",
            'agree_text_post' : "",
            "required_terms_condition" : "Please accept terms and conditions.",
            'select_provider' : "Please select service provider.",
            


        }
    })
    
}

export default new Localization();