////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Category.jsx                                 //
//  Application: Dashboard                                //
//  Option: to view Category                              //
//  Developer: Anil kumar Gupta  						              //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

export class EmergencyContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
        error: null,
        isLoaded: false
    };
    
  }

  render() {
    return (
      <>
        <a >
            <div className="virtual_box">
                <h4>{this.props.contact_info.contact_title.replace(/['"]+/g, '')}</h4>
                <p>{this.props.contact_info.contact_number}</p>
            </div>
        </a> 
      </>
    );
  }
}

export default EmergencyContact;
