////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { ShowNotifications, getLoggedInUser, HidePreloader, ShowPreloader, SetDefaultLanguage, GetLocalizationString, GetValidationString } from '../../Helpers/CustomHelper';
import Moment from 'moment'
import ArrowLeft from "./../../assets/images/al.png"
import ArrowRight from "./../../assets/images/ar.png"
import UserPng from "./../../assets/images/default_user_img.png"
import $ from 'jquery'; 


export class UpdateProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            input: {},
            errors: {},
            isSubmit:false,
            SelectedSpecialization:0,
            SelectedSubCategory:0,
            SelectedCategory:0,
            first_name:getLoggedInUser().first_name,
            role_id:getLoggedInUser().role_id,
            last_name:getLoggedInUser().last_name,
            mobile:getLoggedInUser().mobile,
            whatsapp_number:getLoggedInUser().whatsapp_number,
            unique_id:getLoggedInUser().unique_id ?? '',
            profile_description:getLoggedInUser().profile_description,
            user_data:getLoggedInUser(),
            file: getLoggedInUser().profile_image_url ?? UserPng,
            file_input: null,
            is_uploaded: false,
            default_lang:getLoggedInUser().default_language,
            isLoaded: false,
            exist_img : '',
            level1_service_category_id : 0 

        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                valid_mobile: {  
                  message: GetValidationString('please_select_phone_no',this.state.default_lang),
                  rule: (val) => {
                    const regexExp = /^[6-9]\d{9}$/gi;
                    if(regexExp.test(val)){
                        return true 
                    }
                    return false
                  }
                },
                mobile: {  
                    required:true,
                    message: GetValidationString('mobile_required',this.state.default_lang),
                    rule: (val) => {
                        if(val.trim() != ''){
                            return true 
                        }
                        return false
                      }
                },
                name_required: {  
                    required:true,
                    message: GetValidationString('name_required',this.state.default_lang),
                    rule: (val) => {
                        if(val.trim() != ''){
                            return true 
                        }
                        return false
                      }
                },
                unique_required: {  
                    required:true,
                    message: GetValidationString('unique_required',this.state.default_lang),
                    rule: (val) => {
                        if(val.trim() != ''){
                            return true 
                        }
                        return false
                      }
                }
            },
            messages: {
                name_required: GetValidationString('name_required',this.state.default_lang),
                unique_required: GetValidationString('unique_required',this.state.default_lang),
                mobile: GetValidationString('mobile_required',this.state.default_lang),
                numeric:GetValidationString('only_digit',this.state.default_lang),
                size:GetValidationString('phone_no_size',this.state.default_lang),
                alpha_space: GetValidationString('alpha_space',this.state.default_lang),
                alpha_num_dash_space: GetValidationString('alpha_space',this.state.default_lang)
            }
        });

        this.changeHandler = this.changeHandler.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.ClickFileUpload = this.ClickFileUpload.bind(this)
        this.SingleFileUpload = this.SingleFileUpload.bind(this)
        this.RemoveProfileImage = this.RemoveProfileImage.bind()
        
    }

    SetMyLanguage = (e) => {
        let default_lang = e.target.getAttribute('data-id')
        this.setState({
          default_lang: default_lang
        });
        SetDefaultLanguage(default_lang)
    }

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage(this.state.default_lang)
        let SelectedCategory = localStorage.getItem('SelectedCategory')
        let SelectedSubCategory = localStorage.getItem('SelectedSubCategory')
        let SelectedSpecialization = localStorage.getItem('SelectedSpecialization')
        if(SelectedSubCategory == 0){
            SelectedSubCategory = SelectedSpecialization;
            SelectedSpecialization = 0
        }
        this.setState({
            SelectedSpecialization,
            SelectedSubCategory,
            SelectedCategory,
            level1_service_category_id: SelectedSubCategory
        })
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                localStorage.setItem('default-user-lang',res.data.default_language)
                SetDefaultLanguage(res.data.default_language)
                this.setState({
                    isLoaded:true,
                    user_data:res.data,
                    default_lang:res.data.default_language,
                    first_name:res.data.first_name,
                    role_id:res.data.role_id,
                    last_name:res.data.last_name,
                    mobile:res.data.mobile,
                    whatsapp_number:res.data.whatsapp_number,
                    unique_id:res.data.unique_id ?? '',
                    profile_description:res.data.profile_description,
                    file: res.data.profile_image_url ?? UserPng,
                    exist_img: res.data.profile_image_url ?? UserPng,
                })
            }
        }).catch((errors)=>{
            console.error(errors)
            window.location.href = '/login'
        })
                
    }

    updateProfile = (e) => {
        this.validator.messages = {
            name_required: GetValidationString('name_required',this.state.default_lang),
            unique_required: GetValidationString('unique_required',this.state.default_lang),
            mobile: GetValidationString('mobile_required',this.state.default_lang),
            numeric:GetValidationString('only_digit',this.state.default_lang),
            size:GetValidationString('phone_no_size',this.state.default_lang),
            alpha_space: GetValidationString('alpha_space',this.state.default_lang),
            alpha_num_dash_space: GetValidationString('alpha_space',this.state.default_lang)
        };
        e.preventDefault();
        console.log(getLoggedInUser())
        if(this.validator.allValid()){ 
            this.setState({isSubmit: true});
            var level1_service_category_id = this.state.SelectedCategory;
            var level2_service_category_id = this.state.SelectedSubCategory;
            if(this.state.SelectedSpecialization > 0){
                level1_service_category_id = this.state.SelectedSubCategory;
                level2_service_category_id = this.state.SelectedSpecialization;
            }
            const formData = new FormData();
            formData.append("whatsapp_number", this.state.whatsapp_number);
            formData.append("default_language", this.state.default_lang);
            formData.append("first_name", this.state.first_name);
            formData.append("last_name", this.state.last_name);
            formData.append("mobile", this.state.mobile);
            formData.append("unique_id", this.state.unique_id ?? '');
            formData.append("profile_description", this.state.profile_description);
            formData.append("level1_service_category_id", level1_service_category_id);
            formData.append("level2_service_category_id", level2_service_category_id);
            formData.append("profile_image", this.state.file_input);
            formData.append("request_for", 'update_profile');

            console.log(this.state.file_input)
            console.log(formData)
            AuthenticationService.uploadProfileImage(formData)
            .then((res) =>{
              if((global.successStatus).includes(res.status)) {
                    SetDefaultLanguage(this.state.default_lang)
                    ShowNotifications('success', GetLocalizationString('profile_update_success'),1500).then(()=>{
                    this.setState({
                        user_data:res.data
                    })
                    
                    localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: (this.state.user_data.role_id == process.env.REACT_APP_PROVIDER_ROLE_ID && this.state.user_data.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) ? '/update-provider-payment/'+this.state.user_data.slug :
                            (this.state.user_data.role_id == 2) ? '/update-address-location' : '/home'
                        });
                    }, 500);     
                  });
              }else if(res.status === 401){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }else if(res.status === 400){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
              else{
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
            }).catch((errors)=>{
                console.log(errors);
            });
           
        }else{
            this.validator.showMessages();
        } 
    }

    changeHandler = (event) => {
        let input = this.state.input;
        if (event.target.type === 'date' && event.target.value !== null) {
            event.target.value = Moment(event.target.value, 'DD-MM-YYYY').toDate();
        }
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });

        this.validator.showMessageFor(event.target.name);
    }
    ClickFileUpload(){
        $('#profile_image').click()
    }

    SingleFileUpload = (event) => {
        if(event.target.files[0] !== undefined){
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
                file_input: event.target.files[0],
                is_uploaded: true
            })
        }else{
            this.setState({
                file: this.state.exist_img,
                file_input: null
            })  
        }
        
    };

    RemoveProfileImage = () => {
        this.setState({
            file: this.state.exist_img,
            file_input: null,
            is_uploaded: false
        })
    }

    render() {
        return (
            (this.state.isLoaded == true)
            ?
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt=""/></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('about')}</h1>
                        </div>
                        <div className="col-2 text-right"></div>
                    </div>
                </div>
            </header>
            <section className="signup_page pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-12 text-center">
                            <div className="avatar-upload">
                                <div className="avatar-edit">
                                {(this.state.is_uploaded) ? (<b className='btn_close_profile' onClick={this.RemoveProfileImage}><i className="fa fa-close"></i></b>) : ''}
                                    <input type='file' id="profile_image" accept=".png, .jpg, .jpeg" onChange={this.SingleFileUpload}/>
                                    <label htmlFor="profile_image"></label>
                                </div>
                                <div className="avatar-preview">
                                    <div onClick={this.ClickFileUpload} id="imagePreview" style={{backgroundImage: `url(${this.state.file})`}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 ">
                            <h3>{GetLocalizationString('select_language')}</h3>
                        </div>
                        <div className="col-4">
                            <a  data-id="hi" className={(this.state.default_lang == 'hi') ? 'default_lang btn btn-theme_lang' : 'default_lang btn btn-theme_lang btn_hindi_lang'} onClick={this.SetMyLanguage}>हिंदी </a>
                        </div>
                        <div className="col-4 pl-0">
                            <a  data-id="en" className={(this.state.default_lang == 'en') ? 'default_lang btn btn-theme_lang' : 'default_lang btn btn-theme_lang btn_hindi_lang'} onClick={this.SetMyLanguage}>ENG</a>
                        </div>
                        
                        <div className="col-12 ">
                            <form action="">
                            <div className="form-group">
                                <label htmlFor="">{GetLocalizationString('first_name')}</label>
                                <input type="text" name="first_name" className="form-control" id="" placeholder="" value={this.state.first_name} onChange={this.changeHandler} />
                                {this.validator.message(
                                    "first_name",
                                    this.state.first_name,
                                    "name_required|alpha_space",
                                    { className: "valid_info" }
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">{GetLocalizationString('last_name')}</label>
                                <input type="text" name="last_name" className="form-control" id="" placeholder="" value={this.state.last_name} onChange={this.changeHandler} />
                                {this.validator.message(
                                    "last_name",
                                    this.state.last_name,
                                    "alpha_space",
                                    { className: "valid_info" }
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="">{GetLocalizationString('phone_no')}</label>
                                <input type="text" name="mobile" className="form-control" id="" placeholder="" value={this.state.mobile} onChange={this.changeHandler} readOnly={true} />
                            </div>
                            {
                            (this.state.user_data.role_id == 2)
                            ? 
                            (
                                <div className="form-group">
                                    <label htmlFor="">{GetLocalizationString('whatsapp_no')}</label>
                                    <input type="text" name="whatsapp_number" className="form-control" id="" placeholder="" value={this.state.whatsapp_number} onChange={this.changeHandler} maxLength="10" minLength="10"/>
                                    {this.validator.message(
                                        "whatsapp_number",
                                        this.state.whatsapp_number,
                                        "numeric|valid_mobile|size:10",
                                        { className: "valid_info" }
                                    )}
                                </div>
                            )
                            :''
                            }
                            
                            {
                                (this.state.SelectedCategory == process.env.REACT_APP_TRANSPOTATION_ID || this.state.user_data.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID)
                                ?
                                (
                                    <div className="form-group">
                                        <label htmlFor="">{GetLocalizationString('identity_no')}</label>
                                        <input type="text" name="unique_id" className="form-control" id="" placeholder="" value={this.state.unique_id} onChange={this.changeHandler} />
                                        {this.validator.message(
                                            "unique_id",
                                            this.state.unique_id,
                                            "unique_required|alpha_num_dash_space",
                                            { className: "valid_info" }
                                        )}
                                    </div>
                                )
                                :
                                ''
                            }

{
                            (this.state.user_data.role_id == 2)
                            ? 
                            (
                                <div className="form-group"> 
                                    <label htmlFor="">{GetLocalizationString('description')}</label>
                                    <textarea name="profile_description" id="" className="form-control" rows="7" onChange={this.changeHandler} value={this.state.profile_description} placeholder={GetLocalizationString('write_here')}></textarea>
                                </div>
                            )
                            :''
                            }
                            
                                <button className="btn-theme mt-4 mb-3" type="submit" onClick={this.updateProfile}> {(this.state.user_data.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID || this.state.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) ? GetLocalizationString('setup_fees') : (this.state.role_id == process.env.REACT_APP_PROVIDER_ROLE_ID) ? GetLocalizationString('select_location') : GetLocalizationString('submit')} <img src={ArrowRight} alt=""/> </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            </>
            :
            ''
          )
    }
}

export default UpdateProfile