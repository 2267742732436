import React, { Component } from 'react'
import Category from "./Category"
import ArrowLeft from "./../../assets/images/al.png"
import { GetLocalizationString, HidePreloader, SetDefaultLanguage } from '../../Helpers/CustomHelper'

class SelectSpecialization extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            service_categories: [],
            category_info:{},
        };
        this.RedirectToProfile = this.RedirectToProfile.bind(this);
    }
    
    RedirectToProfile = () => {
        this.props.history.push({
            pathname: '/my-profile',
        });
    }
    
    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        let routeState
        if(this.props.location.service_categories){
            localStorage.setItem('specializationrouteState', JSON.stringify(this.props.location.service_categories))
            routeState = this.props.location.service_categories
            let ServiceCategoriesList = routeState;
            if(ServiceCategoriesList.length > 0){
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                    return <Category key={i} actionFor="vendor-list" category_info={category} nextPage="/my-profile" category_type='Service'/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                    })
                }, 100);
                
            }
            if(this.props.location.category_info){
                localStorage.setItem('sub_category_info', JSON.stringify(this.props.location.category_info))
            }
            this.setState({
                category_info:this.props.location.category_info,
            })
            localStorage.setItem('SelectedSubCategory',(localStorage.getItem('RequestFor') == 'Update') ? this.props.location.category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSpecialization',0)
        } else {
            routeState = localStorage.getItem('specializationrouteState')
            if(routeState) routeState = JSON.parse(routeState)
            let ServiceCategoriesList = routeState;
            if(ServiceCategoriesList.length > 0){
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                    return <Category key={i} actionFor="vendor-list" category_info={category} nextPage="/my-profile" category_type='Service'/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                    })
                }, 100);
            }

            let category_info = localStorage.getItem('sub_category_info')
            if(category_info) category_info = JSON.parse(category_info)
            this.setState({
                category_info:category_info,
            })
            localStorage.setItem('SelectedSubCategory',(localStorage.getItem('RequestFor') == 'Update') ? category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSpecialization',0)
        }
        
    }
    
    render() {
        return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">
                            {
                                (localStorage.getItem('RequestFor') == 'Update')?
                                GetLocalizationString('select_specialization')
                                :
                                this.state.category_info.service_category_title ?? GetLocalizationString('select_specialization')
                            }
                            </h1>
                        </div>
                        <div className="col-2 text-right">
                            
                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        {
                            (localStorage.getItem('RequestFor') == 'Update')?
                            (<div className="col-12">
                                <h2 className="special_text">{GetLocalizationString('select_specialization_service')}</h2>
                            </div>)
                            :
                            (<div className="col-12">
                                <h2 className="special_text">{GetLocalizationString('select_specialization')}</h2>
                            </div>)
                        }
                        {this.state.service_categories}
                    </div>
                </div>
            </section>
        </>
        )
    }
}

export default SelectSpecialization
