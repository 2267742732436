////////////////////////////////////////////////////////////
//     							                          //
//  Program: VerifyOTP.jsx                                     //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Anil kumar Gupta  						  //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator'
import AuthenticationService from '../../services/AuthenticationService'
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowNotifications, ShowPreloader } from '../../Helpers/CustomHelper';
import Timer from "../common/Timer";
import ArrowRignt from "./../../assets/images/ar.png"
import ArrowLeft from "./../../assets/images/al.png"

export class VerifyOTP extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
    this.state = {
        isSubmit: false,
        showAlertModal: false,
        alertModalType: '',
        alertModalTitle: '',
        alertModalMessage: '',
        input_one: '',
        input_two: '',
        input_three: '',
        input_four: '',
        mobile: '',
        minutes:'',
        redirectTo: '',
        headerToken: localStorage.getItem('headerToken'),
    }
    
    this.changeHandler = this.changeHandler.bind(this);
    this.VerifyOTP = this.VerifyOTP.bind(this);
    this.RedirectToLogin = this.RedirectToLogin.bind(this);
  }
  RedirectToLogin = (e) => {
    e.preventDefault();
    this.props.history.push({
        pathname: (localStorage.getItem('register_request_type') == 'login') ? '/login': '/register',
        state: { 
            mobile: ''
        }
    });
  }
  VerifyOTP = (e) => {
    ShowPreloader()
    e.preventDefault();
    if(this.validator.allValid()){ 
        this.setState({isSubmit: true});
        let otp = this.state.input_one+this.state.input_two+this.state.input_three+this.state.input_four;
        let input = {
            mobile: this.state.mobile,
            user_otp:otp,
            first_name: localStorage.getItem('register_first_name') ?? '',
            last_name: localStorage.getItem('register_last_name') ?? '',
            default_language: localStorage.getItem('register_default_language') ?? 'hi',
            role_id: localStorage.getItem('register_role_id') ?? 0,
        }
        AuthenticationService.verifyOtp(input)
        .then((res) =>{
            localStorage.setItem('register_mobile','')
            localStorage.setItem('register_first_name','')
            localStorage.setItem('register_last_name','')
            localStorage.setItem('register_role_id','')
            localStorage.setItem('register_default_language','')
            this.setState({
                input_one: '',
                input_two: '',
                input_three: '',
                input_four: '',
            })
            HidePreloader()
            if((global.successStatus).includes(res.status)) {
                console.log(res);
                localStorage.setItem(
                "headerToken",
                res.data.headerToken ? res.data.headerToken : ""
                );
                global.headerToken = res.data.headerToken ? res.data.headerToken : "";
                localStorage.setItem("isLogin", 1);
                localStorage.setItem(
                "userData",
                res.data.user_data ? JSON.stringify(res.data.user_data) : {}
                );

                localStorage.setItem(
                "default-user-lang",
                res.data.user_data ? res.data.user_data.default_language : process.env.REACT_APP_DEFAULT_LANGUAGE
                );
                
                ShowNotifications('success', GetLocalizationString('otp_verify_successfully'),1500).then(()=>{
                    if(this.state.redirectTo != '/home'){
                        window.location.href = '/select-category';
                    }else{
                        window.location.href = '/home';
                    }
                });
                
            }else if(res.status === 401){
                window.document.getElementById('first_otp_input').focus()
                ShowNotifications('error', GetLocalizationString('entered_otp_invalid'),1500)
                window.document.getElementById('first_otp_input').focus()
            }else if(res.status === 400){
                window.document.getElementById('first_otp_input').focus()
                ShowNotifications('error', GetLocalizationString('entered_otp_invalid'),1500)
            }else{
                window.document.getElementById('first_otp_input').focus()
                ShowNotifications('error', GetLocalizationString('entered_otp_invalid'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                input_one: '',
                input_two: '',
                input_three: '',
                input_four: '',
            })
            window.document.getElementById('first_otp_input').focus()
            console.log(errors);
        });
    }else{
        HidePreloader()
        this.validator.showMessages();
    }
  }

  changeHandler = (event) => {
    if(event.target.value <= 9)
    this.setState({[event.target.name]:event.target.value});
    var form = document.getElementById('verifyOTPForm');
    const index = Array.prototype.indexOf.call(form, event.target);
    if(event.target.value.trim() != '' && event.target.value <= 9 && event.currentTarget.id != 'forth_otp_input'){
        form.elements[index + 1].focus();
    }
    
    this.validator.showMessageFor(event.target.name);
  }

  handleKeyDown = (event) => {
    var form = document.getElementById('verifyOTPForm');
    const index = Array.prototype.indexOf.call(form, event.target);
    if(event.key === 'Backspace' && event.currentTarget.id != 'first_otp_input'){
        this.setState({[event.target.name]:''});
        form.elements[index - 1].focus();
    }
  }

  componentDidMount(){
    SetDefaultLanguage(localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE)
    HidePreloader()
    window.document.getElementById('first_otp_input').focus()
    let routeState
    if(this.props.location.state){
        localStorage.setItem('routeState', JSON.stringify(this.props.location.state))
        routeState = this.props.location.state
    } else {
        routeState = localStorage.getItem('routeState')
        if(routeState) routeState = JSON.parse(routeState)
    }
    
    if(routeState){
        this.setState({
            mobile:routeState.mobile,
            redirectTo: routeState.redirectTo
        })
    } else {
        this.props.history.push({
            pathname: '/login'
        }); 
    }

  }

  render() {
    return (
      <>
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <a  onClick={this.RedirectToLogin}><img src={ArrowLeft} alt="" /></a>
                    </div>
                
                </div>
            </div>
        </header>
        <section className="otp_sec">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                        <h1 className="head_heading">{GetLocalizationString('enter_otp')}</h1>
                        <p> {GetLocalizationString('otp_send_to')} { this.state.mobile.substring(0, 2) + 'XXXXXXXX' }</p>
                        <form id="verifyOTPForm" action="#">
                        <div className="row otp_here">
                                <div className="form-group col-3 m-0">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="first_otp_input" placeholder="" 
                                    name="input_one"
                                    value={this.state.input_one}
                                    onChange={this.changeHandler}
                                    autoComplete="off"
                                    onKeyDown={this.handleKeyDown}
                                    />
                                </div>
                                <div className="form-group col-3 m-0">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="second_otp_input" placeholder="" 
                                    name="input_two"
                                    value={this.state.input_two}
                                    onChange={this.changeHandler}
                                    autoComplete="off"
                                    onKeyDown={this.handleKeyDown}
                                    />
                                </div>
                                <div className="form-group col-3 m-0">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="third_otp_input" placeholder="" 
                                    name="input_three"
                                    value={this.state.input_three}
                                    onChange={this.changeHandler}
                                    autoComplete="off"
                                    onKeyDown={this.handleKeyDown}
                                    />
                                </div>
                                <div className="form-group col-3 m-0">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="forth_otp_input" placeholder="" 
                                    name="input_four"
                                    value={this.state.input_four}
                                    onChange={this.changeHandler}
                                    autoComplete="off"
                                    onKeyDown={this.handleKeyDown}
                                    />
                                </div>
                               
                        </div>
                        <button className="btn-theme none_outline" type="submit" onClick={this.VerifyOTP}> {GetLocalizationString('continue')} <img src={ArrowRignt} alt="" /> </button>
                    </form>
                    </div>
                    <div className="col-12  text-left pl-5">
                        <p className="otp_count"><Timer minutes="10" mobile={ this.state.mobile } request_for={localStorage.getItem('register_request_type') ?? ''} /></p>
                    </div>
                   
                </div>
            </div>
        </section>
      </>
    );
  }
}

export default VerifyOTP;
