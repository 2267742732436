////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../../assets/images/al.png"
import ArrowRight from "./../../assets/images/ar.png"
import PhonePe from "./../../assets/images/payment/phonepe.png"
import GooglePay from "./../../assets/images/payment/Gpay.png"
import Bhimupi from "./../../assets/images/payment/bhim.png"
import Paytm from "./../../assets/images/payment/paytm.png"
import Ruppy from "./../../assets/images/ruppy.png"
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { GetLocalizationString, getLoggedInUser, HidePreloader, SetDefaultLanguage } from '../../Helpers/CustomHelper';
import { ShowNotifications } from '../../Helpers/CustomHelper';
import $ from 'jquery';

import defaultImage from "./../../images/cat.png"
import Moment from 'moment'

export class UpdatePayment extends Component {

    constructor(props) {
        super(props)

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });

        this.state = {
            input: {},
            errors: {},
            settings: {},
            isSubmit:false,
            is_discount:getLoggedInUser().is_discount_iitr ?? 0,
            advance_fees:getLoggedInUser().advance_fees ?? '',
            discount_fees:getLoggedInUser().discount_fees ?? '',
            night_consultation:getLoggedInUser().night_consultation ?? 0,
            video_consultation:getLoggedInUser().video_consultation ?? 0,
            fee_in_advance:getLoggedInUser().payment_method ?? 0,
            second_visit_within:getLoggedInUser().number_of_within_days ?? '',
            user_data:getLoggedInUser(),
            payment_instructions:[],
            payment_checkbox: [1,0,0,0]

        }
        this.changeHandler = this.changeHandler.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.ArrayChangeHandler = this.ArrayChangeHandler.bind(this)
    }

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        var resData = {};
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                console.log(res)
                resData = res.data ? res.data : {}
                resData.imageUrl = res.data.profile_thumb_url ? res.data.profile_thumb_url : defaultImage
                if(resData.payment_instructions.length > 0){
                    var checkboxArray = []
                    for(var c=0;c < resData.payment_instructions.length; c++){
                        if(resData.payment_instructions[c].account_number != '' || c == 0){
                            checkboxArray.push(1)
                        }else{
                            checkboxArray.push(0)
                        }
                    }
                }
                this.setState({
                    user_data:resData,
                    settings: resData.settings,
                    payment_instructions:resData.payment_instructions,
                    payment_checkbox: checkboxArray
                })
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
               
    }
    checkForSpecialChar = function(string){
        var specialChars = "<>!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
        for(var i = 0; i < specialChars.length;i++){
          if(string.indexOf(specialChars[i]) > -1){
              return true
           }
        }
        return false;
    }
    updateProfile = (e) => {
        e.preventDefault();
        
        if(this.validator.allValid()){ 
            var IncorrectIns = 0;
            var EmptyIns = 0;
            var PaymentIns = this.state.payment_instructions;
            for(let i = 0; i < PaymentIns.length; i++){
                const mobileRegex = /^[6-9]\d{9}$/gi;
                if(PaymentIns[i].account_number.trim() != ''){
                    if(mobileRegex.test(PaymentIns[i].account_number.trim())){
                        if(this.checkForSpecialChar(PaymentIns[i].account_number)){
                            IncorrectIns++
                        }  
                    }else{
                        if(this.checkForSpecialChar(PaymentIns[i].account_number)){
                            IncorrectIns++
                        } 
                    }
                }else{
                    EmptyIns++
                }
            }
            if(IncorrectIns > 0){
                ShowNotifications('error', GetLocalizationString('valid_upi_number'),1500)
                return false
            }

            if(this.state.is_discount == 1 && this.state.discount_fees < 1){
                ShowNotifications('error', GetLocalizationString('discount_fees_required'),1500)
                return false
            }

            if(this.state.fee_in_advance == 1 && EmptyIns == 4){
                ShowNotifications('error', GetLocalizationString('select_upi_number'),1500)
                return false 
            }
            this.setState({isSubmit: true});
            let input = {
                is_discount:this.state.is_discount,
                advance_fees:this.state.advance_fees,
                discount_fees:this.state.discount_fees,
                night_consultation:this.state.night_consultation,
                video_consultation:this.state.video_consultation,
                payment_method:this.state.fee_in_advance,
                number_of_within_days:this.state.second_visit_within,
                payment_instructions: JSON.stringify(this.state.payment_instructions),
                request_for: 'update_payment'
            }
            console.log(input)
            AuthenticationService.uploadProfileImage(input)
            .then((res) =>{
              if((global.successStatus).includes(res.status)) {
                  ShowNotifications('success', GetLocalizationString('profile_update_success'),1500).then(()=>{
                    localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/update-address-location'
                        }); 
                    }, 500);
                      
                  });
              }else if(res.status === 401){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }else if(res.status === 400){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
              else{
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
            }).catch((errors)=>{
                console.log(errors);
            });
           
        }else{
            this.validator.showMessages();
        } 
    }

    changeHandler = (event) => {
        let input = this.state.input;
        if (event.target.type === 'date' && event.target.value !== null) {
            event.target.value = Moment(event.target.value, 'DD-MM-YYYY').toDate();
        }
        if((event.target.type === 'checkbox') && event.target.name == 'is_discount'){
            if(event.target.checked){
                $('#discount_price_container').show()
            }else{
                $('#discount_price_container').hide()
            }
        }
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });

        this.validator.showMessageFor(event.target.name);

    }

    checkboxHandler = (event) => {
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        var totalCheckbox = this.state.payment_checkbox;
        totalCheckbox[event.target.dataset.index] = value
        this.setState({ 
            payment_checkbox: totalCheckbox
        });
        this.validator.showMessageFor(event.target.name);

    }
    updateItem(id, itemAttributes) {
        var index = this.state.payment_instructions.findIndex(x=>{ 
            return x.payment_instruction_id == id
        });
        if (index === -1)
            console.log('Oops!')
        else{
            this.setState({
                payment_instructions: [
                    ...this.state.payment_instructions.slice(0,index),
                    Object.assign({}, this.state.payment_instructions[index], itemAttributes),
                    ...this.state.payment_instructions.slice(index+1)
                ]
            })
        }
        /** this.updateItem(2, {someattr: 'a new value'}) */
    }
    ArrayChangeHandler = (e) => {
        var index = e.target.getAttribute('data-index')
        var itemIndex = e.target.getAttribute('data-item-index')
        const obj = e.target.value
        console.log(index,obj)
        this.updateItem(index,{'account_number' : obj})
        var totalCheckbox = this.state.payment_checkbox;
        if(obj.trim() == ''){
            totalCheckbox[itemIndex] = 0
            this.setState({ 
                payment_checkbox: totalCheckbox
            });
        }else{
            totalCheckbox[itemIndex] = 1
            this.setState({ 
                payment_checkbox: totalCheckbox
            }); 
        }
    }

    render() {
        return (
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('setup_fees')}</h1>
                        </div>
                    </div>
                </div>
            </header>
            {/* <section className="mt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="diss_box">
                            <div className="alert alert-danger text-left">
                                    <strong>{GetLocalizationString('disclaimer')}</strong>
                                    {this.state.settings.setup_fees_disclaimer ?? ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="payment_mode">
                <form action="">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="form-group m-0">
                                <label>{GetLocalizationString('advance_fees')}</label>
                                <input type="text" className="form-control ruppy_sym"  placeholder="" name='advance_fees' value={this.state.advance_fees > 0 ? this.state.advance_fees :''} onChange={this.changeHandler} />
                                <span className="ruppy_sym_new_two">
                                    <img src={Ruppy} alt="" className="img-fluid"/>
                                </span>
                            </div>
                            <div className="pay_type" >
                                <div className="row" >
                                    <div className="col-1">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input defaultCheckbox m-0" type="checkbox" id="inlineCheckbox1" value={this.state.is_discount} name='is_discount' checked={(this.state.is_discount == 1) ? 'checked' : ''} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-11">
                                        <p className="optional_type">{GetLocalizationString('discount_text')}</p>  
                                    </div>
                                    { (this.state.is_discount == 1)
                                    ?
                                    (
                                        <div className="col-12" id='discount_price_container'>
                                            <div className="form-group m-0">
                                                <label className='new_lable_final'>{GetLocalizationString('discounted_fees_text')}</label>
                                                <input type="text" className="form-control ruppy_sym mt-0"  placeholder="" name='discount_fees' value={this.state.discount_fees > 0 ? this.state.discount_fees : ''} onChange={this.changeHandler} />
                                                <span className="ruppy_sym_new_two ruppy_sym_new_three">
                                                    <img src={Ruppy} alt="" className="img-fluid"/>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                    :
                                    ''
                                    }
                                    
                                </div>
                            </div>
                            <div className="pay_type" >
                                <div className="row" >
                                    <div className="col-1">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input defaultCheckbox m-0" type="checkbox" id="inlineCheckbox1" value={this.state.night_consultation}  name='night_consultation' checked={(this.state.night_consultation == 1) ? 'checked' : ''} onChange={this.changeHandler}/>
                                        </div>
                                    </div>
                                    <div className="col-11">
                                        <p className="optional_type">{GetLocalizationString('night_consultation')}</p>  
                                    </div>
                                </div>
                            </div>
                            <div className="pay_type" >
                                <div className="row" >
                                    <div className="col-1">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input defaultCheckbox " type="checkbox" id="inlineCheckbox1" name="video_consultation" value={this.state.video_consultation} checked={(this.state.video_consultation == 1) ? 'checked' : ''} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-11">
                                        <p className="optional_type">{GetLocalizationString('video_consultation')}</p>  
                                    </div>
                                </div>
                            </div>
                        
                            <div className="pay_type" >
                                <div className="form-group m-0">
                                    <label htmlFor="" className='new_lable_radio'>{GetLocalizationString('advance_fee_text')}</label>
                                </div>
                                <div className="form-check d-inline mb-3">
                                    <input className="form-check-input" type="radio" name="fee_in_advance" id="exampleRadios1" value="0" checked={(this.state.fee_in_advance == 0) ? 'checked' : ''} onChange={this.changeHandler}/>
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                    {GetLocalizationString('no')}
                                    </label>
                                </div>

                                <div className="form-check d-inline ml-5 mb-3">
                                    <input className="form-check-input" type="radio" name="fee_in_advance" id="exampleRadios2" value="1" checked={(this.state.fee_in_advance == 1) ? 'checked' : ''} onChange={this.changeHandler} />
                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                    {GetLocalizationString('yes')}
                                    </label>
                                </div>
                                
                                {/* payment methods start from here */}
                                {
                                (this.state.fee_in_advance == 1)
                                ?
                                this.state.payment_instructions.map((payment, i) => {
                                    var default_img = (i == 0)? Paytm :(i == 1)? PhonePe :(i == 2)? GooglePay : Bhimupi
                                    var default_txt = (i == 0)? GetLocalizationString('paytm') :(i == 1)? GetLocalizationString('phonepe') :(i == 2)? GetLocalizationString('googlepay') : GetLocalizationString('bhimupi')
                                    var default_label = (i == 0)? GetLocalizationString('paytm_label') :(i == 1)? GetLocalizationString('phonepe_label') :(i == 2)? GetLocalizationString('googlepay_label') : GetLocalizationString('bhimupi_label')
                                                        
                                return (
                                    <div className="pay_type" key={i}>
                                        <div className="row" >
                                            <div className="col-10">
                                                <div className="media" >
                                                    <img className="d-flex" src={default_img} alt=""/>
                                                    <div className="media-body">
                                                    <h5 className="m-0">{default_txt}</h5>
                                                    <p>{default_label}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-2">
                                                <div className="form-check form-check-inline">
                                                    <label data-toggle="collapse" data-target={"#collapseOne"+i} aria-expanded="false" aria-controls="collapseOne">
                                                        <input data-index={i} className="form-check-input defaultCheckbox" type="checkbox" id="inlineCheckbox1" value="1" checked={this.state.payment_checkbox[i] ? 'checked' : ''} onChange={this.checkboxHandler} />
                                                    </label>
                                                
                                                </div>
                                            </div>
                                            <div className="col-10 offset-1">
                                                <div id={"collapseOne"+i} aria-expanded="false" className={payment.account_number || i == 0 ? 'collapse show' : 'collapse'}>

                                                <div className="form-group m-0 input_length">
                                                    <input type="text" data-item-index={i} data-index={payment.payment_instruction_id} name="account_number" value={payment.account_number} className="form-control account_number_input" onChange={this.ArrayChangeHandler} />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                );
                                })
                                :
                                ''
                                }
                                
                                
                            </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="pay_visit">
                                    <div className="form-group m-0">
                                        <label >{GetLocalizationString('second_visit_text')}</label>
                                        <input type="text" className="form-control"  placeholder={GetLocalizationString('days_placeholder')} value={this.state.second_visit_within > 0 ? this.state.second_visit_within : ''}name='second_visit_within' onChange={this.changeHandler}/>

                                    </div>
                                </div>
                            
                                <button className="btn-theme mt-4 mb-3" type="submit" onClick={this.updateProfile}> {GetLocalizationString('select_location')} <img src={ArrowRight} alt=""/> </button>

                            </div>
                    </div>
                </div>
            </form>
            </section>
            </>
          )
    }
}

export default UpdatePayment