/*global google*/
import React from "react";
import ArrowLeft from "./../../assets/images/al.png"
import ArrowRight from "./../../assets/images/ar.png"
import LocationImg from "./../../assets/images/icon/location.png"
import { GoogleMap, LoadScript, Marker, } from "@react-google-maps/api";
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import {GetLocalizationString, getLoggedInUser, SetDefaultLanguage } from '../../Helpers/CustomHelper';
import { ShowNotifications,HidePreloader } from '../../Helpers/CustomHelper';

let markerArray = []

const libraries = ['places'];
class UpdateAddress extends React.Component {

  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.getReverseGeocodingData = this.getReverseGeocodingData.bind(this)
    this.UpdateProfile = this.UpdateProfile.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.UpdateCurrentLocation = this.UpdateCurrentLocation.bind(this)
  }

  componentDidMount(){
    HidePreloader()
    SetDefaultLanguage()
  }

  state = {
    currentLocation: { lat: 0, lng: 0 },
    markers: [],
    bounds: null,
    current_address: '',
    latitude: process.env.REACT_APP_DEFAULT_LAT,
    longitude: process.env.REACT_APP_DEFAULT_LONG,
    isSubmit: false,
    isLoaded:true,
    user_data : getLoggedInUser()
  };

  changeHandler = (event) => {
    this.setState({
      current_address : event.target.value
    })
  }

  getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng)
    var geocoder = new google.maps.Geocoder()
    geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
            console.log(status)
        }
        if (status == google.maps.GeocoderStatus.OK) {
            this.setState({
              current_address: results[0].formatted_address
            })
        }
    });

  }

  UpdateCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=> {
        markerArray.push({lat: position.coords.latitude, lng: position.coords.longitude});
        this.setState({
          markers: markerArray,
          currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude },
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
        setTimeout(() => {
          this.getReverseGeocodingData(this.state.latitude, this.state.longitude)
        }, 100);
        
      });
    }
  }

  onMapLoad = map => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=> {
        markerArray.push({lat: position.coords.latitude, lng: position.coords.longitude});
        this.setState({
          markers: markerArray,
          currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude },
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
        setTimeout(() => {
          this.getReverseGeocodingData(this.state.latitude, this.state.longitude)
        }, 100);
        
      });
    }
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        this.setState({ currentLocation: pos });
      }
    );
    google.maps.event.addListener(map, "bounds_changed", () => {
      console.log(map.getBounds());
      this.setState({ bounds: map.getBounds() });
    });
  }

  onSBLoad = ref => {
    this.searchBox = ref;
  }

  onPlacesChanged = (event) => {
    var address = this.state.current_address
    if (event.key === 'Enter') {
      markerArray = [];
      fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key='+process.env.REACT_APP_MAP_KEY)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if(data.status == 'OK'){
          const latitude = data.results[0].geometry.location.lat
          const longitude = data.results[0].geometry.location.lng
          let place = data.results[0].geometry.location
          markerArray.push(place);
        setTimeout(() => {
          this.setState({ 
            markers: markerArray,
            currentLocation: data.results[0].geometry.location,
            current_address: data.results[0].formatted_address,
            latitude:  latitude ?? parseFloat(process.env.REACT_APP_DEFAULT_LAT),
            longitude: longitude ?? parseFloat(process.env.REACT_APP_DEFAULT_LONG)
          });
        }, 10);
        }else{
          console.warn('address not fetched')
        }
      }).catch((err)=>{
        console.error(err)
      })        
    }
  }

  UpdateProfile = (e) => {
    e.preventDefault();
    if(this.validator.allValid()){ 
        this.setState({isSubmit: true});
        let input = {
            latitude:this.state.latitude,
            longitude:this.state.longitude,
            address:this.state.current_address,
            request_for: 'update_location'
        }
        console.log(input)
        AuthenticationService.uploadProfileImage(input)
        .then((res) =>{
          if((global.successStatus).includes(res.status)) {
              ShowNotifications('success', GetLocalizationString('profile_update_success'),2000).then(()=>{
                localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
                setTimeout(() => {
                    this.props.history.push({
                        pathname: (this.state.user_data.role_id == process.env.REACT_APP_PROVIDER_ROLE_ID && this.state.user_data.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) ? '/update-provider-slots/'+this.state.user_data.slug : '/register-success/'+this.state.user_data.slug
                    }); 
                }, 500);
                  
              });
          }else if(res.status === 401){
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }else if(res.status === 400){
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }
          else{
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }
        }).catch((errors)=>{
            console.log(errors);
        });
       
    }else{
        this.validator.showMessages();
    } 
}


  render() {
    return (
     (this.state.isLoaded == true)
     ?
      <>
      <header>
          <div className="container">
              <div className="row">
                  <div className="col-2">
                  <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                  </div>
                  <div className="col-8">
                      <h1 className="head_heading">{GetLocalizationString('select_location')}</h1>
                  </div>
                  <div className="col-2 text-right"></div>
              </div>
          </div>
      </header>
      <LoadScript
       googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
       libraries={libraries}
      >
        
        <GoogleMap
          center={this.state.currentLocation}
          zoom={12}
          onLoad={map => this.onMapLoad(map)}
          mapContainerStyle={{ height: "65vh", width: "100%" }}
        >
          {this.state.markers.map((mark, index) => (
            <Marker key={index} position={mark} />
          ))}
        </GoogleMap>
          
        <section className="Foot_btn_first foot_btn_second">
          <div className="container">
              <div className="row">
                  <div className="col-12 text-center">
                      <img src={LocationImg} alt="" className="img-fluid" onClick={this.UpdateCurrentLocation}/>
                        <div className="form-group">
                            <label htmlFor="">{GetLocalizationString('your_location')}</label>
                            {/* <StandaloneSearchBox
                            onLoad={this.onSBLoad}
                            onPlacesChanged={this.onPlacesChanged}
                            bounds={this.state.bounds}
                            > */}
                            <input
                                type="text"
                                value={this.state.current_address}
                                onChange={this.changeHandler} onKeyDown={this.onPlacesChanged}
                                name='current_address'
                                placeholder={GetLocalizationString('enter_your_address')}
                                className="form-control"
                            />
                            {/* </StandaloneSearchBox> */}
                        </div>
                        <button className="btn-theme" type="submit" onClick={this.UpdateProfile}> {(this.state.user_data.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) ? GetLocalizationString('setup_time') : GetLocalizationString('submit')} <img src={ArrowRight} alt=""/> </button>
                  </div>
              </div>
          </div>
        </section>
        
     </LoadScript>
     </> 
     :
     '' 
    );
  }
}

export default UpdateAddress;
