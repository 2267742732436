////////////////////////////////////////////////////////////
//     							                          //
//  Program: Register.jsx                                  //
//  Application: authentication                           //
//  Option: For register                                  //
//  Developer: 						                      //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { Link, Redirect } from "react-router-dom";
import { GetLocalizationString, HidePreloader, ShowNotifications, GetValidationString, SetDefaultLanguage, ShowPreloader } from '../../Helpers/CustomHelper';
import ArrowRignt from "./../../assets/images/ar.png"

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmit: false,
            showAlertModal: false,
            alertModalType: '',
            alertModalTitle: '',
            alertModalMessage: '',
            mobile: '',
            first_name: '',
            last_name: '',
            role_id: "0",
            default_lang: localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE,
            headerToken: localStorage.getItem('headerToken'),
            terms_condition:'',
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                valid_mobile: {
                    message: GetValidationString('please_select_phone_no', this.state.default_lang),
                    rule: (val) => {
                        const regexExp = /^[6-9]\d{9}$/gi;
                        if (regexExp.test(val)) {
                            return true
                        }
                        return false
                    }
                },
                mobile: {
                    required: true,
                    message: GetValidationString('mobile_required', this.state.default_lang),
                    rule: (val) => {
                        if (val.trim() != '') {
                            return true
                        }
                        return false
                    }
                },
                name_required: {
                    required: true,
                    message: GetValidationString('name_required', this.state.default_lang),
                    rule: (val) => {
                        if (val.trim() != '') {
                            return true
                        }
                        return false
                    }
                },
                required_terms_condition : {
                    required: true,
                    message: GetValidationString('required_terms_condition', this.state.default_lang),
                    rule: (val) => {
                        if(document.getElementById("terms_condition")){
                            if(document.getElementById("terms_condition").checked){
                                return true
                            }else{
                                return false
                            } 
                        }                       
                    }
                }
            },
            messages: {
                required_terms_condition: GetValidationString('required_terms_condition', this.state.default_lang),
                mobile: GetValidationString('mobile_required', this.state.default_lang),
                name_required: GetValidationString('name_required', this.state.default_lang),
                numeric: GetValidationString('only_digit', this.state.default_lang),
                size: GetValidationString('phone_no_size', this.state.default_lang),
                alpha_space: GetValidationString('alpha_space', this.state.default_lang)
            }
        });
        this.changeHandler = this.changeHandler.bind(this);
        this.Regiser = this.Regiser.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.SetDefaultLanguage = this.SetDefaultLanguage.bind(this);
    }

    onValueChange = (event) => {
        this.setState({
            role_id: event.target.value
        });
    }

    SetDefaultLanguage = (e) => {
        let default_lang = e.target.getAttribute('data-id')
        this.setState({
            default_lang: default_lang
        });
        localStorage.setItem('default-user-lang', default_lang)
        SetDefaultLanguage(default_lang)
    }

    componentDidMount() {
        SetDefaultLanguage(localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE)
        HidePreloader()
        /*if(this.state.headerToken !== null || this.state.headerToken !== ''){
            localStorage.clear();
            sessionStorage.clear();
        }*/
    }

    Regiser = (e) => {
        this.validator.messages = {
            required_terms_condition: GetValidationString('required_terms_condition', this.state.default_lang),
            mobile: GetValidationString('mobile_required', this.state.default_lang),
            name_required: GetValidationString('name_required', this.state.default_lang),
            numeric: GetValidationString('only_digit', this.state.default_lang),
            size: GetValidationString('phone_no_size', this.state.default_lang),
            alpha_space: GetValidationString('alpha_space', this.state.default_lang)
        };
        e.preventDefault();
        ShowPreloader()
        if (this.validator.allValid()) {
            this.setState({ isSubmit: true });
            let input = {
                mobile: this.state.mobile,
                type: 'register'
            }
            if(this.state.role_id == 0){
                ShowNotifications('error', GetLocalizationString('select_provider'), 1500)
                HidePreloader()
                return false
            }
            AuthenticationService.check_mobile_exists(input)
                .then((res) => {
                    console.log(res);
                    HidePreloader()
                    if ((global.successStatus).includes(res.status)) {
                        AuthenticationService.sendOtp(input)
                            .then((res) => {
                                HidePreloader()
                                if ((global.successStatus).includes(res.status)) {
                                    localStorage.setItem('register_request_type', 'register')
                                    localStorage.setItem('register_mobile', this.state.mobile)
                                    localStorage.setItem('register_first_name', this.state.first_name)
                                    localStorage.setItem('register_last_name', this.state.last_name)
                                    localStorage.setItem('register_role_id', this.state.role_id)
                                    localStorage.setItem('register_default_language', this.state.default_lang)
                                    ShowNotifications('success', GetLocalizationString('otp_sent_successfully'), 1500).then(() => {
                                        let redirectTo = '/home';
                                        if (this.state.role_id === '2') {
                                            redirectTo = '/select-category';
                                        }
                                        this.props.history.push({
                                            pathname: '/verify-otp',
                                            state: {
                                                mobile: this.state.mobile,
                                                redirectTo: redirectTo
                                            }
                                        });
                                    });
                                } else if (res.status === 401) {
                                    console.log(res);
                                    ShowNotifications('error', GetLocalizationString('user_not_found'), 1500)
                                } else if (res.status === 400) {
                                    console.log(res);
                                    ShowNotifications('error', GetLocalizationString('user_not_found'), 1500)
                                } else if (res.status === 422) {
                                    console.log(res);
                                    ShowNotifications('error', res.data.message, 1500)
                                } else {
                                    console.log(res);
                                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                                }
                            }).catch((errors) => {
                                console.log(errors);
                            });

                    } else if (res.status === 401) {
                        HidePreloader()
                        console.log(res);
                        ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                    } else if (res.status === 400) {
                        HidePreloader()
                        console.log(res);
                        ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                    }
                    else if (res.status === 422) {
                        HidePreloader()
                        console.log(res);
                        ShowNotifications('error', res.data.message, 1500)
                    }
                    else {
                        HidePreloader()
                        console.log(res);
                        ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                    }
                }).catch((errors) => {
                    HidePreloader()
                    console.log(errors);
                });

        } else {
            HidePreloader()
            this.validator.showMessages();
        }
    }

    changeHandler = (event) => {
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        this.setState({ [event.target.name]: value });
        this.validator.showMessageFor(event.target.name);
    }

    getTitle() {
        return process.env.REACT_APP_PROJECT_TITLE ? process.env.REACT_APP_PROJECT_TITLE : '';
    }

    render() {
        if (this.state.headerToken !== null) {
            return <Redirect to='/home' />;
        }
        return (
            <section className="signup_page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>{GetLocalizationString('select_language')}</h3>
                        </div>
                        <div className="col-4">
                            <a data-id="hi" className={(this.state.default_lang == 'hi') ? 'default_lang btn btn-theme_lang' : 'default_lang btn btn-theme_lang btn_hindi_lang'} onClick={this.SetDefaultLanguage}>हिंदी </a>
                        </div>
                        <div className="col-4 pl-0">
                            <a data-id="en" className={(this.state.default_lang == 'en') ? 'default_lang btn btn-theme_lang' : 'default_lang btn btn-theme_lang btn_hindi_lang'} onClick={this.SetDefaultLanguage}>ENG</a>
                        </div>
                        <div className="col-12 ">
                            <form action="">
                                <div className="form-group">
                                    <label htmlFor="">{GetLocalizationString('phone_no')}<span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name='mobile'
                                        value={this.state.mobile}
                                        onChange={this.changeHandler}
                                        maxLength="10"
                                        minLength="10"
                                    />
                                    {this.validator.message(
                                        "mobile",
                                        this.state.mobile,
                                        "mobile|valid_mobile|numeric|size:10",
                                        { className: "valid_info" }
                                    )}

                                </div>
                                <div className="form-group">
                                    <label htmlFor="">{GetLocalizationString('first_name')}<span className='text-danger'>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        name='first_name'
                                        value={this.state.first_name}
                                        onChange={this.changeHandler}
                                    />
                                    {this.validator.message(
                                        "first_name",
                                        this.state.first_name,
                                        "name_required|alpha_space",
                                        { className: "valid_info" }
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">{GetLocalizationString('last_name')}</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        name='last_name'
                                        value={this.state.last_name}
                                        onChange={this.changeHandler}
                                    />
                                    {this.validator.message(
                                        "last_name",
                                        this.state.last_name,
                                        "alpha_space",
                                        { className: "valid_info" }
                                    )}
                                </div>
                                <div className="form-group m-0">
                                    <label htmlFor="">{GetLocalizationString('are_you_service_provider')}<span className='text-danger'>*</span></label>
                                </div>
                                <div className="form-check d-inline mr-4">
                                    <input className="form-check-input" type="radio" name="role_id" id="exampleRadios1" value="3" onChange={this.onValueChange} checked={this.state.role_id === "3"} />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        {GetLocalizationString('no')}
                                    </label>
                                </div>

                                <div className="form-check d-inline ml-5">
                                    <input className="form-check-input" type="radio" name="role_id" id="exampleRadios2" value="2" onChange={this.onValueChange} checked={this.state.role_id === "2"} />
                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                        {GetLocalizationString('yes')}
                                    </label>
                                </div>

                                <div className="col-12 p-0 mt-20">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input defaultCheckbox red-input" type="checkbox" id="terms_condition" value="1" name="terms_condition" onChange={this.changeHandler} checked={this.state.terms_condition ? 'checked' : ''}/>
                                        <label className="tandc_label ">{GetLocalizationString('agree_text_pre')} <Link to={'/web-terms-conditions'} >{GetLocalizationString('terms_condition')}</Link> {GetLocalizationString('agree_text_post')} </label> 
                                       
                                    </div>
                                    {this.validator.message(
                                        "terms_condition",
                                        this.state.last_name,
                                        "required_terms_condition",
                                        { className: "valid_info" }
                                    )}
                                </div>

                                <button className="btn-theme" type="submit" onClick={this.Regiser}>{GetLocalizationString('sign_up')}<img src={ArrowRignt} alt="" /> </button>
                                <div className="col-12 text-center sign_text">
                                    <p>{GetLocalizationString('already_have_an_account')} <Link to="/login" className="btn btn-link">{GetLocalizationString('sign_in_small')}</Link></p>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Register
