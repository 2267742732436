////////////////////////////////////////////////////////////
//     							                          //
//  Program: ApiService.js                                //
//  Application: Services                                 //
//  Option: Used to call all external/third party apis    //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const HEADER_TOKEN = localStorage.getItem("headerToken")
  ? localStorage.getItem("headerToken")
  : "";

class ApiService {
   getCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        "Accept-Language" : window.localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .get(API_BASE_URL + inputData.endPoint, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
      });
  }

   postCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        "Accept-Language" : window.localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .post(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
       
      });
  }

   putCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        "Accept-Language" : window.localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .put(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
      });
  }

   deleteCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        "Accept-Language" : window.localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .delete(API_BASE_URL + inputData.endPoint, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
      });
  }

   uploadFileCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + HEADER_TOKEN,
        "Accept-Language" : window.localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .post(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
       
      });
  }
}

export default new ApiService();
