////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import default_user_img from "./../assets/images/default_user_img.png"
import AuthenticationService from '../services/AuthenticationService'
import HomeService from '../services/HomeService'
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowNotifications, ShowPreloader } from './../Helpers/CustomHelper'
import { Link } from 'react-router-dom'
import whatsaap from "./../assets/images/social/whatsapp_new.png"
import call from "./../assets/images/social/call_new.png"
import ArrowRight from "./../assets/images/ar.png"

export class ProviderBookings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            personal_bookings: [],
            personal_bookings_count: 0,
            loading: false,
            page: 1,
           per_page: process.env.REACT_APP_PER_PAGE,
            isLoaded: false
        }

        this.handleScroll = this.handleScroll.bind(this)
    }

    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.personal_bookings_count / this.state.per_page)
        let totalPages =  (this.state.personal_bookings_count % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true,
                    isLoaded:false
                })
                this.getProviderBookings(this.state.page)
            }
        }
    };

    componentDidMount() {
        ShowPreloader()
        SetDefaultLanguage()
        AuthenticationService.getProfile()
        .then((res) => {
            console.log(res)
            if ((global.successStatus).includes(res.status)) {
                this.setState({
                    user_detail: res.data,
                    isLoaded:true
                })
            } else {
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res)
            }
        }).catch((errors) => {
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.error(errors)
        })

        /** get provider booking */
        this.getProviderBookings();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */   

    }

    getProviderBookings = () =>{
        ShowPreloader()
        var params = {
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
            "type": 2,
        }
        HomeService.GetAllBookings(params)
        .then((res) =>{
            this.setState({
                loading: false
            })
            console.log(res)
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    personal_bookings:[...this.state.personal_bookings,...res.data.patient_bookings],
                    loading: false,
                    isLoaded:true,
                    personal_bookings_count: res.data.patient_bookings_count
                })
                console.log('personal_bookings ',res.data.patient_bookings);            
            }else if(res.status === 401){
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                console.log(res);
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
    }
    render() {
        return (
            <>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-2">
                                <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                            </div>
                            <div className="col-8">
                                <h1 className="head_heading">{GetLocalizationString('my_booking')}</h1>
                            </div>

                        </div>
                    </div>
                </header>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-3">
                                
                                    {
                                        (this.state.personal_bookings.length <= 0 && this.state.isLoaded)
                                            ?
                                            
                                            (
                                                <div className="success_box no_cnt_data">
                                                    <h5 className="app_done">{GetLocalizationString('no_appointment')}</h5>
                                                </div>
                                            )
                                            :
                                            this.state.personal_bookings.map((booking, i) => {
                                            
                                                return (
                                                    <div className="doctor_details heath_detail box-multiple" key={i}>
                                                        
                                                            <div className="media" >
                                                                <div className="media-body p-0">
                                                                    <h5>{booking.full_name}                                     <span>{booking.service_category_title} </span>
                                                                    </h5>
                                                                    <div className="proive_info">
                                                                        <ul>
                                                                        {(booking.night_consultation) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                            
                                                                        {(booking.video_consultation) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                                                                        </ul>
                                                                    </div>

                                                                    <p>{booking.starts_at} </p>
                                                                </div>
                                                                <img className="" src={(booking.profile_image) ?? default_user_img} alt="" />

                                                            </div>
                                                            <div className="contact_details">
                                                                <ul>
                                                                <li><a href={'tel:'+booking.mobile}><img src={call} alt="" className="icon_syml_call"/> {booking.mobile}</a></li>

                                                                {
                                                                (booking.whatsapp_number)
                                                                ?
                                                                <li><a href={process.env.REACT_APP_WHATSAPP_LINK+booking.whatsapp_number}><img src={whatsaap} alt="" className="icon_syml"/> {booking.whatsapp_number}</a></li>
                                                                :
                                                                ''
                                                                }
                                                                </ul>
                                                            </div>
                                                            <div className="col-8 offset-2">
                                                            <Link className="btn btn-theme"
                                                                to={() => {
                                                                    return {
                                                                        pathname: '/booking-detail/provider',
                                                                        booking_info: booking
                                                                    }
                                                                }}> {GetLocalizationString('view_booking')} <img src={ArrowRight} alt=""/></Link>
                                                            </div>
                                                    </div>
                                                )
                                            })
                                    }

                                    {
                                    (this.state.loading == true)
                                    ?
                                    (
                                        <div style={loadingCSS}>
                                            <center><b>Loading...</b></center>
                                        </div>
                                    )
                                    :
                                    ''
                                    }
                                
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
const loadingCSS = { 
    height: "50px", 
    margin: "30px" 
};
export default ProviderBookings