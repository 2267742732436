////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Page403.jsx                                  //
//  Application: permission                               //
//  Option: For permission denied page                    //
//  Developer: NP 						                            //
//  Date: 2021-12-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { GetLocalizationString } from '../../Helpers/CustomHelper'

class Page403 extends Component {
  render() {
    return (
      <div>
        <div className="az-error-wrapper">
          <h1>403 Forbidden</h1>
          <h2>Oopps. You don't have permission to access this page.</h2>
          <Link to="/" className="btn btn-outline-indigo">{GetLocalizationString('back_to_home')}</Link>
        </div>
      </div>
    )
  }
}

export default Page403
