////////////////////////////////////////////////////////////
//     							                          //
//  Program: RegisterSuccess.jsx                          //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../services/AuthenticationService';
import { HidePreloader, GetLocalizationString, ShowNotifications, SetDefaultLanguage } from './../Helpers/CustomHelper';

export class TermsConditions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded:false,
            data: {}
        }
    }

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        AuthenticationService.getSetting({})
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    data: res.data
                })
            }else{
                HidePreloader()
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            console.log(errors);
        });
    }


    render() {
        return (
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('terms_condition_heading')}</h1>
                        </div>
                    </div>
                </div>
            </header>

            <section className='tandc_section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                        <p className='text-left' dangerouslySetInnerHTML={{__html: this.state.data.terms_condition_text}} />
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default TermsConditions