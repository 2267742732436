////////////////////////////////////////////////////////////
//     							                          //
//  Program: HomeService.js                               //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer:   						                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class HomeService {

    GetAllCategoriesList(data) {
        let endPoint = 'get-service-categories';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProvidersList(data) {
        let endPoint = 'get-all-providers';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProviderDetail(data) {
        let endPoint = 'get-provider-detail';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetEmergencyContacts(data) {
        let endPoint = 'get-emergency-contacts';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetGrievanceContacts(data) {
        let endPoint = 'get-grievance-contacts';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllBookings(data) {
        let endPoint = 'get-all-bookings';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllReviews(data) {
        let endPoint = 'get-my-reviews';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProviderReviews(data) {
        let endPoint = 'get-all-reviews';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SaveProviderSlot(data) {
        let endPoint = 'add-provider-slot';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendProviderReview(data) {
        let endPoint = 'send-review';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    BookAppointment(data) {
        let endPoint = 'book-appointment';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendGrievanceReport(data) {
        let endPoint = 'send-grievance-report';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendSuggestions(data) {
        let endPoint = 'send-suggestion';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    CancelAppointment(data) {
        let endPoint = 'change-appointment-status';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    UpdateGPSLocation(data) {
        let endPoint = 'update-gps-location';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    Get3DModals(data) {
        let endPoint = 'get-3d-models';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllNotifications(data) {
        let endPoint = 'notification-list?page='+data.page+'&per_page='+data.per_page;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }
    ReadNotifications(data) {
        let endPoint = 'notification-read?type='+data.type+'&slug='+data.slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

}

export default new HomeService()