import Swal from 'sweetalert2';
import { createBrowserHistory } from "history";
import Localization from '../languages/Localization';
import $ from "jquery"

export function ShowNotifications(type, message, time) {
	return Swal.fire({
        toast: true,
        icon: type,
        title: message,
        position: 'top-end',
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        showConfirmButton: false,
        showCancelButton: false,
        timer: time,
    }); 
}

export function isObjEmpty(obj) {
    for(var prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
}

const withRefresh = createBrowserHistory({ forceRefresh: true });
const ROOT_PATH = process.env.PUBLIC_URL || 'http://localhost:3001';

export const useRedirectToLocation = (params="1", redirectTo, custom_state) => {
  if(params){
    withRefresh.push({
        pathname: `${ROOT_PATH}`+redirectTo,
        state: custom_state
    });
  }
} 

export const getLoggedInUserId = () => {
  let user_id = localStorage.getItem("userData") !== null &&
  localStorage.getItem("userData") !== undefined
    ? JSON.parse(localStorage.getItem("userData")).user_id
    : 0;
  return user_id
}

export const getLoggedInUser = () => {
  let user = localStorage.getItem("userData") !== null &&
  localStorage.getItem("userData") !== undefined
    ? JSON.parse(localStorage.getItem("userData"))
    : {};
  return user
}

export const ShowPreloader = () => {
  document.getElementById('root').style.pointerEvents = 'none';
  document.getElementById('root').style.opacity = '0.4';
  document.getElementById('root').classList.add('preloader')
}

export const HidePreloader = () => {
  document.getElementById('root').style.pointerEvents = 'auto';
  document.getElementById('root').style.opacity = '1';
  document.getElementById('root').classList.remove('preloader')
}

export const SetDefaultLanguage = (lang='') => {
  if(lang){
    if(Localization.strings.getLanguage() != lang){
      $('.valid_info').html('')
    }
    localStorage.setItem('default-user-lang',lang)
  }
  Localization.strings.setLanguage( lang != '' ? lang :  localStorage.getItem('default-user-lang') ? localStorage.getItem('default-user-lang') : process.env.REACT_APP_DEFAULT_LANGUAGE);

}

export function GetLocalizationString(key){
  return Localization.strings[key]
}

export function GetValidationString(key,lang,value=''){
  Localization.strings.setLanguage(lang)
  if(value)  return Localization.strings.formatString(Localization.strings[key], value)
  return Localization.strings[key]
}

export function getCoordinates(address){
  fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key='+process.env.REACT_APP_MAP_KEY)
    .then(response => response.json())
    .then(data => {
      const latitude = data.results[0].geometry.location.lat;
      const longitude = data.results[0].geometry.location.lng;
      console.log('selected',latitude, longitude)
      return {latitude : latitude, longitude: longitude}
    })
}
  