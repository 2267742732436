////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../../assets/images/al.png"
import CloseBtn from "./../../assets/images/close.png"
import PlusBtn from "./../../assets/images/plus.png"
import ArrowRight from "./../../assets/images/ar.png"
import AuthenticationService from '../../services/AuthenticationService'
import HomeService from "./../../services/HomeService"
import SimpleReactValidator from 'simple-react-validator'
import { GetLocalizationString, getLoggedInUser, HidePreloader, SetDefaultLanguage } from '../../Helpers/CustomHelper';
import { ShowNotifications } from '../../Helpers/CustomHelper'
import defaultImage from "./../../images/cat.png"
//import Moment from "moment";


export class UpdatePayment extends Component {

    constructor(props) {
        super(props)

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });

        this.state = {
            input: {},
            errors: {},
            isSubmit:false,
            slots: [{slot_id: 0, day: '', starts_at: '', ends_at: ''}],
            startDate : new Date(),
            user_data:getLoggedInUser(),
            appointment_duration:(getLoggedInUser().appointment_duration > 0) ? getLoggedInUser().appointment_duration  : 5

        }
        this.slotHandleChange = this.slotHandleChange.bind(this)
        this.addClick = this.addClick.bind(this)
        this.removeClick = this.removeClick.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.onValueChange = this.onValueChange.bind(this);
        
    }

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        var resData = {};
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                resData = res.data ? res.data : {}
                resData.imageUrl = res.data.profile_thumb_url ? res.data.profile_thumb_url : defaultImage
                this.setState({
                    user_data:resData,
                    appointment_duration:resData.appointment_duration,
                    payment_instructions:resData.payment_instructions
                })
                if(resData.provider_slots.length > 0){
                this.setState({
                        slots:resData.provider_slots
                    })
                }
                console.log(this.state.slots);
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
               
    }

    updateProfile = (e) => {
        e.preventDefault();
    
        if(this.validator.allValid()){ 
            this.setState({isSubmit: true});

            var filledSlots = this.state.slots;
            for(var i = 0; i < filledSlots.length; i++){
                var slot_day = filledSlots[i].day
                var start_at = filledSlots[i].starts_at
                var ends_at = filledSlots[i].ends_at 
                if(slot_day == '' || start_at == '' || ends_at == ''){
                    ShowNotifications('error', GetLocalizationString('slot_empty'),1500)
                    return false
                }
            }
            let input = {
                per_page:100,
                page:1,
                provider_id:getLoggedInUser().user_id,
                appointment_duration:this.state.appointment_duration,
                slots:JSON.stringify(this.state.slots)
            }
            console.log(this.state)
            HomeService.SaveProviderSlot(input)
            .then((res) =>{
              if((global.successStatus).includes(res.status)) {
                  ShowNotifications('success', GetLocalizationString('profile_update_success'),1500).then(()=>{
                      this.props.history.push({
                          pathname: '/register-success/'+this.state.user_data.slug
                      });
                  });
              }else if(res.status === 401){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }else if(res.status === 400){
                  console.log(res);
                  ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
              }
              else{
                  console.log(res);
                  ShowNotifications('error', res.data.message,1500)
              }
            }).catch((errors)=>{
                console.log(errors);
            });
           
        }else{
            this.validator.showMessages();
        } 
    }

    changeHandler = (event) => {
        let input = this.state.input;
        
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });

        this.validator.showMessageFor(event.target.name);

    }

    onValueChange = (event) => {
        this.setState({
          appointment_duration: event.target.value
        });
    }

    setStartDate(date){
        this.setState({
            startDate: date
        })
    }

    createUI(){
        return this.state.slots.map((el, i) => 

            <div className="row mt-2" key={i}>
                <div className="select_app_time p-0">
                    <select id={`day`} style={input_style} value={el.day}  onChange={this.slotHandleChange.bind(this, i)}>
                    <option value={''}></option>
                    <option value={'SUN'} selected={(el.day == 'SUN') ? 'selected' : ''}>{GetLocalizationString('sun')}</option>
                    <option value={'MON'} selected={(el.day == 'MON') ? 'selected' : ''}>{GetLocalizationString('mon')}</option>
                    <option value={'TUE'} selected={(el.day == 'TUE') ? 'selected' : ''}>{GetLocalizationString('tue')}</option>
                    <option value={'WED'} selected={(el.day == 'WED') ? 'selected' : ''}>{GetLocalizationString('wed')}</option>
                    <option value={'THU'} selected={(el.day == 'THU') ? 'selected' : ''}>{GetLocalizationString('thu')}</option>
                    <option value={'FRI'} selected={(el.day == 'FRI') ? 'selected' : ''}>{GetLocalizationString('fri')}</option>
                    <option value={'SAT'} selected={(el.day == 'SAT') ? 'selected' : ''}>{GetLocalizationString('sat')}</option>
                    </select>
                    
                </div>
                <div className="select_app_time start_time p-0">
                    <input type='time' id={`starts_at`} style={input_style} value={el.starts_at}  onChange={this.slotHandleChange.bind(this, i)} />
                </div>
                <div className="select_app_time  start_time p-0">
                    <input type='time' id={`ends_at`} style={input_style} value={el.ends_at}  onChange={this.slotHandleChange.bind(this, i)} />
                    
                </div>
                {
                    (i != 0)
                    ?
                    (<div className="select_app_time time_con_btn border-0 p-0">
                        <div className="close_time">
                        <a onClick={this.removeClick.bind(this, i)}>
                            <img src={CloseBtn} alt=""/>
                        </a>
                        </div>
                    </div>)
                    :
                    ('')
                }
                
                <div className="select_app_time time_con_btn border-0 p-0">
                    <div className="close_time">
                    <a onClick={this.addClick}>
                        <img src={PlusBtn} alt="" />
                    </a>
                    </div>
                </div>
            </div>
                       
        )
    }

    slotHandleChange (i,event) {
        let slots = [...this.state.slots];
        console.log(slots[i])
        slots[i][event.target.id] =   event.target.value;
        this.setState({ slots }); 
         
        /*var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        
        today = dd + '/' + mm + '/' + yyyy;
        let slots = [...this.state.slots];
        console.log(slots[i])
        if(event.target.id == 'starts_at'){
            let event_date = new Date(today+' '+event.target.value+':00');
            let end_time = new Date(today+' '+slots[i]['ends_at'])
            alert(event_date)
            if(end_time <= event_date){
                slots[i]['ends_at'] = '' 
            }else{
                slots[i]['starts_at'] = Moment(event_date).format("HH:mm:ss")
            }
        }
        if(event.target.id == 'ends_at'){
            let event_date = new Date(today+' '+event.target.value+':00');
            let start_time = new Date(today+' '+slots[i]['starts_at'])
            if(start_time >= event_date && slots[i]['starts_at']){
                ShowNotifications('warning',GetLocalizationString('invalid_end_time'),2000)
                slots[i]['ends_at'] = '' 
            }else{
                slots[i]['ends_at'] = Moment(event_date).format("HH:mm:ss")
            }
        }
        this.setState({ slots }); */
    }

    addClick(){
        this.setState(prevState => ({ slots: [...prevState.slots, {days: '',starts_at: '', ends_at: ''}]}))
    }
      
    removeClick(i){
        if(this.state.slots.length > 1){
            let slots = [...this.state.slots];
            slots.splice(i,1);
            this.setState({ slots })
        }
    }

    render() {
        return (
            <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('setup_time')}</h1>
                        </div>
                    </div>
                </div>
            </header>

            
            <section className="">
                 <div className="container">
                  <div className="row">
                    <div className="select_app_time time_heading">
                      {GetLocalizationString('day')}
                    </div>
                    <div className="select_app_time time_heading">
                      {GetLocalizationString('from_time')}
                    </div>
                    <div className="select_app_time time_heading">
                      {GetLocalizationString('to_time')}
                    </div>
                    <div className="select_app_time time_heading"></div>
                    <div className="select_app_time time_heading"></div>
                  </div>
                </div>

                <div className="container">

                    {this.createUI()} 

                </div>

                <div className="container mt-3">
                    <form className="row" action="">
                   <div className="col-12 mt-2">
                        <label htmlFor="">{GetLocalizationString('duration_text')}</label>
                        </div>
                    <div className="col-6 mt-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"  value="5" onChange={this.onValueChange} checked={this.state.appointment_duration == "5"}/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                        {GetLocalizationString('five_minutes')}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 mt-2">
                      <div className="form-check ">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="10" onChange={this.onValueChange} checked={this.state.appointment_duration == "10"}/>
                        <label className="form-check-label" htmlFor="exampleRadios2">
                        {GetLocalizationString('ten_minutes')}
                        </label>
                      </div>
                      </div>
                      <div className="col-6 mt-2">
                      <div className="form-check ">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="15" onChange={this.onValueChange} checked={this.state.appointment_duration == "15"} />
                        <label className="form-check-label" htmlFor="exampleRadios3">
                        {GetLocalizationString('fifteen_minutes')}
                        </label>
                      </div>
                      </div>
                      <div className="col-6 mt-2">
                      <div className="form-check ">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="20" onChange={this.onValueChange} checked={this.state.appointment_duration == "20"}/>
                        <label className="form-check-label" htmlFor="exampleRadios4">
                        {GetLocalizationString('twenty_minutes')}
                        </label>
                      </div>
                    </div>
                      <div className="col-12 mt-5 mb-3 ">
                        <button className="btn-theme" type="submit" onClick={this.updateProfile}> {GetLocalizationString('submit')}<img src={ArrowRight} alt=""/> </button>
                    </div>
                    </form>
                    
                </div>       
            </section>
            
            </>
          )
    }
}
const input_style ={
    width:'50px',
    padding: '5px',
    border:'none'
}
export default UpdatePayment