import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: localStorage.getItem("headerToken"),
      userData: global.userData ? global.userData : {},

      profileImage:
        localStorage.getItem("userData") !== null &&
        localStorage.getItem("userData") !== undefined
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : '',

      showSpinner: true,
      customSpinner: parseInt(localStorage.getItem("customSpinnerSession"))
        ? false
        : true,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (localStorage.getItem("isLogin") === "1") {
      localStorage.removeItem("isLogin");
      window.location.reload(false);
    }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    localStorage.setItem("customSpinnerSession", 1);
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  render() {
    let sessionExpireTime = localStorage.getItem("sessionExpireTime")
      ? localStorage.getItem("sessionExpireTime")
      : 60;
    let lastSessionExpiry = localStorage.getItem("sessionExpiry")
      ? localStorage.getItem("sessionExpiry")
      : "";
    let currentTimestamp = new Date().getTime();
    if (!lastSessionExpiry && this.state.headerToken) {
      let sessionExpiry = new Date(
        currentTimestamp + sessionExpireTime * 60000
      ).getTime();
      localStorage.setItem("sessionExpiry", sessionExpiry);
    } else if (this.state.headerToken) {
      if (!isNaN(lastSessionExpiry) && lastSessionExpiry > currentTimestamp) {
       let sessionExpiry = new Date(
          currentTimestamp + sessionExpireTime * 60000
        ).getTime();
        localStorage.setItem("sessionExpiry", sessionExpiry);
      } else {
        return <Redirect to="/logout" />;
      }
    }

    return (
    <></>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
