////////////////////////////////////////////////////////////
//     							                          //
//  Program: RegisterSuccess.jsx                          //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import Done from "./../../assets/images/icon/done.png"
import whatsaap from "./../../assets/images/social/whatsapp_new.png"
import call from "./../../assets/images/social/call_new.png"
import ArrowRight from "./../../assets/images/ar.png"
import default_user_img from "./../../assets/images/default_user_img.png"
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowNotifications } from '../../Helpers/CustomHelper'
import HomeService from '../../services/HomeService'
import { Link } from 'react-router-dom'
export class RegisterSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            provider_info:{}
            
        };
    }

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()

        var params = {
            "slug": this.props.match.params.slug,
        }
        HomeService.GetProviderDetail(params)
        .then((res) =>{
        if((global.successStatus).includes(res.status)) {
            let Provider = res.data.provider
            let ProviderReviews = res.data.provider_reviews
            if(Provider){
                console.log(res)
                /** Checking user is logged in or not */
                if(Provider.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID){
                    console.log(res)
                }
                this.setState({
                    provider_info:Provider,
                    isLoaded: true
                })
            }
            if(ProviderReviews.length > 0){  
                this.setState({
                    provider_reviews:ProviderReviews,
                    isLoaded:true
                })
            }

            
        }else if(res.status === 401){
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }else if(res.status === 400){
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        else{
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
        }
        }).catch((errors)=>{
            console.log(errors);
        });
    }

    render() {
        return (
            <section>
            <div className="container">
              <div className="row">
                <div className="col-12 ">
                  <div className="success_box ">
                    <div className="col-12 text-center">
                      <img src={Done} alt="" className="img-fluid"/>
                      <p className="text-sucess">{GetLocalizationString('you_have_registered_service_provider')}</p>
                    </div>
        
                    <div className="heath_detail box-multiple">
                      <div className="media">
                        <div className="media-body">
                          <h5>{this.state.provider_info.full_name} <span> {this.state.provider_info.service_category_title}</span></h5>
                          {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_TRANSPOTATION_ID && this.state.provider_info.unique_id != '') ? (<p>({this.state.provider_info.unique_id})</p>) : ''}

                          {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) 
                          ?
                          <p>{GetLocalizationString('general_fees')} ₹ {this.state.provider_info.advance_fees ?? 0} <span>| {GetLocalizationString('discounted_fees')} ₹  {this.state.provider_info.discount_fees ?? 0}</span> </p>
                          :
                          ''
                          }
                          {(this.state.provider_info.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID) 
                          ?
                          <div className="proive_info">
                            <ul>
                            {(this.state.provider_info.night_consultation == 1) ? (<li><span></span> {GetLocalizationString('night_consultation_txt')}</li>) : '' }
                
                            {(this.state.provider_info.video_consultation == 1) ? (<li><span></span> {GetLocalizationString('video_consultation_txt')}</li>) : '' }
                            </ul>
                          </div>
                          :
                          ''
                          }
                          <div className="contact_details">
                            <ul>
                            
                                <li><a ><img src={call} alt="" className="icon_syml_call"/> {this.state.provider_info.mobile}</a></li>

                                {
                                this.state.provider_info.whatsapp_number != ''
                                ?
                                <li><a ><img src={whatsaap} alt="" className="icon_syml"/> {this.state.provider_info.whatsapp_number}</a></li>
                                :
                                ''
                                }
                          
                            </ul>
                          </div>
                        </div>
                        <img className="" src={this.state.provider_info.profile_image_url ?? default_user_img} alt=""/>
                      </div>
                   
                     
                    </div>
        
                    <Link onClick={()=>{
                        this.props.history.push({
                            pathname: '/home'
                        });
                    }} className="btn btn-theme mb-5">{GetLocalizationString('go_to_home')} <img src={ArrowRight} alt=""/></Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }
}

export default RegisterSuccess